import { HttpClient } from '@angular/common/http';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnInit,
  Output,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { truncateStr as truncate } from 'src/app/helpers';
import {
  Date,
  Fund,
  sAndp,
  Top,
  Top2,
  TopItem,
  TopItem2,
  search,
  StockTypeEnum,
  AlgoNameRouting,
} from 'src/app/models/stockType';
import { AppProxy } from 'src/app/services/app-proxy';
import { DataService } from 'src/app/services/data.service';
import { innerDataService } from 'src/app/services/innerData.service';
import { StocksService } from 'src/app/services/stocks-service';
import { topDataService } from 'src/app/services/topData.service';
import { ContactComponent } from '../contact/contact.component';
import { PopupTypeComponent } from '../popup-type/popup-type.component';
@Component({
  selector: 'app-top',
  templateUrl: './top.component.html',
  styleUrls: ['./top.component.css'],
})
export class TopComponent implements OnInit {
  @Output() showCharacter: EventEmitter<number> = new EventEmitter<number>();
  math = Math;
  name: string = 'Capital Preservation';
  description: string = 'Capital Preservation';
  img = 'assets/images/Home_Icon_11.png';
  searchText: string;
  type: string;
  text: string;
  dateList: Date[] = [];
  stockList: TopItem[] = [];
  sandpList: TopItem[] = [];
  stockList2: TopItem2[] = [];
  sandpList2: TopItem2[] = [];
  top: any;
  top2: Top2;
  sAndp: sAndp;
  ret: string;
  maxDd;
  std;
  innerHeight: any;
  innerWidth: any;
  num = 2;
  fund: Fund = new Fund();
  pos: number;
  maxDdText = 'max_dd';
  stdText = 'std';
  showProgress = false;
  search: search = new search();
  truncateStr = truncate;
  selectedElement: string;
  period: string;

  constructor(
    private dataService: DataService,
    private topDataService: topDataService,
    private innerDataService: innerDataService,
    private proxy: AppProxy,
    private http: HttpClient,
    private stockTypeService: StocksService,
    private matDialog: MatDialog,
    private router: Router,
    private cdRef: ChangeDetectorRef,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.topDataService.currentProperty.subscribe((propertyName) => {
      if (propertyName === 'name') {
        this.cdRef.detectChanges();
      }
    });
    this.showProgress = true;
    this.dataService.character.next(false);
    this.showCharacter.emit();
    this.proxy.scrollToTop();
    this.innerWidth = window.innerWidth;
    this.innerHeight = window.innerHeight;
    this.description = this.topDataService.getDescription();
    this.name = this.topDataService.getName();
    this.type = this.topDataService.getType();

    if (this.name !== 'Value Investing') {
      this.dateList = [
        {
          id: 1,
          date: '1D',
          selected: false,
        },
        {
          id: 2,
          date: '1W',
          selected: false,
        },
        {
          id: 3,
          date: '1M',
          selected: false,
        },
        {
          id: 4,
          date: '3M',
          selected: false,
        },
        {
          id: 5,
          date: '6M',
          selected: false,
        },
        {
          id: 6,
          date: '12M',
          selected: false,
        },
      ];
    } else {
      this.dateList = [
        {
          id: 0,
          date: '1D',
          selected: false,
        },
        {
          id: 1,
          date: '6M',
          selected: false,
        },
        {
          id: 2,
          date: '9M',
          selected: false,
        },
        {
          id: 3,
          date: '12M',
          selected: false,
        },
      ];
    }

    if (this.type == '0') this.img = 'assets/images/Home_Icon_22.png';
    else if (this.type == '1') this.img = 'assets/images/Home_Icon_11.png';
    else if (this.type == '2') this.img = 'assets/images/Home_Icon_33.png';
    else if (this.type == '3') this.img = 'assets/images/Home_Icon_44.png';
    else if (this.type == '4') this.img = 'assets/images/Home_Icon_54.png';
    this.ret = '30';
    this.maxDd = '90';
    this.std = '15';
    this.innerHeight += Number(Number(this.stockList.length) * 101);
    let i = Number(this.type);
    i += 1;
    const CAPITAL_PRESERVATION = StockTypeEnum.CAPITAL_PRESERVATION + 1;
    const MOMENTUM = StockTypeEnum.MOMENTUM + 1;
    const BUY_THE_DIP = StockTypeEnum.BUY_THE_DIP + 1;
    const SOCIAL_SENTIMENT = StockTypeEnum.SOCIAL_SENTIMENT + 1;
    const VALUE_INVESTING = StockTypeEnum.VALUE_INVESTING + 1;
    this.pos = i;
    this.search.search = this.text;
    let day = null;
    if (this.name === 'Social Sentiment') {
      day = '1D';
    }

    this.stockTypeService
      .getStockList(i + '', this.search, day)
      .subscribe((res: any) => {
        {
          if (i == CAPITAL_PRESERVATION) {
            this.maxDdText = 'max_dd';
            this.stdText = 'std';
          } else if (i == MOMENTUM) {
            this.maxDdText = 'cci';
            this.stdText = 'roc';
          } else if (i == BUY_THE_DIP) {
            this.maxDdText = 'ma20';
            this.stdText = 'ma50';
          } else if (i == SOCIAL_SENTIMENT) {
            this.maxDdText = 'Sentiment Rank';
            this.stdText = 'Volume change';
          } else if (i == VALUE_INVESTING) {
            this.maxDdText = 'p/e';
            this.stdText = 'analysis';
          }
          this.top = res;

          const indexOfFilter = this.topDataService.getIndexOfFilterInTop();
          const previousName = this.topDataService.getPreviousName();
          if (indexOfFilter === 0) {
            this.stockList = this.top.dailyStock.data;
            this.sandpList = this.top.dailyStock.sandp;
            this.topDataService.setByDate('daily');
            this.dateList[indexOfFilter].selected = true;
          } else {
            if (previousName !== undefined && this.name !== previousName) {
              this.stockList = this.top.dailyStock.data;
              this.sandpList = this.top.dailyStock.sandp;
              this.topDataService.setByDate('daily');
              this.dateList[0].selected = true;
              this.topDataService.setIndexOfFilterInTop(0);
              this.topDataService.setPreviousName(this.name);
            } else {
              const timeframes = ['1W', '1M', '3M', '6M', '12M'];
              this.byDate(
                indexOfFilter,
                this.name === 'Value Investing'
                  ? ''
                  : timeframes[indexOfFilter - 1]
              );
            }
          }
        }

        this.topDataService.setPosition(i + '');
        this.showProgress = false;
      });
    this.activatedRoute.queryParams.subscribe((params) => {
      this.period = params['period'];
    });
  }

  question() {
    sessionStorage.isQuestion = true;
    if (this.innerWidth <= 480) {
      this.openDialog();
    } else {
      const algoRoutes = {
        'Capital Preservation': AlgoNameRouting.CAPITAL,
        Momentum: AlgoNameRouting.MOMENTUM,
        'Buy the Dip': AlgoNameRouting.BDD,
        'Social Sentiment': AlgoNameRouting.SOCIAL,
        'Value Investing': AlgoNameRouting.VALUE,
      };
      sessionStorage.fromHomePage = false;
      this.router.navigate(['../groupType', algoRoutes[this.name]]);
    }
  }

  openDialog() {
    this.matDialog.open(PopupTypeComponent, { disableClose: true });
  }

  removeDuplicatesByProperty(arr, property) {
    const seen = new Set();
    return arr.filter((obj) => {
      const value = obj[property];
      if (!seen.has(value)) {
        seen.add(value);
        return true;
      }
      return false;
    });
  }

  objectForValueAlgo(res: Top) {
    return {
      dailyStock: {
        data: this.removeDuplicatesByProperty(res.dailyStock.data, 'symbol'),
        sandp: res.dailyStock.sandp,
      },
      weeklyStock: {
        data: this.removeDuplicatesByProperty(res.weeklyStock.data, 'symbol'),
        sandp: res.weeklyStock.sandp,
      },
      oneMonthStock: {
        data: this.removeDuplicatesByProperty(res.oneMonthStock.data, 'symbol'),
        sandp: res.weeklyStock.sandp,
      },
      threeMonthStock: {
        data: this.removeDuplicatesByProperty(
          res.threeMonthStock.data,
          'symbol'
        ),
        sandp: res.weeklyStock.sandp,
      },
      sixMonthStock: {
        data: this.removeDuplicatesByProperty(res.sixMonthStock.data, 'symbol'),
        sandp: res.weeklyStock.sandp,
      },
      twelveMonthStock: {
        data: this.removeDuplicatesByProperty(
          res.twelveMonthStock.data,
          'symbol'
        ),
        sandp: res.weeklyStock.sandp,
      },
    };
  }

  objectForAnotherAlgos(res: Top) {
    return {
      dailyStock: {
        data: this.removeDuplicatesByProperty(res.dailyStock.data, 'symbol'),
        sandp: res.dailyStock.sandp,
      },
      halfyearlyStock: {
        data: this.removeDuplicatesByProperty(
          res.halfyearlyStock.data,
          'symbol'
        ),
        sandp: res.halfyearlyStock.sandp,
      },
      quarterlyStock: {
        data: this.removeDuplicatesByProperty(
          res.quarterlyStock.data,
          'symbol'
        ),
        sandp: res.quarterlyStock.sandp,
      },
      yearlyStock: {
        data: this.removeDuplicatesByProperty(res.yearlyStock.data, 'symbol'),
        sandp: res.yearlyStock.sandp,
      },
    };
  }

  searchBy() {
    if (
      (this.text != '' && this.text != undefined) ||
      this.stockList.length >= 0
    ) {
      this.showProgress = true;
      this.search.search = this.text;
      let day = null;

      if (this.name === 'Social Sentiment') {
        day = '1D';
      }

      this.stockTypeService
        .getStockList(this.pos + '', this.search, day)
        .subscribe((res: Top) => {
          const newData =
            this.name !== 'Value Investing'
              ? this.objectForValueAlgo(res)
              : this.objectForAnotherAlgos(res);
          this.top = newData;
          let position = -1;
          for (let i = 0; i < this.dateList.length; i++) {
            if (this.dateList[i].selected == true) position = i;
          }
          {
            if (this.name === 'Value Investing') {
              if (position == 0) {
                this.stockList = this.top?.dailyStock?.data;
                this.sandpList = this.top?.dailyStock?.sandp;
              } else if (position == 1) {
                this.stockList = this.top?.halfyearlyStock?.data;
                this.sandpList = this.top?.halfyearlyStock?.sandp;
              } else if (position == 2) {
                this.stockList = this.top?.quarterlyStock?.data;
                this.sandpList = this.top?.quarterlyStock?.sandp;
              } else {
                this.stockList = this.top?.yearlyStock?.data;
                this.sandpList = this.top?.yearlyStock?.sandp;
              }
            } else {
              if (position == 0) {
                this.stockList = this.top?.dailyStock?.data;
                this.sandpList = this.top?.dailyStock?.sandp;
              } else if (position == 1) {
                this.stockList = this.top?.weeklyStock?.data;
                this.sandpList = this.top?.weeklyStock?.sandp;
              } else if (position == 2) {
                this.stockList = this.top?.oneMonthStock?.data;
                this.sandpList = this.top?.oneMonthStock?.sandp;
              } else if (position == 3) {
                this.stockList = this.top?.threeMonthStock?.data;
                this.sandpList = this.top?.threeMonthStock?.sandp;
              } else if (position == 4) {
                this.stockList = this.top?.sixMonthStock?.data;
                this.sandpList = this.top?.sixMonthStock?.sandp;
              } else if (position == 5) {
                this.stockList = this.top?.twelveMonthStock?.data;
                this.sandpList = this.top?.twelveMonthStock?.sandp;
              }
            }

            if (this.pos == 1) {
              this.maxDdText = 'max_dd';
              this.stdText = 'std';
              sessionStorage.SpyMaxDD = this.sandpList[0].max_dd;
              sessionStorage.SpyStd = this.sandpList[0].std;
            } else if (this.pos == 2) {
              this.maxDdText = 'cci';
              this.stdText = 'roc';
              sessionStorage.SpyCci = this.sandpList[0].cci;
              sessionStorage.SpyRoc = this.sandpList[0].roc;
            } else if (this.pos == 3) {
              this.maxDdText = 'ma20';
              this.stdText = 'ma50';
              sessionStorage.SpyMa20 = this.sandpList[0].max_dd;
              sessionStorage.SpyMa50 = this.sandpList[0].recovery;
            } else if (this.pos == 4) {
              this.maxDdText = 'Sentiment Rank';
              this.stdText = 'Volume change';
            } else if (this.pos == 5) {
              this.maxDdText = 'p/e';
              this.stdText = 'analysis';
            }
          }
          this.showProgress = false;
        });
    }
  }

  person() {
    this.router.navigate(['../']);
  }

  addPerson(search: search): Observable<any> {
    const headers = { 'content-type': 'application/json' };
    const body = JSON.stringify({
      search: '1',
    });
    return this.http.post('http://3.72.198.231:8000/api/stockList/2', body, {
      headers: headers,
    });
  }

  inner(stock: TopItem) {
    if (stock.symbolName && stock.symbolName?.length) {
      this.innerDataService.setStockName(stock.symbolName[0].name);
    } else {
      this.innerDataService.setStockName(stock?.tickerData?.[0]?.name || '');
    }
    this.innerDataService.setStockType(stock.symbol);
    this.innerDataService.setDays(stock.days);
    this.innerDataService.setPrice(stock.price);
    if (this.name === 'Value Investing') {
      this.innerDataService.setMarket_Cap(stock?.tickerData?.[0]?.market_cap);
      this.innerDataService.setSector(stock.tickerData?.[0]?.sector);
      this.innerDataService.setClass(stock.tickerData?.[0]?.class);
      if (stock?.buythedip?.[0]?.ret) {
        this.innerDataService.setStockRet(`${stock.buythedip[0].ret}%`);
      } else {
        this.innerDataService.setStockRet('-');
      }
    } else if (this.name === 'Social Sentiment') {
      this.innerDataService.setSector(stock?.symbolName?.[0]?.sector);
      this.innerDataService.setMarket_Cap(stock?.symbolName?.[0]?.market_cap);
      this.innerDataService.setClass(stock.symbolName?.[0].class);
      this.innerDataService.setPrice(
        Number(stock.buythedip[0].price).toFixed(2)
      );
      this.innerDataService.setDays(stock.lookback.replace(/\D/g, ''));
      if (stock?.buythedip?.[0]?.ret) {
        this.innerDataService.setStockRet(`${stock.buythedip[0].ret}%`);
      } else {
        this.innerDataService.setStockRet('-');
      }
    } else {
      if (stock.symbolName && stock.symbolName?.length) {
        this.innerDataService.setSector(stock.symbolName?.[0].sector);
        this.innerDataService.setMarket_Cap(stock?.symbolName?.[0]?.market_cap);
        this.innerDataService.setClass(stock.symbolName?.[0].class);
      } else {
        this.innerDataService.setSector(stock.tickerData?.[0].sector);
        this.innerDataService.setMarket_Cap(stock?.tickerData?.[0]?.market_cap);
        this.innerDataService.setClass(stock.tickerData?.[0].class);
      }
      this.innerDataService.setStockRet(stock.ret);
    }

    const algoRoutes = {
      'Capital Preservation': AlgoNameRouting.CAPITAL,
      Momentum: AlgoNameRouting.MOMENTUM,
      'Buy the Dip': AlgoNameRouting.BDD,
      'Social Sentiment': AlgoNameRouting.SOCIAL,
      'Value Investing': AlgoNameRouting.VALUE,
    };
    this.router.navigate(['/inner', stock.symbol.replace(/\s/g, '')], {
      queryParams: { algo: algoRoutes[this.name], period: this.period },
    });
  }

  choose() {
    this.router.navigate(['../']);
  }

  more() {
    if (this.innerWidth <= 480)
      this.matDialog.open(ContactComponent, {
        disableClose: true,
        maxWidth: '100vw',
      });
    else {
      this.router.navigate(['../contact']);
      sessionStorage.fromHomePage = false;
    }
  }

  async byDate(i: number, period: string) {
    this.topDataService.setIndexOfFilterInTop(i);
    this.topDataService.setPreviousName(this.name);

    for (let m = 0; m < this.dateList.length; m++) {
      if (this.dateList[m].selected == true) this.dateList[m].selected = false;
    }
    this.dateList[i].selected = true;
    this.topDataService.setPosition(i + '');
    if (this.pos == 1) {
      this.maxDdText = 'max_dd';
      this.stdText = 'std';
    } else if (this.pos == 2) {
      this.maxDdText = 'cci';
      this.stdText = 'roc';
    } else if (this.pos == 3) {
      this.maxDdText = 'ma20';
      this.stdText = 'ma50';
    } else if (this.pos == 4) {
      this.maxDdText = 'Sentiment Rank';
      this.stdText = 'Volume change';
    } else if (this.pos == 5) {
      this.maxDdText = 'p/e';
      this.stdText = 'analysis';
    }

    if (this.name !== 'Value Investing') {
      this.getStockListByDate(i);
    } else {
      if (i == 0) {
        this.stockList = this.top.dailyStock.data;
        this.sandpList = this.top.dailyStock.sandp;
        this.topDataService.setByDate('daily');
        this.topDataService.setDateKey('1D');
        this.period = '1D';
      } else if (i == 1) {
        this.stockList = this.top?.halfyearlyStock.data;
        this.sandpList = this.top?.halfyearlyStock.sandp;
        this.topDataService.setByDate('threeMonths');
        this.topDataService.setDateKey('6M');
        this.period = '6M';
      } else if (i == 2) {
        this.stockList = this.top.quarterlyStock.data;
        this.sandpList = this.top.quarterlyStock.sandp;
        this.topDataService.setByDate('sixMonths');
        this.topDataService.setDateKey('9M');
        this.period = '9M';
      } else if (i == 3) {
        this.stockList = this.top.yearlyStock.data;
        this.sandpList = this.top.yearlyStock.sandp;
        this.topDataService.setByDate('twelveMonths');
        this.topDataService.setDateKey('12M');
        this.period = '12M';
      }
    }
    period = period || this.period;
    this.router.navigate([], {
      queryParams: { period },
      queryParamsHandling: 'merge',
      relativeTo: this.activatedRoute,
    });
  }

  getStockListByDate(mode: number) {
    if (mode == 0) {
      this.stockList = this.top.dailyStock.data;
      this.sandpList = this.top.dailyStock.sandp;
      this.topDataService.setByDate('daily');
    } else if (mode == 1) {
      this.stockList = this.top.weeklyStock.data;
      this.sandpList = this.top.weeklyStock.sandp;
      this.topDataService.setByDate('weekly');
    } else if (mode == 2) {
      this.stockList = this.top.oneMonthStock.data;
      this.sandpList = this.top.oneMonthStock.sandp;
      this.topDataService.setByDate('monthly');
    } else if (mode == 3) {
      this.stockList = this.top.threeMonthStock.data;
      this.sandpList = this.top.threeMonthStock.sandp;
      this.topDataService.setByDate('threeMonths');
    } else if (mode == 4) {
      this.stockList = this.top.sixMonthStock.data;
      this.sandpList = this.top.sixMonthStock.sandp;
      this.topDataService.setByDate('sixMonths');
    } else if (mode == 5) {
      this.stockList = this.top.twelveMonthStock.data;
      this.sandpList = this.top.twelveMonthStock.sandp;
      this.topDataService.setByDate('twelveMonths');
    }
  }

  getSocialVolumeChange(number) {
    if (!number) {
      return '0.0';
    }

    const volumeChangePercent = (number as number) * 100;

    if (volumeChangePercent < 1) {
      return volumeChangePercent.toFixed(2);
    }

    return Math.round(volumeChangePercent);
  }

  backToTop = (): void => {
    this.router.navigate(['../']);
  };
}
