<div class="container">
  <form
    [formGroup]="step === 1 ? loginForm : verifyCodeForm"
    (ngSubmit)="step === 1 ? onSubmit() : onConfirm()"
    class="form"
  >
    <h1>{{ step === 1 ? "Log in" : "Comfirm Account" }}</h1>
    <p class="error">{{ message }}</p>
    <div class="inputContainer" *ngIf="step === 2">
      <label for="code">Code from registered mail</label>
      <input class="input" type="text" id="code" formControlName="code" />
    </div>
    <div class="inputContainer" *ngIf="step === 1">
      <label for="email">Email</label>
      <input class="input" type="email" id="email" formControlName="email" />
    </div>
    <div class="inputContainer" *ngIf="step === 1">
      <label for="password">Password</label>
      <input
        class="input"
        type="password"
        id="password"
        formControlName="password"
      />
    </div>
    <button
      class="button"
      type="submit"
      [disabled]="step === 1 ? !loginForm.valid : !verifyCodeForm.valid"
    >
      {{ step === 1 ? "Log in" : "Comfirm Account" }}
    </button>
    <p class="signup" (click)="step === 1 ? onSignup() : back()">
      {{ step === 1 ? "No account? Sign Up" : "Back To Log in" }}
    </p>
    <p *ngIf="step === 1" (click)="onResetPassword()" class="signup">
      Forgot Password
    </p>
  </form>
</div>
