import { Injectable } from '@angular/core';
import { ServerResponse } from 'http';
import { Observable } from 'rxjs';
import { AppProxy, HttpMethod } from './app-proxy';

@Injectable()
export class UserService {
  constructor(private proxy: AppProxy) {}

  getAllUsers(parameters) {
    const url =
      this.proxy.baseQAUrl +
      `admin/users?size=${parameters.size}&page=${parameters.page}`;
    return new Observable((observer) => {
      this.proxy.execute<ServerResponse>(url, HttpMethod.Get).subscribe(
        (result) => {
          const res: any = result;
          if (res.success == true) {
            observer.next(res);
          }
        },
        (error) => console.log('oops', error)
      );
    });
  }

  getUser(parameters) {
    const url =
      this.proxy.baseQAUrl +
      `admin/user/?size=${parameters.size}&page=${parameters.page}&email=${parameters.email}`;
    return new Observable((observer) => {
      this.proxy.execute<ServerResponse>(url, HttpMethod.Get).subscribe(
        (result) => {
          const res: any = result;
          if (res.success == true) {
            observer.next(res);
          }
        },
        (error) => console.log('oops', error)
      );
    });
  }

  updateUser(data) {
    const url = this.proxy.baseQAUrl + `admin/user/${data.id}`;
    return new Observable((observer) => {
      this.proxy
        .executeUserPut<ServerResponse>(url, data, HttpMethod.Put)
        .subscribe(
          (result) => {
            const res: any = result;
            if (res.success == true) {
              observer.next(res);
            }
          },
          (error) => console.log('oops', error)
        );
    });
  }

  deleteUser(user) {
    const url = this.proxy.baseQAUrl + `admin/deleteUser/${user.id}`;
    return new Observable((observer) => {
      this.proxy.execute<ServerResponse>(url, HttpMethod.Delete).subscribe(
        (result) => {
          const res: any = result;
          if (res.success == true) {
            observer.next(res);
          }
        },
        (error) => console.log('oops', error)
      );
    });
  }
}
