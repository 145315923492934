import {
  AfterViewInit,
  Directive,
  DoCheck,
  Host,
  Input,
  Optional,
  Renderer2,
  Self,
  ViewContainerRef,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';

@Directive({
  selector: '[appStylePaginator]',
})
export class StylePaginatorDirective implements AfterViewInit, DoCheck {
  @Input() public currentPage: any; // <<== for getting current page of component, specially after search
  public directiveLoaded = false;
  public pageGapTxt = '...';
  public widthPageCount: number;

  constructor(
    @Host() @Self() @Optional() private readonly matPag: MatPaginator,
    private readonly vr: ViewContainerRef,
    private readonly ren: Renderer2
  ) {}

  private buildPageNumbers(pageCount, pageRange) {
    let dots = false;
    const paglast = pageCount;
    this.widthPageCount = paglast * 40;
    const pagcurrent = this.matPag.pageIndex;
    const showTotalPages = window.innerWidth > 480 ? 5 : 2;
    const startingPageIndex = window.innerWidth > 480 ? 2 : 1;

    let startingPage = Math.max(0, pagcurrent - startingPageIndex);
    const endingPage = Math.min(paglast - 1, startingPage + (showTotalPages - 1));
    if (endingPage - startingPage < showTotalPages - 1) {
      startingPage = Math.max(0, endingPage - (showTotalPages - 1));
    }
    for (let i = startingPage; i <= endingPage; i++) {
      if (i < pagcurrent) {
        this.ren.insertBefore(
          pageRange,
          this.createPage(i, this.matPag.pageIndex),
          null
        );
      } else {
        this.ren.appendChild(
          pageRange,
          this.createPage(i, this.matPag.pageIndex)
        );
      }
    }
    if (paglast > showTotalPages) {
      if (endingPage < paglast - 3) {
        if (!dots) {
          this.ren.insertBefore(
            pageRange,
            this.createPage(this.pageGapTxt, this.matPag.pageIndex),
            null
          );
          dots = true;
        }
        this.ren.appendChild(
          pageRange,
          this.createPage(paglast - 1, this.matPag.pageIndex)
        );
      }
      if (startingPage > 0) {
        if (!dots) {
          this.ren.insertBefore(
            pageRange,
            this.createPage(this.pageGapTxt, this.matPag.pageIndex),
            pageRange.firstChild
          );
          this.ren.insertBefore(
            pageRange,
            this.createPage(0, this.matPag.pageIndex),
            pageRange.firstChild
          );
          dots = true;
        }
      }
    }
  }

  public changePage = (i: number, isSwitchPage: boolean) => {
    const index = i + 1;
    this.currentPage = index;
    if (isSwitchPage) this.switchPage(index - 1);
  };

  private createPage(i: any, pageIndex: number): any {
    const linkBtn = this.ren.createElement('mat-button');
    this.ren.addClass(linkBtn, 'mat-icon-button');

    const pagingTxt = isNaN(i) ? this.pageGapTxt : +(i + 1);
    const text = this.ren.createText(pagingTxt + '');
    this.ren.addClass(linkBtn, 'mat-custom-page');

    switch (i) {
      case pageIndex:
        this.ren.setAttribute(linkBtn, 'disabled', 'disabled');
        this.ren.addClass(linkBtn, 'color');

        break;
      case this.pageGapTxt:
        this.ren.setAttribute(linkBtn, 'disabled', 'disabled');
        break;
      default:
        this.ren.listen(linkBtn, 'click', () => {
          this.changePage(i, true);
        });
        break;
    }

    this.ren.appendChild(linkBtn, text);
    return linkBtn;
  }

  private initPageRange(): void {
    const pagingContainerMain = this.vr.element.nativeElement.querySelector(
      '.mat-paginator-range-actions'
    );

    if (
      this.vr.element.nativeElement.querySelector(
        'div.mat-paginator-range-actions div.btn_custom-paging-container'
      )
    ) {
      this.ren.removeChild(
        pagingContainerMain,
        this.vr.element.nativeElement.querySelector(
          'div.mat-paginator-range-actions div.btn_custom-paging-container'
        )
      );
    }

    const pagingContainerBtns = this.ren.createElement('div');
    const refNode =
      this.vr.element.nativeElement.childNodes[0].childNodes[0].childNodes[2]
        .childNodes[5];
    this.ren.addClass(pagingContainerBtns, 'btn_custom-paging-container');
    this.ren.insertBefore(pagingContainerMain, pagingContainerBtns, refNode);

    const pageRange = this.vr.element.nativeElement.querySelector(
      'div.mat-paginator-range-actions div.btn_custom-paging-container'
    );
    pageRange.innerHtml = '';
    const pageCount = this.pageCount(this.matPag.length, this.matPag.pageSize);
    this.buildPageNumbers(pageCount, pageRange);
  }

  private pageCount(length: number, pageSize: number): number {
    const pageLength = length / pageSize;
    return Number.isInteger(pageLength)
      ? pageLength
      : Math.floor(pageLength) + 1;
  }

  private switchPage(i: number): void {
    this.matPag.pageIndex = i;
    this.matPag._changePageSize(this.matPag.pageSize);
  }

  public ngAfterViewInit() {
    setTimeout(() => {
      this.directiveLoaded = true;
    }, 500);
  }

  public ngDoCheck() {
    if (this.directiveLoaded) {
      const cPageNum = parseInt(this.currentPage, 10);
      this.matPag.pageIndex = cPageNum > 0 ? cPageNum - 1 : 0;
      this.initPageRange();
    }
  }
}
