<div
  class="alert-link"
  style="
    background-color: #040b21;
    padding-top: 110px;
    background-image: url(assets/images/BG_Web.png);
    height: calc(100vh - 110px);
  "
>
  <div class="investInfo">
    <p style="line-height: 1.2">
      We have amazing investment opportunities for you
    </p>

    <div class="coloredText" style="line-height: 1.2">
      But first, what kind of investor do you see yourself as?
    </div>
  </div>
  <mat-spinner
    *ngIf="showProgress == true"
    class="mat-spinner-color"
    style="justify-content: center; align-items: center; margin: 0 auto"
    [diameter]="70"
    mode="indeterminate"
  ></mat-spinner>
  <div class="d-flex container">
    <div
      *ngFor="let choose of chooseType; let i = index"
      (click)="type(i)"
      class="frame"
    >
      <div class="investmentCard">
        <img
          (click)="question(i)"
          class="question"
          src="assets/images/Question.png"
        />
        <img class="icon" [src]="choose.image" />
        <div class="textContainer">
          <div *ngIf="choose != null && choose.name != null" class="name">
            {{ choose.name }}
          </div>
          <div class="description">
            {{ choose.description }}
          </div>
          <img
            class="arrowIcon"
            src="assets/images/right-arrow-svgrepo-com.svg"
          />
        </div>
      </div>
    </div>
  </div>

  <div class="buttonContainer">
    <button class="btnGreen" (click)="whoMi()">
      <span class="btnGreenSpan">Simplifying Investment Decisions</span>
    </button>
  </div>
</div>
