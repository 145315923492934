import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlgoNameRouting, timeFramesArrays } from 'src/app/models/stockType';
import { topDataService } from 'src/app/services/topData.service';

@Component({
  selector: 'app-back-step',
  templateUrl: './back-step.component.html',
  styleUrls: ['./back-step.component.css'],
})
export class BackStepComponent implements OnInit {
  public innerWidth;
  currentUrl: string = '';
  constructor(private router: Router, private topDateService: topDataService) {}

  ngOnInit(): void {
    this.innerWidth = window.innerWidth;
    this.currentUrl = this.router.routerState.snapshot.url;
  }

  backToTop = (): void => {
    const fromHomePage = sessionStorage.fromHomePage;
    const algoRoutes = {
      'Capital Preservation': AlgoNameRouting.CAPITAL,
      'Momentum': AlgoNameRouting.MOMENTUM,
      'Buy the Dip': AlgoNameRouting.BDD,
      'Social Sentiment': AlgoNameRouting.SOCIAL,
      'Value Investing': AlgoNameRouting.VALUE,
    };
    const algoName = this.topDateService.getName();
    if (fromHomePage == 'true') {
      this.router.navigate(['../']);
    } else {
      const timeFrames =
        algoName === 'Value Investing'
          ? timeFramesArrays.value
          : timeFramesArrays.otherAlgos;
      const id: number = this.topDateService.getIndexOfFilterInTop();
      const period = timeFrames[id];

      algoName
        ? this.router.navigate(['../top', algoRoutes[algoName]], {
            queryParams: { period },
          })
        : this.router.navigate(['../']);
    }
  };

  choose() {
    this.router.navigate(['../']);
  }
}
