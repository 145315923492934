  <img *ngIf="name=='Capital Preservation'" src="assets/images/Popup_Conservative.png" class="img" >
  <img *ngIf="name=='Momentum'" src="assets/images/Popup_Momentum.png" class="img">
  <img *ngIf="name=='Buy the Dip'" src="assets/images/Popup_Buy_The_Dip.png" class="img" >
  <img *ngIf="name=='Social Sentiment'" src="assets/images/Popup_Social_Sentiment.png" class="img">
  <img *ngIf="name=='Value Investing'" src="assets/images/Popup_Value_Investment.png" class="img" >
  <img (click)="close()" class="close" src="assets/images/Close_Popup.png">
  <div style="display: flex; justify-content: center;">
    <img *ngIf="showProgress==false" (click)="next()" class="button" src="assets/images/Top10_Button.png">
  </div>

