<div class="container">
  <form class="userInfo" [formGroup]="settingsForm" (ngSubmit)="onSubmit()">
    <h1 class="title">User Settings</h1>
    <p class="errMessage">{{ errMessage }}</p>
    <span class="inputContainer">
      <div class="infoBlock">
        <label for="firstName" class="infoTitle">First Name</label>
        <input
          class="infoText"
          value="{{ userInfo.firstName }}"
          type="text"
          id="firstName"
          formControlName="firstName"
        />
      </div>
      <div class="infoBlock">
        <label for="lastName" class="infoTitle">Last Name</label>
        <input
          class="infoText"
          value="{{ userInfo.lastName }}"
          type="text"
          id="lastName"
          formControlName="lastName"
        />
      </div>
      <div class="infoBlock">
        <label for="email" class="infoTitle">Email</label>
        <input
          class="infoText"
          value="{{ userInfo.email }}"
          type="email"
          id="email"
          formControlName="email"
        />
      </div>
      <div class="infoBlock">
        <label for="phoneNumber" class="infoTitle">Phone</label>
        <input
          class="infoText"
          id="phoneNumber"
          ng2TelInput
          [ng2TelInputOptions]="{ initialCountry: changeFlag() }"
          (countryChange)="onCountryChange($event)"
          value="{{ userInfo.phoneNumber }}"
          placeholder="Enter your phone number"
          type="text"
          id="phoneNumber"
          formControlName="phoneNumber"
        />
        <span class="dialCode" matPrefix style="width: max-content">
          {{ countryCode }}
        </span>
      </div>

      <div class="infoBlock">
        <label for="country" class="infoTitle">Country</label>
        <input
          class="infoText"
          value="{{ userInfo.country }}"
          type="text"
          id="country"
          formControlName="country"
        />
      </div>
    </span>
    <button class="button" type="submit" [disabled]="isFormChanged">
      Save changes
    </button>
  </form>
</div>
