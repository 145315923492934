<div
  style="align-items: center; border: none; font-family: OpenSansHebrewLight"
>
  <form
    class="form-signin form"
    [formGroup]="contactForm"
    style="height: inherit"
  >
    <img (click)="close()" class="close" src="assets/images/Close_Popup.png" />
    <div style="text-align: center">
      <div style="padding-top: 45px; color: #00caa7; font-size: 22px">
        Want to know more?
      </div>
      <div
        style="
          color: white;
          font-size: 21px;
          margin-top: 15px;
          line-height: 1.5;
        "
      >
        Our team of advisors will
      </div>
      <div style="color: white; font-size: 21px">get back to you shortly</div>

      <div
        class="txt-input"
        style="margin-top: 50px"
        [ngStyle]="{
          'background-image':
            'url(' +
            (!isValidInput('userName')
              ? 'assets/images/Conect_Popup_Your_Name.png'
              : 'assets/images/Conect_Popup_Your_Name_Error.png') +
            ')'
        }"
      >
        <input
          [(ngModel)]="contectUser.name"
          matInput
          name="userName"
          formControlName="userName"
          type="text"
          #ft01
          (keyup.enter)="keytab($event)"
          autofocus
          required
        />
      </div>

      <div
        class="txt-input"
        [ngStyle]="{
          'background-image':
            'url(' +
            (!isValidInputMail('userEmail')
              ? 'assets/images/Conect_Popup_Your_Mail.png'
              : 'assets/images/Conect_Popup_Your_Mail_Error.png') +
            ')'
        }"
      >
        <input
          [(ngModel)]="contectUser.email"
          matInput
          name="userEmail"
          formControlName="userEmail"
          type="email"
          #ft01
          (keyup.enter)="keytab($event)"
          autofocus
          required
        />
      </div>

      <div
        class="txt-input"
        [ngStyle]="{
          'background-image':
            'url(' +
            (!isValidInputAddress('userAddress')
              ? 'assets/images/Conect_Popup_Your_Address.png'
              : 'assets/images/Conect_Popup_Your_Address_Error.png') +
            ')'
        }"
      >
        <input
          [(ngModel)]="contectUser.address"
          matInput
          name="userAddress"
          formControlName="userAddress"
          type="text"
          #ft01
          (keyup.enter)="keytab($event)"
          autofocus
          required
        />
      </div>

      <div
        class="txt-input"
        [ngStyle]="{
          'background-image':
            'url(' +
            (!isValidInputPhone('userPhone')
              ? 'assets/images/Conect_Popup_Your_Phone.png'
              : 'assets/images/Conect_Popup_Your_Phone_Error.png') +
            ')'
        }"
      >
        <input
          [(ngModel)]="contectUser.phoneNumber"
          matInput
          name="userPhone"
          formControlName="userPhone"
          type="number"
          #ft01
          (keyup.enter)="keytab($event)"
          autofocus
          required
        />
      </div>

      <div
        class="d-flex"
        style="color: white; font-size: 12px; margin-top: 40px"
      >
        <div style="font-size: 17px">Accredited investors?</div>
        <div class="d-flex" style="margin-left: 2%">
          <div
            class="yesOrNo"
            (click)="yes()"
            [ngStyle]="{
              'background-image':
                'url(' +
                (isYes == true
                  ? 'assets/images/Yes_On.png'
                  : 'assets/images/Yes_Off.png') +
                ')'
            }"
          >
            <span
              style="font-size: 14px"
              [ngStyle]="{ color: isYes === true ? 'white' : 'black' }"
              >Yes</span
            >
          </div>
          <div
            class="yesOrNo"
            (click)="no()"
            [ngStyle]="{
              'background-image':
                'url(' +
                (isYes == true
                  ? 'assets/images/No_Off.png'
                  : 'assets/images/No_On.png') +
                ')'
            }"
          >
            <span
              style="font-size: 14px"
              [ngStyle]="{ color: isYes === true ? 'black' : 'white' }"
              >No</span
            >
          </div>
        </div>
      </div>
      <div style="display: flex; justify-content: center">
        <img
          (click)="send()"
          [ngClass]="innerHeight > 740 ? 'button2' : 'button'"
          src="assets/images/Button_3.png"
        />
      </div>
    </div>
  </form>
</div>
