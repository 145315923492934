<app-back-step></app-back-step>

<div
  style="
    height: 87.5vh;
    align-items: center;
    border: none;
    font-family: OpenSansHebrewLight;
    background-image: url(assets/images/BG_Web.png);
  "
>
  <div>
    <img (click)="next()" class="img" [src]="'assets/images/' + img" alt="" />
    <img
      (click)="next()"
      class="button"
      src="assets/images/Check_Top_10_Button.png"
      alt=""
    />
  </div>
</div>
