import { AfterViewInit, Component, ElementRef, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import parsePhoneNumberFromString from 'libphonenumber-js';
import { PageType } from 'src/app/models/signup';
import { AppProxy } from 'src/app/services/app-proxy';
import { FormDataService } from 'src/app/services/shared.service';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css'],
})
export class SignupComponent implements OnInit, AfterViewInit {
  PageType = PageType;
  signupForm: FormGroup;
  verifyCodeForm: FormGroup;
  countryCode: string = '+1';
  checkValidate: boolean = false;
  step: number = 1;
  message: string = '';
  buttonDisable: boolean = true;
  path: string;
  currentUrl: string;
  pageType: string = PageType.SIGNUP;
  inputElement: any;
  password: FormControl = new FormControl('', [
    Validators.required,
    Validators.minLength(5),
  ]);
  firstName: FormControl = new FormControl('', [
    Validators.required,
    Validators.minLength(2),
  ]);
  lastName: FormControl = new FormControl('', [
    Validators.required,
    Validators.minLength(2),
  ]);
  country: FormControl = new FormControl('', [
    Validators.required,
    Validators.minLength(2),
  ]);
  email: FormControl = new FormControl('', [
    Validators.required,
    Validators.email,
  ]);
  phone: FormControl = new FormControl('', [
    Validators.required,
    Validators.minLength(5),
  ]);
  terms: FormControl = new FormControl();
  code: FormControl = new FormControl('', [
    Validators.required,
    Validators.minLength(4),
    Validators.minLength(4),
  ]);

  constructor(
    private formBuilder: FormBuilder,
    private proxy: AppProxy,
    private router: Router,
    private formDataService: FormDataService,
    private el: ElementRef
  ) {
    this.signupForm = this.formBuilder.group({
      firstName: this.firstName,
      lastName: this.lastName,
      email: this.email,
      phoneNumber: this.phone,
      country: this.country,
      password: this.password,
      terms: this.terms,
    });

    this.verifyCodeForm = this.formBuilder.group({
      code: this.code,
    });
  }

  ngOnInit() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.currentUrl = this.router.routerState.snapshot.url;
        if (
          this.currentUrl !== '/signup' &&
          this.currentUrl !== '/termAndConditions'
        ) {
          this.signupForm.controls.firstName.setValue('');
          this.signupForm.controls.lastName.setValue('');
          this.signupForm.controls.country.setValue('');
          this.signupForm.controls.password.setValue('');
          this.signupForm.controls.phoneNumber.setValue('');
          this.signupForm.controls.email.setValue('');
          this.signupForm.controls.terms.setValue(false);
          this.formDataService.setTerms(false);
        }
      }
    });
    const token = localStorage.getItem('token');
    if (token) {
      this.router.navigate(['/']);
    }

    const formData = this.formDataService.getFormData();
    if (formData) {
      this.signupForm.controls.firstName.setValue(formData.firstName);
      this.signupForm.controls.lastName.setValue(formData.lastName);
      this.signupForm.controls.country.setValue(formData.country);
      this.signupForm.controls.password.setValue(formData.password);
      this.signupForm.controls.phoneNumber.setValue(formData.phoneNumber);
      this.signupForm.controls.email.setValue(formData.email);
      this.signupForm.controls.terms.setValue(formData.terms);
    }

    this.formDataService.currentProperty.subscribe((propertyName) => {
      if (propertyName === 'terms') {
        const formData = this.formDataService.getFormData();
        this.buttonDisable = Object.values(formData).includes('');
        const terms = this.formDataService.getTerms();
        this.signupForm.controls.terms.setValue(terms);
      }
    });

    this.signupForm.valueChanges.subscribe((form: FormGroup) => {
      this.buttonDisable = Object.values(form).includes('');
      this.formDataService.setFormData(form);
    });
  }

  ngAfterViewInit(): void {
    this.inputElement = this.el.nativeElement.querySelector('#phoneInput');
    const widthOfCountryCode = this.countryCodeWidth(
      this.countryCode.length - 1
    );
    this.inputElement.style.paddingLeft = 90 - widthOfCountryCode + 'px';
  }

  keyPress(event: any) {
    const pattern = /[0-9\+\-\ ]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (
      (event.keyCode != 8 && !pattern.test(inputChar)) ||
      event.keyCode === 43 ||
      event.keyCode === 45 ||
      event.keyCode === 32
    ) {
      event.preventDefault();
    }
  }

  onTerms() {
    this.router.navigate(['termAndConditions']);
  }

  onLogin() {
    this.router.navigate(['login']);
  }

  onCountryChange(event) {
    if (event.dialCode) {
      const widthOfCountryCode = this.countryCodeWidth(event.dialCode.length);
      this.inputElement.style.paddingLeft = 90 - widthOfCountryCode + 'px';
      this.countryCode = '+' + event.dialCode;
    }
  }

  countryCodeWidth(dialCodeLength: number): number {
    const countryCodeWidth =
      dialCodeLength + 1 === 2 ? 16 : dialCodeLength + 1 === 3 ? 4 : -10;
    return countryCodeWidth;
  }

  onSignup() {
    this.pageType = PageType.SIGNUP;
  }

  onSubmit() {
    if (this.signupForm.valid && this.signupForm.value.terms) {
      this.checkValidate = false;
      const isCorrectNumber = parsePhoneNumberFromString(
        `${this.countryCode}${this.signupForm.value.phoneNumber}`
      );
      if (isCorrectNumber) {
        if (isCorrectNumber.country) {
          const formObj = {
            firstName: this.signupForm.value.firstName,
            lastName: this.signupForm.value.lastName,
            country: this.signupForm.value.country,
            email: this.signupForm.value.email,
            phoneNumber: this.signupForm.value.phoneNumber,
            password: this.signupForm.value.password,
            role: this.signupForm.value.role,
            terms: this.signupForm.value.terms,
          };
          this.formDataService.setFormData(formObj);
          this.message = '';
          this.checkValidate = false;
          const url = this.proxy.baseQAUrl + 'auth/signup';
          const values = {
            ...formObj,
            phoneNumber: `${this.countryCode}${formObj.phoneNumber}`,
          };

          this.proxy.executePost(url, values).subscribe((data: any) => {
            if (data?.success) {
              this.step = 2;
            }
            if (!data?.success) {
              this.message = data?.message;
            }
          });
        } else {
          this.message = 'Invalid Phone Number';
        }
      } else {
        this.message = 'Invalid Phone Number';
      }
    } else {
      this.checkValidate = true;
    }
  }

  onConfirm() {
    if (this.verifyCodeForm.valid) {
      this.message = '';
      this.checkValidate = false;
      const url = this.proxy.baseQAUrl + 'auth/' + 'confirmAccount';
      const values = {
        email: this.signupForm.value.email,
        code: this.verifyCodeForm.value.code,
      };

      this.proxy.executePost(url, values).subscribe((data: any) => {
        if (data?.success) {
          localStorage.setItem('token', data.token);
          localStorage.setItem('role', data.role);
          this.router.navigate(['/pending']);
        }
        if (!data?.seccess) {
          this.message = data?.message;
        }
      });
    }
  }
}
