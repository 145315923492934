import { Component } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { UserStatus } from 'src/app/models/user';
import { AppProxy } from 'src/app/services/app-proxy';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent {
  loginForm: FormGroup;
  verifyCodeForm: FormGroup;
  message: string = '';
  step: number = 1;
  checkValidate: boolean = false;
  email: FormControl = new FormControl('', [
    Validators.required,
    Validators.email,
  ]);
  password: FormControl = new FormControl('', [
    Validators.required,
    Validators.minLength(5),
  ]);
  code: FormControl = new FormControl('', [
    Validators.required,
    Validators.minLength(4),
    Validators.minLength(4),
  ]);

  constructor(
    private formBuilder: FormBuilder,
    private proxy: AppProxy,
    private router: Router
  ) {
    this.loginForm = this.formBuilder.group({
      email: this.email,
      password: this.password,
    });
    this.verifyCodeForm = this.formBuilder.group({
      code: this.code,
    });
  }

  ngOnInit() {
    const token = localStorage.getItem('token');
    if (token) {
      this.router.navigate(['/']);
    }
  }

  onSignup() {
    this.router.navigate(['signup']);
  }

  onResetPassword() {
    this.router.navigate(['forgotPassword']);
  }

  onSubmit() {
    if (this.loginForm.valid) {
      this.message = '';
      const url = this.proxy.baseQAUrl + 'auth/' + 'login';
      const values = this.loginForm.value;
      this.proxy.executePost(url, values).subscribe((data: any) => {
        if (data?.data?.status === 'Pending') {
          this.router.navigate(['/pending']);
        } else if (data?.data?.status === 'Rejected') {
          this.router.navigate(['/rejected']);
        } else if (data?.data?.status === 'Approved') {
          this.router.navigate(['/']);
        }
        if (data.success) {
          localStorage.setItem('token', data?.token);
          localStorage.setItem('role', data?.data?.role);
          localStorage.setItem('currentId', data?.data?.id);
        }
        if (!data.success) {
          if (data?.status === 402) {
            this.step = 2;
          }
          this.message = data?.message;
        }
      });
    }
  }

  onConfirm() {
    if (this.verifyCodeForm.valid) {
      this.message = '';
      this.checkValidate = false;
      const url = this.proxy.baseQAUrl + 'auth/' + 'confirmAccount';
      const values = {
        email: this.loginForm.value.email,
        code: this.verifyCodeForm.value.code,
      };
      this.proxy.executePost(url, values).subscribe((data: any) => {
        if (data?.success) {
          localStorage.setItem('token', data.message);
          if (data?.data?.status === 'Pending') {
            this.router.navigate(['/pending']);
          } else if (data?.data?.status === 'Rejected') {
            this.router.navigate(['/rejected']);
          } else {
            this.router.navigate(['/']);
          }
        }
        if (!data?.seccess) {
          this.message = data?.message;
        }
      });
    }
  }

  back() {
    this.message = '';
    this.step = 1;
  }
}
