import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FormDataService {
  private formData: any;
  terms: boolean = false;

  private propertySource = new BehaviorSubject<string>('initial value');
  currentProperty = this.propertySource.asObservable();

  updateProperty(propertyName: string, newValue: boolean): void {
    switch (propertyName) {
      case 'terms':
        this.setTerms(newValue);
        break;
      default:
        console.error(`Invalid property name: ${propertyName}`);
        return;
    }

    this.propertySource.next('terms');
  }

  setTerms(value): void {
    this.terms = value;
  }

  getTerms(): boolean {
    return this.terms;
  }

  setFormData(data: any) {
    this.formData = data;
  }

  getFormData() {
    return this.formData;
  }
}
