import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'choose-button',
  templateUrl: './chooseButton.component.html',
  styleUrls: ['./chooseButton.component.css'],
})
export class chooseButtonComponent {
  constructor(private router: Router) {}

  choose() {
    this.router.navigate(['../']);
  }
}
