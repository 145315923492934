import {
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from 'src/app/services/data.service';
import { AppProxy } from 'src/app/services/app-proxy';
import { AuthGuard } from 'src/app/services/guards/auth.guard';
import { StocksService } from 'src/app/services/stocks-service';
import { InputComponent } from 'src/app/input/input.component';
import { LoginComponent } from '../login/login.component';
import { SignupComponent } from '../signup/signup.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
  host: {
    '(document:click)': 'onClick($event)',
  },
})
export class HeaderComponent implements OnInit {
  @ViewChild(InputComponent) inputComponent: InputComponent;
  innerWidth: any;
  settings: boolean = false;
  character = true;
  isLoggedIn: boolean;
  token: boolean = this.auth.canActivate();
  searchResult: object;
  searchBorderColor: boolean = false;
  publicRoutes = ['/login', '/signup', '/forgotPassword', '/terms'];
  role: string;
  inputValue: string = '';

  @ViewChild(LoginComponent) loginComponent: LoginComponent;
  @ViewChild(SignupComponent) signupComponent: SignupComponent;

  constructor(
    private router: Router,
    private dataService: DataService,
    private proxy: AppProxy,
    private _eref: ElementRef,
    private auth: AuthGuard,
    private stockServie: StocksService
  ) {
    router.events.subscribe((val) => {
      this.settings = false;
      this.token = this.auth.getAuth();
      this.isLoggedIn = !!localStorage.getItem('currentId');
      this.role = localStorage.getItem('role');
    });
    this.isLoggedIn = !this.publicRoutes.includes(this.router.url);
    this.role = localStorage.getItem('role');
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    const clickedElement = event.target as HTMLElement;

    const isClickedInsideSearch =
      clickedElement.classList.contains('searchInput');

    if (!isClickedInsideSearch) {
      if (this.inputComponent) {
        if (
          clickedElement.classList.contains('searchInput') === false &&
          clickedElement.classList.value !== 'searchImg'
        ) {
          this.closeSearch();
        }
      }

      this.handleMenu(false);
    }
  }

  ngOnInit(): void {
    this.innerWidth = window.innerWidth;
    this.character = this.dataService.character.getValue() || {};
    this.settings = false;
    this.dataService.character.subscribe((data) => {
      this.character = data;
    });
  }

  choose() {
    this.router.navigate(['/login']);
  }

  isApproved() {
    const mainUrl = this.router.url.split('?')[0];
    return mainUrl !== '/pending' && mainUrl !== '/rejected';
  }

  back() {
    window.history.back();
  }

  main() {
    if (this.isApproved()) {
      this.router.navigate(['/']);
    } else {
      localStorage.clear();
      this.router.navigate(['/login']);
    }
  }

  handleMenu(val) {
    this.settings = val;
  }

  onInputChange() {
    const searchValue = this.inputComponent.value;
    this.search(searchValue);
  }

  onClick(event) {
    if (!this._eref.nativeElement.contains(event.target)) {
      this.settings = false;
    }
  }

  search(searchValue) {
    searchValue
      ? (this.searchBorderColor = true)
      : (this.searchBorderColor = false);
    searchValue = searchValue.toUpperCase();
    if (searchValue) {
      const res = this.stockServie
        .findStock(searchValue)
        .subscribe((data: object) => {
          this.searchResult = data;
        });
    } else {
      this.closeSearch();
    }
    this.inputValue = searchValue;
  }

  closeSearch() {
    this.inputComponent.value = '';
    this.searchResult = [];
    this.inputValue = '';
    this.searchBorderColor = false;
  }

  onSettings() {
    this.router.navigate(['../userSettings']);
    this.settings = false;
  }

  onAdmin() {
    this.router.navigate(['../admin']);
  }

  onDeleteAccount() {
    const url = this.proxy.baseQAUrl + 'user/' + 'deleteCurrentUser';

    this.proxy.executeUserGet(url).subscribe((data: any) => {
      if (data?.success) {
        localStorage.clear();
        this.settings = false;
        this.router.navigate(['../login']);
      }
    });
  }

  logout() {
    const url = this.proxy.baseQAUrl + 'auth/' + 'logout';
    this.isLoggedIn = null;

    this.proxy.execute(url).subscribe((data: any) => {
      if (data?.success) {
        localStorage.clear();
        this.settings = false;
        this.router.navigate(['../login']);
      }
    });
  }
}
