import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ContectUser } from 'src/app/models/user';
import { DataService } from 'src/app/services/data.service';
import { StocksService } from 'src/app/services/stocks-service';

@Component({
  selector: 'app-contact2',
  templateUrl: './contact2.component.html',
  styleUrls: ['./contact2.component.css'],
})
export class Contact2Component implements OnInit {
  @Output() showCharacter: EventEmitter<number> = new EventEmitter<number>();
  contactForm: FormGroup;
  contectUser: ContectUser;
  contectUserList: ContectUser[] = [];
  yesOn = 'assets/images/Yes_On.png';
  yesOff = 'assets/images/Yes_Off.png';
  noOn = 'assets/images/No_On.png';
  noOff = 'assets/images/No_Off.png';
  isYes: boolean = true;
  innerHeight: any;
  valid: boolean = false;
  phone = 'Phone number';
  mail = 'Your email';
  name = 'Your name';
  address = 'Your address';

  constructor(
    private dataService: DataService,
    private fb: FormBuilder,
    private matDialog: MatDialog,
    private stockService: StocksService
  ) {
    this.contectUser = new ContectUser();
  }

  ngOnInit(): void {
    this.innerHeight = window.innerHeight;
    this.createForm();
    this.dataService.character.next(true);
    this.showCharacter.emit();
  }

  send() {
    this.valid = true;
    if (this.contactForm.valid) {
      this.stockService.contact(this.contectUser).subscribe((res: any) => {
        this.contectUserList = res;
        window.history.back();
      });
    }
  }

  isValidInput(fieldName): boolean {
    if (this.valid == true) {
      this.name = this.contactForm.controls[fieldName].invalid
        ? 'Error name'
        : 'Your name';
      return this.contactForm.controls[fieldName].invalid;
    }
  }

  isValidInputPhone(fieldName): boolean {
    if (this.valid == true) {
      this.phone = this.contactForm.controls[fieldName].invalid
        ? 'Error phone'
        : 'Phone number';
      return this.contactForm.controls[fieldName].invalid;
    }
  }

  isValidInputAddress(fieldName): boolean {
    if (this.valid == true) {
      this.address = this.contactForm.controls[fieldName].invalid
        ? 'Error address'
        : 'Your address';
      return this.contactForm.controls[fieldName].invalid;
    }
  }

  isValidInputMail(fieldName): boolean {
    if (this.valid == true) {
      this.mail = this.contactForm.controls[fieldName].invalid
        ? 'Error email'
        : 'Your email';
      return this.contactForm.controls[fieldName].invalid;
    }
  }

  no() {
    if (this.isYes == true) this.isYes = false;
  }

  yes() {
    if (this.isYes == false) {
      this.isYes = true;
    }
  }

  keytab(event) {
    let element = event.srcElement.nextElementSibling; // get the sibling element
    if (element) element.focus();
  }

  createForm() {
    this.contactForm = this.fb.group({
      userName: new FormControl('', Validators.required),
      userEmail: new FormControl('', [
        Validators.email,
        Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
      ]),
      userAddress: new FormControl('', Validators.required),
      userPhone: new FormControl('', [
        Validators.required,
        Validators.pattern('^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-s./0-9]*$'),
      ]),
    });
  }
}
