import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-who-mi',
  templateUrl: './who-mi.component.html',
  styleUrls: ['./who-mi.component.css']
})
export class WhoMiComponent implements OnInit {
  innerWidth:any;
  img="assets/images/Open_Page_PC_Text_Only.png"

  constructor() { }

  ngOnInit(): void {
  window.scroll(0,0);
  this.innerWidth=window.innerWidth;
  if(this.innerWidth<=480)
  this.img="assets/images/Open_Page_Mobile_Text_Only.png";
  }
}
