<div class="container">
  <div class="form">
    <span class="titleContainer">
      <img
        src="assets/images/Vector.png"
        (click)="onLogin()"
        alt=""
        class="arrowIcon"
      />
      <h1 class="title">Reset Password</h1>
    </span>
    <p class="error">{{ message }}</p>
    <form
      class="formWrapper"
      [formGroup]="step === 1 ? sendEmailForm : resetPasswordForm"
      (ngSubmit)="step === 1 ? onSendEmail() : onResetPassword()"
    >
      <div class="inputs" *ngIf="step === 1">
        <span class="inputBlock">
          <label for="email">Email</label>
          <input
            class="input"
            type="email"
            id="email"
            formControlName="email"
          />
        </span>
      </div>
      <div class="inputs" *ngIf="step === 2">
        <span class="inputBlock">
          <label for="code">Code from registered email</label>
          <input class="input" type="text" id="code" formControlName="code" />
          <button
            (click)="onResetCode()"
            [disabled]="timer"
            class="resendButton"
            type="button"
          >
            Resend Code
          </button>
          <div class="timer" *ngIf="timer">
            You can resend email after {{ resendCodeTimer }} seconds
          </div>
        </span>
        <span class="inputBlock">
          <label for="password">New Password</label>
          <input
            class="input"
            type="password"
            id="password"
            formControlName="password"
          />
        </span>
        <span class="inputBlock">
          <label for="confirmPassword">Confirm Password</label>
          <input
            class="input"
            type="password"
            id="password"
            formControlName="confirmPassword"
          />
        </span>
      </div>
      <button
        class="button"
        type="submit"
        [disabled]="
          step === 1 ? !sendEmailForm.valid : !resetPasswordForm.valid
        "
      >
        {{ step === 1 ? "Send Email" : "Reset Password" }}
      </button>
    </form>
  </div>
</div>
