<div style="height: 87.5vh; align-items: center; border: none; font-family: OpenSansHebrewLight; background-image: url(assets/images/BG_Web.png);">
  <form class="form-signin form" [formGroup]="contactForm" style="height: inherit;" > 
    <app-back-step></app-back-step>
     <div style="text-align: center;">
          <div class="header">Want to know more?</div>
          <div class="header2">Our team of advisors will get back to you shortly</div>
          <div class="frame" style="background-image: url(assets/images/Detele_BG.png);">
              <div style="width: 78%; position: absolute; right: 0; left:0; margin: auto;">
              <div class="d-flex">
    <div class="txt-input" style="margin-top: 70px;" [ngStyle]="{'background-image':!isValidInput('userName') ? 'url(assets/images/Conect_Web_Popup_Your_Name.png)' : 'url(assets/images/Conect_Web_Popup_Your_Name_Error.png)'}">
        <input [(ngModel)]="contectUser.name" matInput name="userName" formControlName="userName" type="text" #ft01 (keyup.enter)="keytab($event)" autofocus required/>
    </div>
     <div class="txt-input" style="margin-top: 70px; position: absolute; right: 0;" [ngStyle]="{'background-image':!isValidInputMail('userEmail') ? 'url(assets/images/Conect_Web_Popup_Your_Mail.png)' : 'url(assets/images/Conect_Web_Popup_Your_Mail_Error.png)'}">
     <input [(ngModel)]="contectUser.email" matInput name="userEmail" formControlName="userEmail" type="email" #ft01 (keyup.enter)="keytab($event)" autofocus required/>
 </div>
</div>

<div class="d-flex">
 <div class="txt-input" [ngStyle]="{'background-image':!isValidInputAddress('userAddress') ? 'url(assets/images/Conect_Web_Popup_Your_Address.png)' : 'url(assets/images/Conect_Web_Popup_Your_Address_Error.png)'}">
   <input [(ngModel)]="contectUser.address" matInput name="userAddress" formControlName="userAddress" type="text" #ft01 (keyup.enter)="keytab($event)" autofocus required/>
</div>

<div class="txt-input" style="position: absolute; right: 0;" [ngStyle]="{'background-image':!isValidInputPhone('userPhone') ? 'url(assets/images/Conect_Web_Popup_Your_Phone.png)' : 'url(assets/images/Conect_Web_Popup_Your_Phone_Error.png)'}">
 <input [(ngModel)]="contectUser.phoneNumber" matInput name="userPhone" formControlName="userPhone" type="number" #ft01 (keyup.enter)="keytab($event)" autofocus required/>
</div>
</div>

<div class="d-flex" style="color: white; font-size: 12px; margin-top: 15px;">
 <div style="font-size: 20px; color: white; ">Accredited investors?</div>
 <div class="d-flex" style="margin-left: 2%;">
 <div class="yes" (click)="yes()" [ngStyle]="{'background-image':isYes === true ? 'url(assets/images/Yes_Button_On_Web.png)' : 'url(assets/images/Yes_Button_Off_Web.png)'}">
   <span style="font-size: 14px;"[ngStyle]="{'color':isYes === true ? 'white' : 'black' }">Yes</span>
</div>
<div class="no" (click)="no()" [ngStyle]="{'background-image':isYes === true ? 'url(assets/images/No_Button_Off_Web.png)' : 'url(assets/images/No_Button_On_Web.png)'}">
 <span style="font-size: 14px;"  [ngStyle]="{'color':isYes === true ? 'black' : 'white' }">No</span>
</div>
</div>
</div>
</div>
<img (click)="send()" class="button" src="assets/images/Send_Button.png">
</div>
     </div>
   </form>
 </div>

 
