import {
  AfterViewInit,
  Component,
  ElementRef,
  OnChanges,
  Renderer2,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import {
  MatPaginator,
  MatPaginatorIntl,
  PageEvent,
} from '@angular/material/paginator';
import { UserService } from 'src/app/services/users.service';
import { StylePaginatorDirective } from './paginator';
import { ToastrService } from 'ngx-toastr';
import { UserStatus } from 'src/app/models/user';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-top',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css'],
})
export class TableComponent implements AfterViewInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(StylePaginatorDirective) pageChanger: StylePaginatorDirective;
  innerWidth: any;
  displayedColumns: string[] = [
    'id',
    'firstName',
    'lastName',
    'email',
    'country',
    'role',
    'phoneNumber',
    'status',
    'edit',
    'remove',
  ];
  dataSource = new MatTableDataSource<any>([]);
  parameters = {
    page: 0,
    size: 10,
  };
  searchParams = {
    email: null,
    page: 0,
    size: 10,
  };
  searchUser: string;
  totalCount: number;
  roles = ['Admin', 'User'];
  status = UserStatus;
  pageSize: BehaviorSubject<number> = new BehaviorSubject<number>(10);
  currentUserId: number;

  constructor(
    private userService: UserService,
    private paginatorIntl: MatPaginatorIntl,
    private toastr: ToastrService,
    private renderer: Renderer2,
    private el: ElementRef
  ) {
    this.paginatorIntl.getRangeLabel = (
      page: number,
      pageSize: number,
      length: number
    ) => {
      const startIndex = page * pageSize + 1;
      const endIndex = Math.min(length, (page + 1) * pageSize);
      return `Showing ${startIndex} - ${endIndex} of ${length} results`;
    };
  }

  ngOnInit(): void {
    this.innerWidth = window.innerWidth;
    this.userService.getAllUsers(this.parameters).subscribe((data: any) => {
      this.totalCount = data.pagination.totalItems;
      this.dataSource = new MatTableDataSource<any>(
        data.data.map((user: any) => ({
          ...user,
          editDisabled: true,
          originalRole: user.role,
          originalStatus: user.status,
        })) as any
      );
      this.dataSource.paginator = this.paginator;
      this.currentUserId = JSON.parse(localStorage.getItem('currentId'));
      this.dataSource.paginator._intl.itemsPerPageLabel = 'Showing';
      this.dataSource.paginator._intl.firstPageLabel = 'first';
      this.dataSource.paginator._intl.lastPageLabel = 'last';
    });
  }

  ngAfterViewInit() {
    this.pageSize.subscribe(() => {
      if (window.innerWidth > 480) {
        this.chnageWidth();
      }
    });
  }

  chnageWidth = (): void => {
    setTimeout(() => {
      const nextBtn = this.el.nativeElement.querySelector(
        '.mat-paginator-navigation-next'
      );
      const paginatorContainer = this.el.nativeElement.querySelector(
        '.btn_custom-paging-container'
      );
      const paginatorWidth = paginatorContainer.getBoundingClientRect().width;

      this.renderer.setStyle(
        nextBtn,
        'left',
        `${paginatorWidth + (window.innerWidth > 1600 ? 480 : 330)}px`
      );
    }, 500);
  };

  public searchText(email: string) {
    this.paginator.firstPage();
    this.searchParams.email = email;
    this.userService.getUser(this.searchParams).subscribe((data: any) => {
      this.totalCount = data.pagination.totalItems;
      this.dataSource = new MatTableDataSource<any>(
        data.data.map((user: any) => ({
          ...user,
          editDisabled: true,
          originalRole: user.role,
          originalStatus: user.status,
        })) as any
      );
      this.dataSource.paginator = this.paginator;
    });
  }

  changeUserRole(element) {
    if (
      element.role === element.originalRole &&
      element.originalStatus === element.status
    ) {
      element.editDisabled = true;
      return;
    }
    element.originalRole = element.role;
    element.originalStatus = element.status;

    this.userService.updateUser(element).subscribe((data: any) => {
      if (data) {
        const index = this.dataSource.data.findIndex(
          (user) => user.id === element.id
        );
        this.dataSource.data[index].role = element.role;
        element.editDisabled = true;
        this.toastr.success('User updated successfully');
      } else {
        this.toastr.error('Failed to update user');
      }
    });
  }

  deleteUser(element) {
    this.userService.deleteUser(element).subscribe((data: any) => {
      this.dataSource = <any>(
        this.dataSource.data.filter((user: any) => user.id !== element.id)
      );
      if (data) {
        this.toastr.success('User deleted successfully');
      } else {
        this.toastr.error('Failed to delete user');
      }
    });
  }

  handleDisable(element) {
    element.editDisabled = !(
      element.role !== element.originalRole ||
      element.originalStatus !== element.status
    );
  }

  enumKeys(enumeration: any) {
    return Object.keys(enumeration);
  }

  public pageNavigate(event: PageEvent) {
    if (event.pageSize !== this.pageSize.getValue()) {
      this.pageSize.next(event.pageSize);
    }
    this.pageChanger.changePage(event.pageIndex, false);
    this.parameters.page = event.pageIndex + 1;
    this.parameters.size = event.pageSize;
    if (this.searchParams.email) {
      this.userService.getUser(this.searchParams).subscribe((data: any) => {
        this.totalCount = data.pagination.totalItems;
        this.dataSource = new MatTableDataSource<any>(
          data.data.map((user: any) => ({
            ...user,
            editDisabled: true,
            originalRole: user.role,
            originalStatus: user.status,
          })) as any
        );
        this.dataSource.paginator = this.paginator;
      });
    } else {
      this.userService.getAllUsers(this.parameters).subscribe((data: any) => {
        this.dataSource = new MatTableDataSource<any>(
          data.data.map((user: any) => ({
            ...user,
            editDisabled: true,
            originalRole: user.role,
            originalStatus: user.status,
          })) as any
        );
      });
    }
  }
}
