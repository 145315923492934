import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class topDataService {
  private type: string;
  private name: string;
  private description: string;
  private byDate: string;
  private position: string;
  private dateKey: string;
  private indexOfFilterInTop: number = 0;
  private previousName: string;

  private propertySource = new BehaviorSubject<string>('initial value');
  currentProperty = this.propertySource.asObservable();

  updateProperty(propertyName: string, newValue: string): void {
    switch (propertyName) {
      case 'name':
        this.setName(newValue);
        break;
      default:
        console.error(`Invalid property name: ${propertyName}`);
        return;
    }

    this.propertySource.next('name');
  }

  setType(value: string): void {
    console.log(value, 'type');

    this.type = value;
  }

  getType(): string {
    return this.type;
  }

  setName(value: string): void {
    console.log(value, 'name');

    this.name = value;
  }

  getName(): string {
    return this.name;
  }

  setDescription(value: string): void {
    console.log(value, 'description');

    this.description = value;
  }

  getDescription(): string {
    return this.description;
  }

  setByDate(value: string): void {
    console.log(value, 'byDate');

    this.byDate = value;
  }

  getByDate(): string {
    return this.byDate;
  }

  setPosition(value: string): void {
    console.log(value, 'position');

    this.position = value;
  }

  getPosition(): string {
    return this.position;
  }

  setDateKey(value: string): void {
    console.log(value, 'dateKey');

    this.dateKey = value;
  }

  getDateKey(): string {
    return this.dateKey;
  }

  setIndexOfFilterInTop(value: number): void {
    this.indexOfFilterInTop = value;
  }

  getIndexOfFilterInTop(): number {
    return this.indexOfFilterInTop;
  }

  setPreviousName(value: string): void {
    this.previousName = value;
  }

  getPreviousName(): string {
    return this.previousName;
  }
}
