import { AfterViewInit, Component, ElementRef, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AppProxy } from 'src/app/services/app-proxy';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

interface UserInfo {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  role: string;
  country: string;
  phoneNumber: string;
}

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css'],
})
export class SettingsComponent implements AfterViewInit {
  settingsForm: FormGroup;
  userInfo: UserInfo;
  originalFormValue: string;
  isFormChanged = true;
  dbPhoneNumber: string = '';
  countryCode: string = '';
  errMessage: string = '';
  inputElement: any;
  firstName: FormControl = new FormControl('', [
    Validators.required,
    Validators.minLength(2),
  ]);
  lastName: FormControl = new FormControl('', [
    Validators.required,
    Validators.minLength(2),
  ]);
  country: FormControl = new FormControl('', [
    Validators.required,
    Validators.minLength(2),
  ]);
  email: FormControl = new FormControl('', [
    Validators.required,
    Validators.email,
  ]);
  phoneNumber: FormControl = new FormControl('', [
    Validators.required,
    Validators.minLength(5),
  ]);
  id: FormControl = new FormControl('', [Validators.required]);

  constructor(
    private proxy: AppProxy,
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private el: ElementRef
  ) {
    this.settingsForm = this.formBuilder.group({
      firstName: this.firstName,
      lastName: this.lastName,
      email: this.email,
      phoneNumber: this.phoneNumber,
      country: this.country,
    });

    this.getData();
  }

  onSubmit() {
    if (this.settingsForm) {
      const url = this.proxy.baseQAUrl + `user/update/${this.userInfo.id}`;
      const isPhonenUmberWithCountryCode = !!parsePhoneNumberFromString(
        this.phoneNumber.value
      );
      const isPhoneNumberWithoutCountryCode = !!parsePhoneNumberFromString(
        `${this.countryCode}${this.phoneNumber.value}`
      );

      if (isPhonenUmberWithCountryCode || isPhoneNumberWithoutCountryCode) {
        if (isPhonenUmberWithCountryCode) {
          this.settingsForm
            .get('phoneNumber')
            .setValue(
              parsePhoneNumberFromString(this.phoneNumber.value).nationalNumber
            );
        }
        this.settingsForm.value.phoneNumber = `${this.countryCode}${this.phoneNumber.value}`;
        const isCorrectNumber = parsePhoneNumberFromString(
          this.settingsForm.value.phoneNumber
        );

        if (isCorrectNumber.country) {
          const values = {
            ...this.userInfo,
            ...this.settingsForm.value,
          };

          this.proxy.executePost(url, values).subscribe((data: any) => {
            data
              ? this.toastr.success('User updated successfully')
              : this.toastr.error('Failed to update user');
          });
          this.errMessage = '';
        } else {
          this.errMessage = 'Invalid phone number, please enter correct one';
        }
      } else {
        this.errMessage = 'Invalid phone number, please enter correct one';
      }
    }
  }

  ngAfterViewInit(): void {
    this.inputElement = this.el.nativeElement.querySelector('#phoneNumber');
    const widthOfCountryCode = this.countryCodeWidth(
      this.countryCode.length - 1
    );
    this.inputElement.style.paddingLeft = 85 - widthOfCountryCode + 'px';
  }

  changeFlag() {
    if (this.countryCode === '') {
      this.countryCode =
        '+' + parsePhoneNumberFromString(this.dbPhoneNumber).countryCallingCode;
    }
    this.settingsForm.get('phoneNumber').setValue(this.phoneNumber.value);
    return parsePhoneNumberFromString(this.dbPhoneNumber).country;
  }

  onCountryChange(event) {
    if (event.dialCode) {
      const widthOfCountryCode = this.countryCodeWidth(event.dialCode.length);
      this.inputElement.style.paddingLeft = 85 - widthOfCountryCode + 'px';
      this.countryCode = '+' + event.dialCode;
    }
  }

  countryCodeWidth(dialCodeLength: number): number {
    const countryCodeWidth =
      dialCodeLength + 1 === 2 ? 18 : dialCodeLength + 1 === 3 ? 6 : 0;
    return countryCodeWidth;
  }

  getData() {
    const url = this.proxy.baseQAUrl + 'user/' + 'me/';

    this.proxy.executeUserGet(url).subscribe((data: any) => {
      if (data?.success) {
        const user = data?.data;
        this.settingsForm.get('firstName').setValue(user?.firstName);
        this.settingsForm.get('lastName').setValue(user?.lastName);
        this.settingsForm.get('country').setValue(user?.country);
        this.settingsForm.get('email').setValue(user?.email);
        this.settingsForm
          .get('phoneNumber')
          .setValue(
            parsePhoneNumberFromString(user?.phoneNumber).nationalNumber
          );
        this.dbPhoneNumber = user?.phoneNumber;

        this.originalFormValue = JSON.stringify(this.settingsForm.value);
        this.userInfo = data?.data;
      }

      this.settingsForm.valueChanges.subscribe((form: UserInfo) => {
        const currentFormValue = JSON.stringify(form);

        this.isFormChanged = this.originalFormValue === currentFormValue;
      });
    });
  }
}
