<!-- <div class="graphDiv" >
<div class="tradingview-widget-container">
 <div id="tradingview_2e945"></div>
 <div class="tradingview-widget-copyright">
   <a href="url" rel="noopener" target="_blank">
     <span class="blue-text">{{stockType}}</span></a> by TradingView</div>
 <script type="text/javascript" src="https://s3.tradingview.com/tv.js"></script>
  <div #tradingview></div>
</div>
</div> -->

<!-- TradingView Widget BEGIN -->
<!-- <div class="tradingview-widget-container">
 <div id="tradingview_2e945"></div>
 <div class="tradingview-widget-copyright">
   <a href="https://www.tradingview.com/symbols/NASDAQ-AAPL/" rel="noopener" target="_blank">
     <span class="blue-text">AAPL Chart</span></a> by TradingView</div>
 <script type="text/javascript" src="https://s3.tradingview.com/tv.js" >  </script>
 <div [innerHtml]="html"></div>
</div>  -->

<!-- <script type="text/javascript" src="https://s3.tradingview.com/tv.js"></script>
   <script>
   function getParameterByName(name, url) {
   if (!url) {
     url = window.location.href;
   }
   name = name.replace(/[\[\]]/g, "\\$&");
   var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
       results = regex.exec(url);
   if (!results) return null;
   if (!results[2]) return '';
   return decodeURIComponent(results[2].replace(/\+/g, " "));
}
var fx = getParameterByName('value');
     var fxWidget = new TradingView.widget({
     "width": 500,
     "height": 400,
     "symbol": "FX:"+fx,
     "interval": "1",
     "timezone": "Etc/UTC",
     "theme": "White",
     "style": "2",
     "locale": "en",
     "toolbar_bg": "#f1f3f6",
     "hide_top_toolbar": true,
     "save_image": false,
     "hideideas": true
   });
   </script> -->

<!-- <div class="tradingview-widget-container">
 <div id="tradingview_bac65"></div>
 <div class="tradingview-widget-copyright">

   <a href="https://www.tradingview.com/symbols/NASDAQ-AAPL/" rel="noopener" target="_blank">
     <span class="blue-text">AAPL Chart</span></a> by TradingView</div>

 <div #tradingview></div>
</div> -->

<div
  *ngIf="showProgress == true"
  style="
    background-image: url(assets/images/Stock_Inside_BG.png);
    height: 135vh;
  "
>
  <mat-spinner
    class="mat-spinner-color"
    style="justify-content: center; align-items: center; margin: 0 auto"
    [diameter]="70"
    mode="indeterminate"
  ></mat-spinner>
</div>

<div
  class="bg"
  style="background-image: url(assets/images/Stock_Inside_BG.png)"
>
  <app-back-step></app-back-step>
  <div
    class="bg2"
    style="width: 75%; position: relative; margin: auto; padding-top: 40px"
    *ngIf="innerWidth > 480"
  >
    <!-- <img class="line" src="assets/images/Line_1.png" /> -->
    <div class="topBar">
      <div class="content">
        <div *ngIf="name?.length > 0" class="nameSection">
          <div class="stockName">{{ name }}</div>
        </div>
        <div class="stockInfo">
          <div *ngIf="symbol?.length > 0" class="symbol">
            <div class="symbolTitle">{{ symbol }}</div>
            <div class="symbolData">/Symbol</div>
          </div>
          <div class="stockSections">
            <table id="customers">
              <tr class="stockSectionsHeaders">
                <div class="headersGroup">
                  <th
                    *ngIf="security_type && security_type.length > 0"
                    class="infoSection"
                  >
                    <div class="infoTitle">Security Type</div>
                  </th>
                  <th *ngIf="price" class="infoSection">
                    <div class="infoTitle">Price</div>
                  </th>
                </div>
                <div class="headersGroup rightHeadersGroup">
                  <th
                    *ngIf="market_cap && market_cap.length > 0"
                    class="infoSection"
                  >
                    <div class="infoTitle">Market Cap</div>
                  </th>
                  <th
                    *ngIf="RET?.length > 0"
                    class="infoSection"
                    style="min-width: 50px !important; width: 70px"
                  >
                    <div class="infoTitle">RET</div>
                  </th>
                  <th *ngIf="sector && sector?.length > 0" class="infoSection">
                    <div class="infoTitle">Sector</div>
                  </th>
                </div>
              </tr>
              <tr class="stockValues">
                <div class="headersGroup">
                  <td
                    *ngIf="security_type && security_type.length > 0"
                    class="infoSection"
                  >
                    <div class="infoData">
                      {{ security_type }}
                    </div>
                  </td>
                  <td class="infoSection">
                    <div *ngIf="price" class="infoData">{{ price }}$</div>
                  </td>
                </div>
                <div class="headersGroup rightHeadersGroup">
                  <td
                    *ngIf="market_cap && market_cap.length > 0"
                    class="infoSection"
                  >
                    <div class="infoData">{{ market_cap }}</div>
                  </td>
                  <td
                    *ngIf="RET?.length > 0"
                    class="infoSection"
                    style="min-width: 50px !important; width: 70px"
                  >
                    <div *ngIf="RET.indexOf('%') >= 0" class="infoData">
                      {{ RET }}
                    </div>
                    <div *ngIf="RET.indexOf('%') === -1" class="infoData">
                      {{ RET }}%
                    </div>
                  </td>
                  <td *ngIf="sector && sector?.length > 0" class="infoSection">
                    <div class="infoData" style="min-width: 180px !important">
                      {{ sector }}
                    </div>
                  </td>
                </div>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    class="bg2"
    style="width: 75%; position: relative; margin: auto; padding-top: 25px"
    *ngIf="innerWidth < 480"
  >
    <!-- <img class="line" src="assets/images/Line_1.png" /> -->
    <div class="topBar">
      <div class="content">
        <div *ngIf="name?.length > 0" class="nameSection">
          <div class="stockName">{{ name }}</div>
        </div>
        <div class="stockInfo">
          <div class="stockSections">
            <div *ngIf="symbol?.length > 0" class="infoSection">
              <div class="infoTitle">Symbol</div>
              <div class="infoData">{{ symbol }}</div>
            </div>
            <div
              *ngIf="security_type && security_type.length > 0"
              class="infoSection"
            >
              <div class="infoTitle">Security Type</div>
              <div class="infoData">
                {{ security_type }}
              </div>
            </div>
            <div *ngIf="sector && sector?.length > 0" class="infoSection">
              <div class="infoTitle">Sector</div>
              <div class="infoData">{{ sector }}</div>
            </div>
          </div>
          <div class="stockSections">
            <div *ngIf="price" class="infoSection">
              <div class="infoTitle">Price</div>
              <div class="infoData">{{ price }}$</div>
            </div>
            <div
              *ngIf="market_cap && market_cap.length > 0"
              class="infoSection"
            >
              <div class="infoTitle">Market Cap</div>
              <div class="infoData">{{ market_cap }}</div>
            </div>
            <div *ngIf="RET?.length > 0" class="infoSection">
              <div class="infoTitle">RET</div>
              <div *ngIf="RET.indexOf('%') >= 0" class="infoData">
                {{ RET }}
              </div>
              <div *ngIf="RET.indexOf('%') === -1" class="infoData">
                {{ RET }}%
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- STARY CHAT  -->
  <div className="tradingview-widget-container" style="margin: auto">
    <div
      id="tradingview_8aaad"
      style="width: 75%; height: 500px; margin: auto"
    ></div>
    <!-- <div id="tradingview_fb8da"></div> -->
    <div
      className="tradingview-widget-copyright"
      style="width: 75%; height: 100%"
    ></div>

    <!-- <img
      style="margin-top: -38px"
      class="line"
      src="assets/images/Line_1.png"
    /> -->
    <!-- <div *ngIf="innerWidth<=480" class="parameters cdk-overlay-connected-position-bounding-box">Graph</div>


<div *ngIf="innerWidth<=480" class="border1">

<canvas *ngIf="innerWidth>480" id="myChart" wigraphList.deciledth="400" height="65" style="height: 164px !important;"></canvas>
<canvas *ngIf="innerWidth<=480" id="myChart" width="400"  style="height: 224px !important;"></canvas>
</div>  -->
  </div>

  <div id="description-container">
    <div class="leftGreenLine"></div>
    <div class="textInDescription">
      <h3 id="description-header">Description</h3>
      <p id="description-content"></p>
    </div>
  </div>

  <div class="d-flex periods" *ngIf="innerWidth < 480">
    <div *ngFor="let date of dateList; let i = index" style="font-size: 20px">
      <span
        (click)="isFilterChoosingShown ? handleStockPeriod(date.date) : null"
        [style.cursor]="isFilterChoosingShown ? 'pointer' : 'default'"
        style="padding-left: 10px"
        class="nav-link"
        [ngClass]="{
          dateSelect: this.period == date.date,
          date: this.period != date.date,
          disabled: disabled
        }"
        >{{ date.date }}</span
      >
    </div>
  </div>

  <div class="mediumDiv"></div>

  <!-- <div *ngIf="innerWidth>480" style="margin-top:250px;" class="decile cdk-overlay-connected-position-bounding-box">Decile</div> -->
  <div *ngIf="innerWidth > 480" class="parameterBorder">
    <div style="height: 70px">
      <mat-spinner
        *ngIf="graphListAll.length <= 4"
        class="mat-spinner-color"
        style="
          justify-content: center;
          align-items: center;
          margin: auto;
          top: 460px;
        "
        [diameter]="70"
        mode="indeterminate"
      ></mat-spinner>
    </div>
    <div class="decilesPart">
      <div class="titleAndFilters">
        <div class="decileTitleContainer">
          <span class="decileTitle">Decile</span>
        </div>

        <div class="d-flex periods">
          <div *ngFor="let date of dateList; let i = index">
            <span
              (click)="
                isFilterChoosingShown ? handleStockPeriod(date.date) : null
              "
              [style.cursor]="isFilterChoosingShown ? 'pointer' : 'default'"
              style="padding-left: 10px"
              class="nav-link"
              [ngClass]="{
                dateSelect: this.period == date.date,
                date: this.period != date.date,
                disabled: disabled
              }"
              >{{ date.date }}</span
            >
          </div>
        </div>
      </div>
      <div class="tableAndDeciles">
        <div class="tableOfDeciles">
          <span>10</span>
          <span>8</span>
          <span>6</span>
          <span>4</span>
          <span>2</span>
          <span>0</span>
        </div>
        <div class="allDeciles" *ngIf="graphListAll.length > 4">
          <div class="capital">
            <div class="leftSideOfHeader">
              <div class="graphAndInfo">
                <div class="headerGrap">
                  {{ stockType ? stockType : "Capital Preservation" }}
                </div>
                <div class="white">MaxDD {{ max_dd || "0.0" }}</div>
                <div class="white">STD {{ std || "0.0" }}</div>
                <div
                  *ngIf="heights?.capitalStockGraph > 0"
                  class="grap"
                  style="background-image: url(assets/images/Graph_5.png)"
                  [ngStyle]="
                    num == 2 && { height: heights?.capitalStockGraph + 'px' }
                  "
                ></div>
              </div>
            </div>
            <div class="leftSideOfHeader">
              <div class="graphAndInfo">
                <div style="text-align: right">
                  <div class="headerSpy">SPY</div>
                  <div class="white">MaxDD {{ spy_max_dd || "0.0" }}</div>
                  <div class="white">STD {{ spy_std || "0.0" }}</div>
                </div>
                <div *ngIf="heights?.capitalSPYGraph > 0">
                  <div
                    class="grapBlue"
                    style="
                      background-image: url(assets/images/Stock_Graph_S&P.png);
                    "
                    [ngStyle]="{ height: heights?.capitalSPYGraph + 'px' }"
                  ></div>
                </div>
              </div>
            </div>
          </div>
          <div class="capital">
            <div class="leftSideOfHeader">
              <div class="graphAndInfo">
                <div class="headerGrap">
                  {{ stockType ? stockType : "Momentum" }}
                </div>
                <div class="white">
                  CCI {{ (cci | number : "1.2-2") || "0.0" }}
                </div>
                <div class="white">
                  ROC {{ (roc | number : "1.2-2") || "0.0" }}
                </div>
                <div
                  *ngIf="heights?.momentumStockGraph > 0"
                  class="grap"
                  style="
                    background-image: url(assets/images/Graph_5.png);
                    left: 21%;
                  "
                  [ngStyle]="
                    num == 2 && { height: heights?.momentumStockGraph + 'px' }
                  "
                ></div>
              </div>
            </div>
            <div class="leftSideOfHeader">
              <div class="graphAndInfo">
                <div style="text-align: right">
                  <div class="headerSpy">SPY</div>
                  <div class="white">
                    CCI {{ (spy_cci | number : "1.2-2") || "0.0" }}
                  </div>
                  <div class="white">
                    ROC {{ (spy_roc | number : "1.2-2") || "0.0" }}
                  </div>
                </div>

                <div *ngIf="heights?.momentumSPYGraph > 0">
                  <div
                    class="grapBlue"
                    style="
                      background-image: url(assets/images/Stock_Graph_S&P.png);
                    "
                    [ngStyle]="
                      margintop2
                        ? { height: heights?.momentumSPYGraph + 'px' }
                        : { display: 'none' }
                    "
                  ></div>
                </div>
              </div>
            </div>
          </div>
          <div class="capital">
            <div class="leftSideOfHeader">
              <div class="graphAndInfo">
                <!-- <div class="headerGrap1" style="left: 42%;"></div>  -->
                <div class="headerGrap">
                  {{ stockType ? stockType : "Buy the Dip" }}
                </div>
                <div class="white">
                  MaxDD
                  {{ graphList2?.max_dd ? graphList2?.max_dd : "0.0" }}
                </div>
                <div class="white">
                  Recovery
                  {{ graphList2?.recovery ? graphList2?.recovery : "0.0" }}
                </div>
                <div *ngIf="heights?.bddStockGraph > 0">
                  <div
                    class="grap"
                    style="
                      background-image: url(assets/images/Graph_5.png);
                      left: 41%;
                    "
                    [ngStyle]="{ height: heights?.bddStockGraph + 'px' }"
                  ></div>
                </div>
              </div>
            </div>

            <div class="leftSideOfHeader">
              <div class="graphAndInfo">
                <div style="text-align: right">
                  <div class="headerSpy">SPY</div>
                  <div class="white">
                    MaxDD
                    {{
                      graphList2?.spy_max_dd ? graphList2?.spy_max_dd : "0.0"
                    }}
                  </div>
                  <div class="white">
                    Recovery
                    {{
                      graphList2?.spy_recovery
                        ? graphList2?.spy_recovery
                        : "0.0"
                    }}
                  </div>
                </div>

                <div
                  *ngIf="heights?.bddSPYGraph > 0"
                  class="grapBlue"
                  style="
                    background-image: url(assets/images/Stock_Graph_S&P.png);
                    left: 48%;
                  "
                  [ngStyle]="{ height: heights?.bddSPYGraph + 'px' }"
                ></div>
              </div>
            </div>
          </div>
          <div class="capital">
            <div class="leftSideOfHeader">
              <div class="graphAndInfo">
                <div class="headerGrap1"></div>
                <div class="headerGrap">
                  {{ stockType ? stockType : "Social Sentiment" }}
                </div>
                <div
                  class="white"
                  style="
                    overflow-wrap: break-word;
                    word-wrap: break-word;
                    word-break: break-word;
                  "
                >
                  Volume Change
                  {{ getSocialSentimentGraphSpyVolumeChange(false) }}
                </div>
                <div
                  class="white"
                  style="
                    overflow-wrap: break-word;
                    word-wrap: break-word;
                    word-break: break-word;
                  "
                >
                  Sentiment Rank
                  {{
                    graphList3?.sentiment_rank
                      ? (graphList3?.sentiment_rank | number : "1.2-2")
                      : "0.0"
                  }}
                </div>
                <div
                  *ngIf="heights?.socialStockGraph > 0"
                  class="grap"
                  style="background-image: url(assets/images/Graph_5.png)"
                  [ngStyle]="
                    num == 2 && { height: heights?.socialStockGraph + 'px' }
                  "
                ></div>
              </div>
            </div>

            <div class="leftSideOfHeader">
              <div class="graphAndInfo">
                <div style="text-align: right">
                  <div class="headerSpy">SPY</div>
                  <div
                    class="white"
                    style="
                      overflow-wrap: break-word;
                      word-wrap: break-word;
                      word-break: break-word;
                    "
                  >
                    Volume Change
                    {{ getSocialSentimentGraphSpyVolumeChange(true) }}
                  </div>
                  <div
                    class="white"
                    style="
                      overflow-wrap: break-word;
                      word-wrap: break-word;
                      word-break: break-word;
                    "
                  >
                    Sentiment Rank
                    {{
                      graphList3?.spy_sentiment_rank
                        ? (graphList3?.spy_sentiment_rank | number : "1.2-2")
                        : "0.0"
                    }}
                  </div>
                </div>

                <div
                  *ngIf="heights?.socialSPYGraph > 0"
                  class="grapBlue"
                  style="
                    background-image: url(assets/images/Stock_Graph_S&P.png);
                  "
                  [ngStyle]="
                    num == 2 && { height: heights?.socialSPYGraph + 'px' }
                  "
                ></div>
              </div>
            </div>

            <!-- <div class="type2" style="left: 42%;">Dip</div> -->
            <!-- </div> -->
          </div>
          <div class="capital">
            <div
              style="
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
              "
            >
              <div style="text-align: left" class="valueHeader">
                <!-- <div class="headerGrap1" style="left: 42%;"></div>  -->
                <div class="headerGrap">
                  {{ stockType ? stockType : "Value Investing" }}
                </div>
                <div class="whiteValue">
                  Rev_growth_qtr {{ Rev_growth_qtr }}
                </div>
                <div class="whiteValue">Debt/Equity {{ Debt_equality }}</div>
                <div class="whiteValue">Net_Margin {{ Net_Margin }}</div>
                <div class="whiteValue">P/E {{ PE }}</div>
                <div class="whiteValue">
                  Analyst Rating {{ Analyst_Rating }}
                </div>
              </div>
              <div class="valueDecile">
                <div
                  *ngIf="heights?.valueStockGraph > 0"
                  class="grap"
                  style="background-image: url(assets/images/Graph_5.png)"
                  [ngStyle]="
                    num == 2 && { height: heights?.valueStockGraph + 'px' }
                  "
                ></div>
              </div>
            </div>
            <!-- <div
              *ngIf="heights?.valueSPYGraph > 0"
              class="grapBlue"
              style="
                background-image: url(assets/images/Stock_Graph_S&P.png);
                left: 88%;
              "
              [ngStyle]="num == 2 && { height: heights?.valueSPYGraph + 'px' }"
            >
              <div class="number">{{ spyValue4 ? spyValue4 : 0 }}</div>
            </div> -->

            <!-- <div class="type2" style="left: 42%;">Dip</div> -->
          </div>

          <!-- <div [ngStyle]="num==2 && {'margin-top':margintop4+ 'px'}" style="margin-left: 2%;">
   <div class="white" style="left: 63%;">MAX_DD {{max_dd3}}</div>
   <div class="white2" style="left: 63%;">STD {{std3}}</div>
   <div class="grap" style="background-image:  url(assets/images/Graph_2.png); left: 63%;" [ngStyle]="num==2 && {'height':heights?.socialStockGraph+ 'px'}">
       <div class="number">{{value3}}</div>
   </div>
   <div class="grap" style="background-image: url(assets/images/Stock_Graph_S&P.png); left: 63%;" [ngStyle]="num==2 && {'height':heights?.socialSPYGraph+ 'px'}"></div>

   <div class="type" style="left: 63%;">Social</div>
   <div class="type2" style="left: 63%;">Sentiment</div>
</div>  -->

          <!-- <div [ngStyle]="num==2 && {'margin-top':margintop5+ 'px'}" style="margin-left: 0%;">
   <div class="white" style="left: 84%; margin-top: 0px;">MAX_DD {{max_dd4}}</div>
   <div class="white2" style="right: -1%; margin-top: 20px;">STD {{std4}}</div>

   <div class="grap" style="background-image:  url(assets/images/Graph_1.png); left: 84%;" [ngStyle]="num==2 && {'height':heights?.valueStockGraph+ 'px'}">
     <div class="number">{{value4}}</div>
 </div>
 <div class="grap" style="background-image: url(assets/images/Stock_Graph_S&P.png); left: 84%;" [ngStyle]="num==2 && {'height':heights?.valueSPYGraph+ 'px'}"></div>
   <div class="type" style="left: 84%;">Value </div>
   <div class="type2" style="left: 84%;">Investing</div>
</div> -->
          <!-- <div *ngIf="showProgress==true" class="loader"></div> -->
          <!-- <mat-spinner class="mat-spinner-color" style="justify-content: center;
align-items: center; margin:0 auto;" [diameter]="70" mode="indeterminate"></mat-spinner>  -->
          <!-- <div class="vertical type"  style="position: absolute; left: 1%; right: 3%; text-align: center;">{{header1}}</div> -->
        </div>
        <div class="tableOfDeciles" style="text-align: right">
          <span>10</span>
          <span>8</span>
          <span>6</span>
          <span>4</span>
          <span>2</span>
          <span>0</span>
        </div>
      </div>
    </div>
    <span style="position: relative; left: 13%" class="type">Capital</span>
    <span style="position: relative; left: 26%" class="type">Momentum</span>
    <span style="position: relative; left: 39%" class="type">Buy the Dip</span>
    <span style="position: relative; left: 51%" class="type"
      >Social Sentiment</span
    >
    <span style="position: relative; left: 61%" class="type"
      >Value Investing</span
    >
  </div>
  <div
    *ngIf="innerWidth <= 480"
    class="parameterBorder"
    style="background-image: url(assets/images/Stock_Graph_BG.png)"
  >
    <div style="background-color: #1f1f1f">
      <mat-spinner
        *ngIf="graphListAll.length <= 4"
        class="mat-spinner-color"
        style="
          justify-content: center;
          align-items: center;
          margin: auto;
          top: 116px;
        "
        [diameter]="70"
        mode="indeterminate"
      ></mat-spinner>
    </div>
    <div
      class="d-flex"
      style="left: 1%; right: 2%; text-align: center; background-color: #1f1f1f"
      *ngIf="graphListAll.length > 4"
    >
      <div class="decile2">Decile</div>

      <div [ngStyle]="num == 2 && { 'margin-top': margintop1 + 'px' }">
        <!-- <div class="white">MAX_DD {{graphList[0].max_dd}}</div>
       <div class="white2">STD {{graphList[0].std}}</div> -->
        <div *ngIf="heights?.capitalSPYGraph > 0">
          <div
            class="grap"
            style="
              background-image: url(assets/images/Graph_Page_S&P.png);
              left: 13%;
            "
            [ngStyle]="num == 2 && { height: heights?.capitalSPYGraph + 'px' }"
          >
            <div class="number">{{ spyValue }}</div>
          </div>
        </div>
        <div
          class="grap"
          style="background-image: url(assets/images/Graph_5.png); left: 7%"
          [ngStyle]="num == 2 && { height: heights?.capitalStockGraph + 'px' }"
        >
          <div class="number">{{ value }}</div>
        </div>
        <!-- <div class="vertical type" style="left: 1%; height: 115px;">{{graphList[0].header}}</div> -->
        <div class="type vertical" style="left: 1%; height: 140px">
          {{
            graphListAll[0]?.header
              ? graphListAll[0]?.header
              : "Capital Preservation"
          }}
        </div>
        <!-- <img src="assets/images/Graph_5.png" class="graph5 align-self-end" > -->
      </div>
      <div
        [ngStyle]="num == 2 && { 'margin-top': margintop2 + 'px' }"
        style="margin-left: 0%"
      >
        <!-- <div class="white" style="left: 21%;">CCI {{graphList1[0].cci}}</div>
        <div class="white2" style="left: 21%;">ROC {{graphList1[0].roc}}</div> -->
        <div *ngIf="heights?.momentumSPYGraph > 0">
          <div
            class="grap"
            style="
              background-image: url(assets/images/Graph_Page_S&P.png);
              left: 33%;
            "
            [ngStyle]="num == 2 && { height: heights?.momentumSPYGraph + 'px' }"
          >
            <div class="number">{{ spyValue1 }}</div>
          </div>
        </div>
        <div
          *ngIf="heights?.momentumStockGraph > 0"
          class="grap"
          style="background-image: url(assets/images/Graph_5.png); left: 27%"
          [ngStyle]="num == 2 && { height: heights?.momentumStockGraph + 'px' }"
        >
          <div class="number">{{ value1 }}</div>
        </div>
        <div class="vertical type" style="left: 21%">
          {{ graphListAll[1]?.header ? graphListAll[1]?.header : "Momentum" }}
        </div>
      </div>
      <div>
        <div
          [ngStyle]="num == 2 && { 'margin-top': margintop3 + 'px' }"
          style="margin-left: 1%"
        >
          <!-- <div class="white" style="left: 42%;">Mom {{graphList2.ma20}}</div>
          <div class="white2" style="left: 42%;">NSI {{graphList2.ma50}}</div>  -->
          <div
            *ngIf="heights?.bddStockGraph > 0"
            class="grap"
            style="background-image: url(assets/images/Graph_5.png); left: 47%"
            [ngStyle]="{ height: heights?.bddStockGraph + 'px' }"
          >
            <div class="number">{{ value2 }}</div>
          </div>
          <div
            *ngIf="heights?.bddSPYGraph > 0"
            class="grap"
            style="
              background-image: url(assets/images/Graph_Page_S&P.png);
              left: 53%;
            "
            [ngStyle]="{ height: heights?.bddSPYGraph + 'px' }"
          >
            <div class="number">{{ spyValue2 }}</div>
          </div>
          <div class="vertical type" style="left: 41%; height: 100px">
            {{
              graphListAll[2]?.header ? graphListAll[2]?.header : "Buy the Dip"
            }}
          </div>
        </div>
      </div>
      <div
        [ngStyle]="num == 2 && { 'margin-top': margintop4 + 'px' }"
        style="margin-left: 2%"
      >
        <div
          *ngIf="heights?.socialStockGraph > 0"
          class="grap"
          style="background-image: url(assets/images/Graph_5.png); left: 67%"
          [ngStyle]="num == 2 && { height: heights?.socialStockGraph + 'px' }"
        >
          <div class="number">{{ value3 ? value3 : 0 }}</div>
        </div>
        <div
          *ngIf="heights?.socialSPYGraph > 0"
          class="grap"
          style="
            background-image: url(assets/images/Graph_Page_S&P.png);
            left: 73%;
          "
          [ngStyle]="num == 2 && { height: heights?.socialSPYGraph + 'px' }"
        >
          <div class="number">{{ spyValue3 ? spyValue3 : 0 }}</div>
        </div>
        <div class="vertical type" style="left: 61%">
          {{
            graphListAll[3]?.header
              ? graphListAll[3]?.header
              : "Social Sentiment"
          }}
        </div>
        <div
          [ngStyle]="num == 2 && { 'margin-top': margintop5 + 'px' }"
          style="margin-left: 0%"
        >
          <!-- <div
            *ngIf="heights?.valueSPYGraph > 0"
            class="grap"
            style="
              background-image: url(assets/images/Graph_Page_S&P.png);
              left: 93%;
            "
            [ngStyle]="{ height: heights?.valueSPYGraph + 'px' }"
          >
            <div class="number">{{ spyValue4 ? spyValue4 : 0 }}</div>
          </div> -->
          <div
            *ngIf="heights?.valueStockGraph > 0"
            class="grap"
            style="background-image: url(assets/images/Graph_5.png); left: 88%"
            [ngStyle]="num == 2 && { height: heights?.valueStockGraph + 'px' }"
          >
            <div class="number">{{ value4 ? value4 : 0 }}</div>
          </div>
          <div class="vertical type" style="left: 82%">
            {{
              graphListAll[4]?.header
                ? graphListAll[4]?.header
                : "Value Investing"
            }}
          </div>
        </div>
      </div>
    </div>
    <!-- <img class="graphLine_" src="assets/images/Graph_Page_List_Line.png" />
    <div class="loader" *ngIf="showProgress == true"></div>
    <mat-spinner
      class="mat-spinner-color"
      style="justify-content: center; align-items: center; margin: 0 auto"
      [diameter]="70"
      mode="indeterminate"
    ></mat-spinner> -->
  </div>

  <div
    *ngIf="innerWidth <= 480"
    style="
      background-color: #1f1f1f;
      height: 1040px;
      margin-top: 20px;
      padding-top: 10px;
    "
  >
    <div
      *ngFor="let graph of graphListAll; let i = index"
      style="width: 90%; position: relative; margin: auto"
    >
      <div
        [ngStyle]="{ height: i == 4 ? '250px' : i == 3 ? '180px' : '150px' }"
        style="width: 227px; margin-top: 10px"
        *ngIf="graph != null"
      >
        <span class="stockHeader">{{
          graph.header == undefined ? defaultHeaders[i] : graph.header
        }}</span>
        <div class="d-flex" style="padding-top: 10px; font-size: 20px">
          <div
            class="circel"
            style="
              text-align: center;
              background-image: url(assets/images/Graph_Page_Green_Point.png);
              background-repeat: no-repeat;
            "
          >
            <span style="font-size: 14px; color: white">
              {{ i === 4 ? value4 || 0 : graphListAll[i]?.decile }}</span
            >
          </div>
          <span style="margin-left: 15px">{{ stockType }}</span>
          <span *ngIf="i != 4" class="sp">SPY</span>
        </div>
        <div class="d-flex" *ngIf="i == 0">
          <div class="white">MaxDD {{ graphListAll[i]?.max_dd }}</div>
          <span class="textRight">MaxDD {{ graphListAll[i]?.spy_max_dd }}</span>
        </div>
        <div class="d-flex" *ngIf="i == 0">
          <div class="white2">STD {{ graphListAll[i]?.std || "0.0" }}</div>
          <span class="textRight2"
            >STD {{ graphListAll[i]?.spy_std || "0.0" }}</span
          >
        </div>

        <div class="d-flex" *ngIf="i == 1">
          <div class="white">
            CCI {{ (graphListAll[i]?.cci | number : "1.2-2") || "0.0" }}
          </div>
          <span class="textRight"
            >CCI
            {{ (graphListAll[i]?.spy_cci | number : "1.2-2") || "0.0" }}</span
          >
        </div>
        <div class="d-flex" *ngIf="i == 1">
          <div class="white2">
            ROC {{ (graphListAll[i]?.roc | number : "1.2-2") || "0.0" }}
          </div>
          <span class="textRight2"
            >ROC
            {{ (graphListAll[i]?.spy_roc | number : "1.2-2") || "0.0" }}</span
          >
        </div>

        <div class="d-flex" *ngIf="i == 2">
          <div class="white">
            MaxDD
            {{ graphListAll[i]?.max_dd ? graphListAll[i]?.max_dd : "0.0" }}
          </div>
          <span class="textRight"
            >MaxDD
            {{
              graphListAll[i]?.spy_max_dd ? graphListAll[i]?.spy_max_dd : "0.0"
            }}</span
          >
        </div>
        <div class="d-flex" *ngIf="i == 2">
          <div class="white2">
            Recovery
            {{ graphListAll[i]?.recovery ? graphListAll[i]?.recovery : "0.0" }}
          </div>
          <span class="textRight2"
            >Recovery
            {{
              graphListAll[i]?.spy_recovery
                ? graphListAll[i]?.spy_recovery
                : "0.0"
            }}</span
          >
        </div>

        <div class="d-flex" *ngIf="i == 3">
          <div
            class="white"
            style="
              width: 112px;
              overflow-wrap: break-word;
              word-wrap: break-word;
              word-break: break-word;
              margin-top: 10px;
            "
          >
            Volume Change
            {{ getSocialSentimentGraphSpyVolumeChange(false) }}
          </div>
          <span
            class="textRight"
            style="
              width: 112px;
              overflow-wrap: break-word;
              word-wrap: break-word;
              word-break: break-word;
              margin-top: 10px;
              text-align: right;
            "
            >Volume Change
            {{ getSocialSentimentGraphSpyVolumeChange(true) }}</span
          >
        </div>
        <div class="d-flex" *ngIf="i == 3">
          <div
            class="white2"
            style="
              width: 111px;
              overflow-wrap: break-word;
              word-wrap: break-word;
              word-break: break-word;
              margin-top: 62px;
            "
          >
            Sentiment Rank
            {{
              graphList3?.sentiment_rank
                ? (graphList3?.sentiment_rank | number : "1.2-2")
                : "0.0"
            }}
          </div>
          <span
            class="textRight2"
            style="
              width: 111px;
              overflow-wrap: break-word;
              word-wrap: break-word;
              word-break: break-word;
              margin-top: 62px;
              text-align: right;
            "
            >Sentiment Rank
            {{
              graphList3?.sentiment_rank
                ? (graphList3?.spy_sentiment_rank | number : "1.2-2")
                : "0.0"
            }}</span
          >
        </div>
        <div *ngIf="i == 4">
          <div>
            <div class="whitetext">
              Rev_growth_qtr <br />
              {{ Rev_growth_qtr }}
            </div>
            <div class="whitetext">
              Debt/Equity <br />
              {{ Debt_equality }}
            </div>
            <div class="whitetext">
              Net_Margin <br />
              {{ Net_Margin }}
            </div>
            <div class="whitetext">
              P/E <br />
              {{ PE }}
            </div>
            <div class="whitetext">
              Analyst Rating <br />
              {{ Analyst_Rating }}
            </div>
          </div>
        </div>
        <img
          class="graphLine"
          *ngIf="i != 4"
          src="assets/images/Graph_Page_List_Line.png"
          [ngStyle]="
            i == 3 ? { 'margin-top': '120px' } : { 'margin-top': '80px' }
          "
        />
      </div>
    </div>
  </div>
</div>
