import { Injectable } from '@angular/core';
import { ServerResponse } from 'http';
import { Observable } from 'rxjs';
import {
  Fund,
  graph,
  parameter,
  parameters,
  search,
  StockType,
  Top,
} from '../models/stockType';
import { ContectUser } from '../models/user';
import { AppProxy, HttpMethod } from './app-proxy';

@Injectable()
export class StocksService {
  stockTypeList: StockType[] = [];
  graph: graph;
  parametersList: parameters[] = [];

  constructor(private proxy: AppProxy) {}

  getStockType() {
    let url = this.proxy.baseQAUrl + 'stocks/' + 'stockType';
    return new Observable((observer) => {
      this.proxy.execute<ServerResponse>(url, HttpMethod.Get).subscribe(
        (result) => {
          let res: any = result;
          if (res.success == true) {
            let resList: StockType[] = res.data;
            this.stockTypeList = resList;
            observer.next(this.stockTypeList);
          }
        },
        (error) => console.log('oops', error)
      );
    });
  }

  getStockList(i: string, search: search, day: string = '') {
    let url = this.proxy.baseQAUrl + 'stocks/' + 'stockList/' + i;

    if (day != '') {
      url += `?day=${day}`;
    }

    return new Observable((observer) => {
      this.proxy
        .executePost<ServerResponse>(url, search, HttpMethod.Post)
        .subscribe(
          (result) => {
            let res: any = result;
            if (res.success == true) {
              let resList: Top = res.data;
              observer.next(resList);
            }
          },
          (error) => console.log('oops', error)
        );
    });
  }

  getDataFromYahoo(symbol: string): Observable<{
    [market_cap: string]: string;
    description: string;
    sector: string;
  }> {
    let url = this.proxy.baseQAUrl + 'stocks/yahooData/' + symbol;

    return new Observable((observer) => {
      fetch(url, { method: 'GET' })
        .then((response) => response.json())
        .then((result) => {
          let res: any = result;
          if (res.success == true) {
            let transformedData = {
              description: res.description,
              sector: res.sector,
              market_cap: res.market_cap,
            };
            observer.next(transformedData);
          } else {
            console.log('there is an error');
          }
        })
        .catch((error) => {
          console.log('oops', error);
        });
    });
  }

  contact(contectUser: ContectUser) {
    let url = this.proxy.baseQAUrl + 'contact';
    return new Observable((observer) => {
      this.proxy
        .executePost<ServerResponse>(url, contectUser, HttpMethod.Post)
        .subscribe(
          (result) => {
            let res: any = result;
            if (res.success == true) {
              let resList: Top = res.data;
              observer.next(resList);
            }
          },
          (error) => console.log('oops', error)
        );
    });
  }

  fund() {
    let url = this.proxy.baseQAUrl + 'fund';
    return new Observable((observer) => {
      this.proxy.execute<ServerResponse>(url, HttpMethod.Get).subscribe(
        (result) => {
          let res: any = result;
          if (res.success == true) {
            let resObg: Fund = res.data;
            observer.next(resObg);
          }
        },
        (error) => console.log('oops', error)
      );
    });
  }

  findStock(symbol: any) {
    let url = this.proxy.baseQAUrl + 'stocks/search-stock-inner/' + symbol;
    return new Observable((observer) => {
      this.proxy.execute<ServerResponse>(url, HttpMethod.Post).subscribe(
        (result) => {
          let res: any = result;
          if (res.success == true) {
            let resObg: Fund = res.data;
            observer.next(resObg);
          }
        },
        (error) => console.log('oops', error)
      );
    });
  }

  getGraph(parameter: parameter) {
    let url = this.proxy.baseQAUrl + 'graph';
    return new Observable((observer) => {
      this.proxy
        .executePost<ServerResponse>(url, parameter, HttpMethod.Post)
        .subscribe(
          (result) => {
            let res: any = result;
            if (res.success == true) {
              if (res.data != 'This symbol data doesnt exists') {
                let resList: graph = res.data;
                this.graph = resList;
                observer.next(this.graph);
              } else observer.next(null);
            }
          },
          (error) => console.log('oops', error)
        );
    });
  }

  getParameters(symbol: any) {
    let url = this.proxy.baseQAUrl + 'findParameters';
    return new Observable((observer) => {
      this.proxy
        .executePost<ServerResponse>(url, { symbol }, HttpMethod.Post)
        .subscribe(
          (result) => {
            let res: any = result;
            if (res.success == true) {
              if (res.data != 'This symbol data doesnt exists') {
                let resList = res.data;

                observer.next(resList);
              } else observer.next(null);
            }
          },
          (error) => console.log('oops', error)
        );
    });
  }

  parameters(parameter: parameter) {
    let url = this.proxy.baseQAUrl + 'parametersLightWeight';
    return new Observable((observer) => {
      this.proxy
        .executePost<ServerResponse>(url, parameter, HttpMethod.Post)
        .subscribe(
          (result) => {
            let res: any = result;
            if (res.success == true) {
              let resList: parameters[] = res.data;
              this.parametersList = resList;
              observer.next(this.parametersList);
            }
          },
          (error) => console.log('oops', error)
        );
    });
  }
}
