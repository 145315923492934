import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { HttpClientModule } from '@angular/common/http';
import { NguCarouselModule } from '@ngu/carousel';
import { MatCarouselModule } from '@ngmodule/material-carousel';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDialogModule } from '@angular/material/dialog';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { IgxCarouselModule, IgxListModule } from 'igniteui-angular';
import { AppProxy } from './services/app-proxy';
import { NgSelectModule } from '@ng-select/ng-select';
import { ToastrModule } from 'ngx-toastr';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { CdkScrollableModule } from '@angular/cdk/scrolling';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { GalleryModule } from 'ng-gallery';
import { LightboxModule } from 'ng-gallery/lightbox';
import { DataService } from './services/data.service';
import { VirtualScrollerModule } from 'ngx-virtual-scroller';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { MatToolbarModule } from '@angular/material/toolbar';
import { FitText } from './fit-text';
import { AgmCoreModule } from '@agm/core';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { LoaderComponent } from './loader/loader.component';
import { SplashComponent } from './components/splash/splash.component';
import { MainHomeComponent } from './components/main-home/main-home.component';
import { HeaderComponent } from './components/header/header.component';
import { ValueInvestingComponent } from './components/value-investing/value-investing.component';
import { ChooseTypeComponent } from './components/choose-type/choose-type.component';
import { StocksService } from './services/stocks-service';
import { PopupTypeComponent } from './components/popup-type/popup-type.component';
import { TopComponent } from './components/top/top.component';
import { ContactComponent } from './components/contact/contact.component';
import { InnerComponent } from './components/inner/inner.component';
import { GroupTypeComponent } from './components/group-type/group-type.component';
import { Contact2Component } from './components/contact2/contact2.component';
import { WhoMiComponent } from './components/who-mi/who-mi.component';
import { LoginComponent } from './components/login/login.component';
import { SignupComponent } from './components/signup/signup.component';
import { SettingsComponent } from './components/user-settings/settings.component';
import { Ng2TelInputModule } from 'ng2-tel-input';
import { ForgotPasswordComponent } from './components/forgot-password/forgotPassword.component';
import { TermsAndConditionsComponent } from './components/termsAndConditions/termsAndConditions.component';
import { TableComponent } from './components/admin/admin.component';
import { MatTableModule } from '@angular/material/table';
import { UserService } from './services/users.service';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatIconModule } from '@angular/material/icon';
import { InputComponent } from './input/input.component';
import { BackStepComponent } from './components/back-step/back-step.component';
import { StylePaginatorDirective } from './components/admin/paginator';
import { PendingStatusComponent } from './components/pending-status/pending-status.component';
import { RejectedStatusComponent } from './components/rejected-status/rejected-status.component';
import { chooseButtonComponent } from './components/chooseButton/chooseButton.component';

@NgModule({
  declarations: [
    AppComponent,
    FitText,
    SplashComponent,
    LoaderComponent,
    MainHomeComponent,
    HeaderComponent,
    ValueInvestingComponent,
    ChooseTypeComponent,
    PopupTypeComponent,
    TopComponent,
    ContactComponent,
    InnerComponent,
    GroupTypeComponent,
    Contact2Component,
    WhoMiComponent,
    LoginComponent,
    SignupComponent,
    SettingsComponent,
    ForgotPasswordComponent,
    TermsAndConditionsComponent,
    TableComponent,
    InputComponent,
    BackStepComponent,
    StylePaginatorDirective,
    PendingStatusComponent,
    RejectedStatusComponent,
    chooseButtonComponent,
  ],
  imports: [
    Ng2TelInputModule,
    CdkScrollableModule,
    ScrollingModule,
    NgSelectModule,
    BrowserModule,
    CommonModule,
    HttpClientModule,
    GalleryModule,
    LightboxModule,
    FormsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    ReactiveFormsModule,
    MatToolbarModule,
    MatInputModule,
    MatFormFieldModule,
    MatDialogModule,
    AppRoutingModule,
    SlickCarouselModule,
    IgxCarouselModule,
    MatCarouselModule.forRoot(),
    NguCarouselModule,
    IgxListModule,
    ToastrModule.forRoot(),
    MatProgressSpinnerModule,
    BrowserAnimationsModule,
    InfiniteScrollModule,
    NgbModule,
    MatIconModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatSelectModule,
    MatFormFieldModule,
    NgxMatSelectSearchModule,
    VirtualScrollerModule,
    GooglePlaceModule,
    MatTableModule,
    MatPaginatorModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyC1lIMPz3cuZmZqhylKPGgbQzVq4_nnEzY',
      libraries: ['places'],
      language: 'iw',
    }),
    NgCircleProgressModule.forRoot({}),
  ],

  entryComponents: [LoaderComponent, PopupTypeComponent, ContactComponent],
  providers: [DataService, AppProxy, StocksService, UserService],
  bootstrap: [AppComponent],
})
export class AppModule {}
