<div
  class="container"
  [ngClass]="{ 'bg-web': innerWidth > 480, 'bg-mobile': innerWidth <= 480 }"
>
  <div class="termsAndConditions">
    <h1>Terms And Conditions</h1>
    <p class="text">
      The system presented on this website was developed by WhoMi Avatar Ltd.
      (the "Company" and the "System," respectively). The System gathers public
      financial information about securities and processes it according to
      built-in algorithms. The financial information about the securities, which
      System's outputs are based on, is publicly available information provided
      to the Company by external parties, or calculated by the Company, from
      various sources.<br /><br />
      The Company has no responsibility for the correctness and/or accuracy of
      this information and relying on it does not constitute verification or
      confirmation of the information’s correctness, subject to the provisions
      of any law. It should be emphasized that the financial information the
      System relies on is information that relates to past data of securities.
      Therefore, the system's outputs (which rely on the previously mentioned
      information) do not predict, or pretend to predict, any future data of any
      security. Any conclusion about the future behavior of a security will be
      in accordance with the user's sole interpretation . It is clarified that
      the Company does not have an investment advising license and is not
      regulated. The System’s activity should not be considered as providing
      investment advice and it does not take into account the needs of any
      person.<br /><br />
      Any decision to invest in a security will be in accordance with the user’s
      sole discretion after the user examined and assessed the chances and risks
      inherent in the investment. It is clarified that the System is in its
      trial period and its use by you is made as part of the System’s
      development process. Changes may apply to the System and its algorithm for
      the purpose of optimizing the System and its products. Therefore, the
      System’s products must be taken with a limited guarantee during this trial
      period. Without detracting from the aforementioned, the Company will not
      be responsible for any reliance on the System's products, and any
      investment decision will be your responsibility after you have examined
      any and all relevant information about the securities yourself.
    </p>
    <div class="buttons">
      <p (click)="onAccept()" class="links">Accept</p>
      <p (click)="onLogin()" class="links">Log in</p>
    </div>
  </div>
</div>
