import {
  Component,
  Output,
  EventEmitter,
  Inject,
  OnChanges,
  SimpleChanges,
  OnInit,
  Input,
} from '@angular/core';
import { DataService } from './services/data.service';
import { Title } from '@angular/platform-browser';
import {
  ActivatedRoute,
  NavigationEnd,
  Router,
  RouterState,
} from '@angular/router';
import { DOCUMENT } from '@angular/common';
import {
  AlgoNameRouting,
  AlgorithmsDescriptions,
  StockTypeEnum,
  timeFramesArrays,
} from './models/stockType';
import { topDataService } from './services/topData.service';
import { innerDataService } from './services/innerData.service';
import { AuthGuard } from './services/guards/auth.guard';
import { AppProxy } from './services/app-proxy';
import { UserStatus, user } from './models/user';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit, OnChanges {
  @Output() state: EventEmitter<number> = new EventEmitter<number>();
  @Output() changByFood: EventEmitter<number> = new EventEmitter<number>();
  @Input() appUrl: string;
  currentUrl: string;
  showFooter: boolean = true;
  login: boolean = this.auth.getAuth();
  static priceTotal = 'פריטים-0';
  static price = 0;
  lastNavigationUrl: string;
  currentUser: user | undefined;

  constructor(
    private router: Router,
    private topDataService: topDataService,
    private innerDateService: innerDataService,
    private dataService: DataService,
    private titleService: Title,
    private route: ActivatedRoute,
    private auth: AuthGuard,
    public proxy: AppProxy,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.handleRouteEvents();
  }
  ngOnChanges(changes: SimpleChanges): void {
    throw new Error('Method not implemented.');
  }

  async ngOnInit() {
    const token = localStorage.getItem('token');
    if (!token) {
      await this.getUserData();
      if (!this.login) {
        localStorage.clear();
        this.router.navigate(['/login']);
      } else {
        const userStatus = this.currentUser.status;
        if (userStatus === UserStatus.PENDING) {
          this.router.navigate(['/pending']);
        } else if (userStatus === UserStatus.REJECTED) {
          this.router.navigate(['/rejected']);
        }
      }
    }

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.currentUrl = this.router.routerState.snapshot.url;
        if (this.currentUrl !== '/') {
          this.lastNavigationUrl = this.currentUrl;
        }
        const url = this.currentUrl.split('/');
        const parentRoute = 1;
        const childRoute = 2;
        if (url[parentRoute] === '') {
          this.router.navigate(['/']);
        }
        switch (url[parentRoute]) {
          case 'contact':
            if (window.innerWidth < 480) {
              this.router.navigate(['/']);
            }
            break;

          case 'pending':
            if (localStorage.getItem('currentId')) {
              this.router.navigate(['/']);
            }
            break;

          case 'admin':
            if (localStorage.getItem('role') === 'User') {
              this.router.navigate(['/']);
            }
            break;

          case 'termAndConditions':
            if (localStorage.getItem('currentId')) {
              this.router.navigate(['/']);
            }
            break;

          case 'forgotPassword':
            if (localStorage.getItem('currentId')) {
              this.router.navigate(['/']);
            }

          default:
            break;
        }

        if (url.length === 3) {
          const topData = this.topDataService;
          const innerData = this.innerDateService;
          const parent = url[parentRoute];
          const child = url[childRoute].split('?')[0];
          if (parent === 'top') {
            const period = url[childRoute].split('?')[1].split('=')[1];
            const timeframes =
              child === 'value'
                ? timeFramesArrays.value
                : timeFramesArrays.otherAlgos;
            const id: number = timeframes.findIndex(
              (item: string) => period === item
            );
            topData.setIndexOfFilterInTop(id);
          }
          switch (parent) {
            case 'top':
              switch (child) {
                case AlgoNameRouting.CAPITAL:
                  topData.updateProperty('name', 'Capital Preservation');
                  topData.setType(`${StockTypeEnum.CAPITAL_PRESERVATION}`);
                  topData.setDescription(AlgorithmsDescriptions.CAPITAL);
                  break;
                case AlgoNameRouting.MOMENTUM:
                  topData.updateProperty('name', 'Momentum');
                  topData.setType(`${StockTypeEnum.MOMENTUM}`);
                  topData.setDescription(AlgorithmsDescriptions.MOMENTUM);
                  break;
                case AlgoNameRouting.BDD:
                  topData.updateProperty('name', 'Buy the Dip');
                  topData.setType(`${StockTypeEnum.BUY_THE_DIP}`);
                  topData.setDescription(AlgorithmsDescriptions.BDD);
                  break;
                case AlgoNameRouting.SOCIAL:
                  topData.updateProperty('name', 'Social Sentiment');
                  topData.setType(`${StockTypeEnum.SOCIAL_SENTIMENT}`);
                  topData.setDescription(AlgorithmsDescriptions.SOCIAL);
                  break;
                case AlgoNameRouting.VALUE:
                  topData.updateProperty('name', 'Value Investing');
                  topData.setType(`${StockTypeEnum.VALUE_INVESTING}`);
                  topData.setDescription(AlgorithmsDescriptions.VALUE);
                  break;
                default:
                  this.router.navigate(['/']);
                  break;
              }
              break;

            case 'inner':
              innerData.updateProperty('stockType', child);
              break;

            case 'groupType':
              const isWebSite = window.innerWidth > 480;
              switch (true) {
                case isWebSite && child === AlgoNameRouting.CAPITAL:
                  topData.setName('Capital Preservation');
                  break;
                case isWebSite && child === AlgoNameRouting.MOMENTUM:
                  topData.setName('Momentum');
                  break;
                case isWebSite && child === AlgoNameRouting.BDD:
                  topData.setName('Buy the Dip');
                  break;
                case isWebSite && child === AlgoNameRouting.SOCIAL:
                  topData.setName('Social Sentiment');
                  break;
                case isWebSite && child === AlgoNameRouting.VALUE:
                  topData.setName('Value Investing');
                  break;
                default:
                  this.router.navigate(['/']);
                  break;
              }
              break;

            default:
              this.router.navigate(['/']);
              break;
          }
        }
      }
    });

    window.addEventListener('popstate', () => {
      if (this.currentUrl === '/') {
        this.router.navigate([`${this.lastNavigationUrl}`]);
      }
    });
  }

  handleRouteEvents() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const title = this.getTitle(
          this.router.routerState,
          this.router.routerState.root
        ).join('-');
        gtag('event', 'page_view', {
          page_title: title,
          page_path: event.urlAfterRedirects,
          page_location: this.document.location.href,
        });
      }
    });
  }

  getTitle(state: RouterState, parent: ActivatedRoute): string[] {
    const data = [];
    if (parent && parent.snapshot.data && parent.snapshot.data['title']) {
      data.push(parent.snapshot.data['title']);
    }
    if (state && parent && parent.firstChild) {
      data.push(...this.getTitle(state, parent.firstChild));
    }
    return data;
  }

  public async getUserData() {
    const url = this.proxy.baseQAUrl + 'user/me/';
    let tmp: user = { role: '', active: false, status: '' };
    await new Promise((resolve, reject) => {
      this.proxy.executeUserGet(url).subscribe((data: any) => {
        if (data?.success) {
          const { role, active, status, email } = data?.data;
          tmp = {
            role,
            active,
            status,
            email,
          };
          this.currentUser = tmp;
          resolve(this.currentUser);
        } else {
          reject();
        }
      });
    });
    return tmp;
  }
}
