import { ElementSchemaRegistry } from '@angular/compiler';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import {
  AlgoNameRouting,
  StockType,
  StockTypeEnum,
  timeFramesArrays,
} from 'src/app/models/stockType';
import { DataService } from 'src/app/services/data.service';
import { AuthGuard } from 'src/app/services/guards/auth.guard';
import { StocksService } from 'src/app/services/stocks-service';
import { topDataService } from 'src/app/services/topData.service';
import { ContactComponent } from '../contact/contact.component';
import { PopupTypeComponent } from '../popup-type/popup-type.component';

@Component({
  selector: 'app-choose-type',
  templateUrl: './choose-type.component.html',
  styleUrls: ['./choose-type.component.css'],
})
export class ChooseTypeComponent implements OnInit {
  @Output() showCharacter: EventEmitter<number> = new EventEmitter<number>();
  token: boolean = this.auth.getAuth();
  chooseType: StockType[] = [];
  innerWidth: any;
  showProgress = false;

  constructor(
    private dataService: DataService,
    private stocksService: StocksService,
    private topDataService: topDataService,
    private matDialog: MatDialog,
    private router: Router,
    private auth: AuthGuard
  ) {
    router.events.subscribe((val) => {
      this.token = this.auth.getAuth();
    });
  }

  ngOnInit(): void {
    this.innerWidth = window.innerWidth;
    sessionStorage.isQuestion = false;
    this.dataService.character.next(false);
    this.showCharacter.emit();

    //this.stocksService.getStockType().subscribe((res:any)=>{
    this.chooseType = [
      {
        id: StockTypeEnum.CAPITAL_PRESERVATION,
        name: 'Capital Preservation',
        description: 'Sleep better at night',
        select: false,
        createdAt: '',
        updatedAt: '',
        image: 'assets/images/Home_Icon_22.png',
      },
      {
        id: StockTypeEnum.MOMENTUM,
        name: 'Momentum',
        description: 'Winning horses keep on winning',
        select: false,
        createdAt: '',
        updatedAt: '',
        image: 'assets/images/Home_Icon_11.png',
      },
      {
        id: StockTypeEnum.BUY_THE_DIP,
        name: 'Buy the Dip',
        description: 'Number 1 rule in investing is buy cheap',
        select: false,
        createdAt: '',
        updatedAt: '',
        image: 'assets/images/Home_Icon_33.png',
      },
      {
        id: StockTypeEnum.SOCIAL_SENTIMENT,
        name: 'Social Sentiment',
        description: 'Invest in the big next thing everyone is talking about',
        select: false,
        createdAt: '',
        updatedAt: '',
        image: 'assets/images/Home_Icon_44.png',
      },
      {
        id: StockTypeEnum.SOCIAL_SENTIMENT,
        name: 'Value Investing',
        description: 'Don’t gamble. Make knowledgeable decisions',
        select: false,
        createdAt: '',
        updatedAt: '',
        image: 'assets/images/Home_Icon_54.png',
      },
    ];
    // });
  }

  getAlgoRoutes() {
    return {
      'Capital Preservation': AlgoNameRouting.CAPITAL,
      Momentum: AlgoNameRouting.MOMENTUM,
      'Buy the Dip': AlgoNameRouting.BDD,
      'Social Sentiment': AlgoNameRouting.SOCIAL,
      'Value Investing': AlgoNameRouting.VALUE,
    };
  }

  question(i: number) {
    sessionStorage.isQuestion = true;
    sessionStorage.fromHomePage = true;
    this.topDataService.setType(i + '');
    this.topDataService.setName(this.chooseType[i].name);
    this.topDataService.setDescription(this.chooseType[i].description);
    if (this.innerWidth <= 480) {
      this.openDialog();
    } else {
      this.router.navigate([
        '../groupType',
        this.getAlgoRoutes()[this.chooseType[i].name],
      ]);
    }
  }

  whoMi() {
    // if(this.innerWidth<=480)
    // this.matDialog.open(ContactComponent, { disableClose: true });
    // else
    // this.router.navigate(['../contact']);
    this.router.navigate(['../whomi']);
  }

  openDialog() {
    this.matDialog.open(PopupTypeComponent, { disableClose: true });
  }

  type(i: number) {
    if (this.token) {
      if (sessionStorage.isQuestion == 'false') {
        this.showProgress = true;
        this.topDataService.setType(i + '');
        if (this.chooseType[i] != null) {
          this.topDataService.setName(this.chooseType[i].name);
          this.topDataService.setDescription(this.chooseType[i].description);
        }
        this.showProgress = false;
        const algoRoutes = this.getAlgoRoutes();

        const algoName = this.chooseType[i].name;
        const route = algoRoutes[algoName] || algoRoutes['Value Investing'];
        const timeFrames =
          algoName === 'Value Investing'
            ? timeFramesArrays.value
            : timeFramesArrays.otherAlgos;
        const indexOfFilter: number =
          this.topDataService.getIndexOfFilterInTop();
        const id = indexOfFilter !== -1 ? indexOfFilter : 0;
        const previousName = this.topDataService.getPreviousName();
        const period =
          route === algoRoutes[previousName] ? timeFrames[id] : '1D';

        if (period === '1D') {
          this.topDataService.setIndexOfFilterInTop(0);
        }

        this.router.navigate(['/top', route], {
          queryParams: { period },
        });
      }
    } else {
      this.router.navigate(['./login']);
    }
  }

  mouseOver(pos: number) {
    for (let i = 0; i < this.chooseType.length; i++) {
      this.chooseType[i].select = false;
    }
    this.chooseType[pos].select = true;
  }

  mouseOut(pos: number) {
    for (let i = 0; i < this.chooseType.length; i++) {
      this.chooseType[i].select = false;
    }
  }
}
