import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormDataService } from 'src/app/services/shared.service';

@Component({
  selector: 'app-login',
  templateUrl: './termsAndConditions.component.html',
  styleUrls: ['./termsAndConditions.component.css'],
})
export class TermsAndConditionsComponent implements OnInit {
  text: string;
  updatedFormData: any;
  innerWidth: any;

  constructor(
    private router: Router,
    private formDataService: FormDataService
  ) {}

  ngOnInit(): void {
    this.innerWidth = window.innerWidth;
  }

  onAccept() {
    this.formDataService.updateProperty('terms', true)
    this.router.navigate(['signup']);
  }

  onLogin() {
    this.router.navigate(['login']);
  }
}
