const apiKeys = ['ROTJ4APRN3NA3N4B', 'D352FPT76YLM6DTY', '41FANO6KHH7GXMGL'];

export const getDataFromAlpha = async (symbol) => {
  for (const key of apiKeys) {
    const endpoint = `https://www.alphavantage.co/query?function=OVERVIEW&symbol=${symbol}&apikey=${key}`;

    const resp = await fetch(endpoint);
    const data = await resp.json();

    const res = {
      description: data.Description,
      sector: data.Sector,
      market_cap: data.MarketCapitalization
    };

    if (res.description || res.sector || res.market_cap) return res;
  }
  return {
    description: '',
    sector: '',
    market_cap: '',
  };
};
