<div class="adminDashboard" *ngIf="innerWidth > 480">
  <div class="search">
    <mat-form-field>
      <input
        matInput
        type="text"
        placeholder="Search User"
        class="inputSearch"
        [disabled]="!dataSource"
        [(ngModel)]="searchUser"
        (ngModelChange)="searchText(searchUser)"
      />
    </mat-form-field>
  </div>
  <table mat-table [dataSource]="dataSource" class="fullscreen-table">
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef class="thHeight">ID</th>
      <td mat-cell *matCellDef="let element">{{ element.id }}</td>
    </ng-container>

    <ng-container matColumnDef="firstName">
      <th mat-header-cell *matHeaderCellDef class="thHeight">First Name</th>
      <td mat-cell *matCellDef="let element">{{ element.firstName }}</td>
    </ng-container>

    <ng-container matColumnDef="lastName">
      <th mat-header-cell *matHeaderCellDef class="thHeight">Last Name</th>
      <td mat-cell *matCellDef="let element">{{ element.lastName }}</td>
    </ng-container>

    <ng-container matColumnDef="country">
      <th mat-header-cell *matHeaderCellDef class="thHeight">Country</th>
      <td mat-cell *matCellDef="let element">{{ element.country }}</td>
    </ng-container>

    <ng-container matColumnDef="phoneNumber">
      <th mat-header-cell *matHeaderCellDef class="thHeight">Phone Number</th>
      <td mat-cell *matCellDef="let element">{{ element.phoneNumber }}</td>
    </ng-container>

    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef class="thHeight">Email</th>
      <td mat-cell *matCellDef="let element">{{ element.email }}</td>
    </ng-container>

    <ng-container matColumnDef="role">
      <th mat-header-cell *matHeaderCellDef class="thHeight">Role</th>
      <td mat-cell *matCellDef="let element">
        <mat-form-field>
          <mat-select
            [(ngModel)]="element.role"
            (selectionChange)="handleDisable(element)"
          >
            <mat-option *ngFor="let role of roles" [value]="role">
              {{ role }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th
        mat-header-cell
        style="width: 120px !important"
        class="thHeight"
        *matHeaderCellDef
      >
        Status
      </th>
      <td mat-cell *matCellDef="let element">
        <mat-form-field>
          <mat-select
            [(ngModel)]="element.status"
            (selectionChange)="handleDisable(element)"
          >
            <mat-option
              *ngFor="let key of enumKeys(status)"
              [value]="status[key]"
            >
              {{ status[key] }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </td>
    </ng-container>

    <ng-container matColumnDef="edit">
      <th mat-header-cell *matHeaderCellDef class="thHeight"></th>
      <td mat-cell *matCellDef="let element">
        <button
          mat-icon-button
          (click)="changeUserRole(element)"
          [disabled]="element.editDisabled"
          class="button"
          [class.disabledButton]="element.editDisabled"
        >
          <mat-icon>Edit</mat-icon>
        </button>
      </td>
    </ng-container>

    <ng-container matColumnDef="remove">
      <th mat-header-cell *matHeaderCellDef class="thHeight"></th>
      <td mat-cell *matCellDef="let element">
        <button
          *ngIf="element.id !== currentUserId"
          (click)="deleteUser(element)"
          class="removeButton"
        >
          Remove
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <mat-paginator
    appStylePaginator
    class="mat-paginator-footer"
    [length]="totalCount"
    [pageSize]="10"
    [pageSizeOptions]="[5, 10, 25]"
    (page)="pageNavigate($event)"
    [showFirstLastButtons]="false"
    dropzone="top"
  >
  </mat-paginator>
</div>

<div class="mobileTableContainer" *ngIf="innerWidth < 480">
  <div class="search">
    <mat-form-field>
      <input
        matInput
        type="text"
        placeholder="Search User"
        class="inputSearch"
        [(ngModel)]="searchUser"
        (ngModelChange)="searchText(searchUser)"
      />
    </mat-form-field>
  </div>
  <section class="example-container mat-elevation-z8" tabindex="0">
    <table mat-table [dataSource]="dataSource">
      <ng-container matColumnDef="id" class="id">
        <th mat-header-cell *matHeaderCellDef>ID</th>
        <td mat-cell *matCellDef="let element">{{ element.id }}</td>
      </ng-container>

      <ng-container matColumnDef="firstName">
        <th mat-header-cell *matHeaderCellDef>First Name</th>
        <td mat-cell *matCellDef="let element">{{ element.firstName }}</td>
      </ng-container>

      <ng-container matColumnDef="lastName">
        <th mat-header-cell *matHeaderCellDef>Last Name</th>
        <td mat-cell *matCellDef="let element">{{ element.lastName }}</td>
      </ng-container>

      <ng-container matColumnDef="country">
        <th mat-header-cell *matHeaderCellDef>Country</th>
        <td mat-cell *matCellDef="let element">{{ element.country }}</td>
      </ng-container>

      <ng-container matColumnDef="phoneNumber">
        <th mat-header-cell *matHeaderCellDef>Phone Number</th>
        <td mat-cell *matCellDef="let element">{{ element.phoneNumber }}</td>
      </ng-container>

      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef>Email</th>
        <td mat-cell *matCellDef="let element">{{ element.email }}</td>
      </ng-container>

      <ng-container matColumnDef="role">
        <th mat-header-cell *matHeaderCellDef>Role</th>
        <td mat-cell *matCellDef="let element">
          <mat-form-field>
            <mat-select
              [(ngModel)]="element.role"
              (selectionChange)="handleDisable(element)"
            >
              <mat-option *ngFor="let role of roles" [value]="role">
                {{ role }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell style="width: 120px !important" *matHeaderCellDef>
          Status
        </th>
        <td mat-cell *matCellDef="let element">
          <mat-form-field>
            <mat-select
              [(ngModel)]="element.status"
              (selectionChange)="handleDisable(element)"
            >
              <mat-option
                *ngFor="let key of enumKeys(status)"
                [value]="status[key]"
              >
                {{ status[key] }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </td>
      </ng-container>

      <ng-container matColumnDef="edit">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
          <button
            mat-icon-button
            (click)="changeUserRole(element)"
            [disabled]="element.editDisabled"
            class="button"
            [class.disabledButton]="element.editDisabled"
          >
            <mat-icon>Edit</mat-icon>
          </button>
        </td>
      </ng-container>

      <ng-container matColumnDef="remove">
        <th mat-header-cell *matHeaderCellDef class="thHeight"></th>
        <td mat-cell *matCellDef="let element">
          <button
            *ngIf="element.id !== currentUserId"
            (click)="deleteUser(element)"
            class="removeButton"
          >
            Remove
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </section>
  <mat-paginator
    appStylePaginator
    class="mat-paginator-footer"
    [length]="totalCount"
    [pageSize]="6"
    [pageSizeOptions]="[6, 12]"
    (page)="pageNavigate($event)"
    [showFirstLastButtons]="false"
    dropzone="top"
  >
  </mat-paginator>
</div>
