import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ChooseTypeComponent } from './components/choose-type/choose-type.component';
import { InnerComponent } from './components/inner/inner.component';
import { MainHomeComponent } from './components/main-home/main-home.component';
import { SplashComponent } from './components/splash/splash.component';
import { TopComponent } from './components/top/top.component';
import { ValueInvestingComponent } from './components/value-investing/value-investing.component';
import { Contact2Component } from './components/contact2/contact2.component';
import { GroupTypeComponent } from './components/group-type/group-type.component';
import { WhoMiComponent } from './components/who-mi/who-mi.component';
import { LoginComponent } from './components/login/login.component';
import { SignupComponent } from './components/signup/signup.component';
import { SettingsComponent } from './components/user-settings/settings.component';
import { AuthGuard } from './services/guards/auth.guard';
import { ForgotPasswordComponent } from './components/forgot-password/forgotPassword.component';
import { TermsAndConditionsComponent } from './components/termsAndConditions/termsAndConditions.component';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { TableComponent } from './components/admin/admin.component';
import { PendingStatusComponent } from './components/pending-status/pending-status.component';
import { RejectedStatusComponent } from './components/rejected-status/rejected-status.component';

const routes: Routes = [
  { path: 'splash', component: SplashComponent, canActivate: [AuthGuard] },
  { path: 'mainHome', component: MainHomeComponent, canActivate: [AuthGuard] },
  {
    path: 'valueInvesting',
    component: ValueInvestingComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'top/:selectedElement',
    component: TopComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'inner/:selectedInner',
    component: InnerComponent,
    canActivate: [AuthGuard],
  },
  { path: 'contact', component: Contact2Component, canActivate: [AuthGuard] },
  {
    path: 'groupType/:selectedTop',
    component: GroupTypeComponent,
    canActivate: [AuthGuard],
  },
  { path: 'whomi', component: WhoMiComponent, canActivate: [AuthGuard] },
  { path: 'login', component: LoginComponent },
  { path: 'signup', component: SignupComponent },
  { path: 'forgotPassword', component: ForgotPasswordComponent },
  { path: 'termAndConditions', component: TermsAndConditionsComponent },
  {
    path: 'userSettings',
    component: SettingsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'admin',
    component: TableComponent,
    canActivate: [AuthGuard],
  },
  {
    path: '',
    pathMatch: 'full',
    component: ChooseTypeComponent,
  },
  {
    path: 'pending',
    pathMatch: 'full',
    component: PendingStatusComponent,
  },
  {
    path: 'rejected',
    pathMatch: 'full',
    component: RejectedStatusComponent,
  },
  { path: '**', redirectTo: '/', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [
    [AuthGuard],
    { provide: LocationStrategy, useClass: PathLocationStrategy },
  ],
})
export class AppRoutingModule {}
