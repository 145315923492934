import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class innerDataService {
  private stockName: string;
  private stockType: string;
  private days: string;
  private price: string;
  private sector: string;
  private market_cap: string;
  private class: string;
  private stockRet: string;
  
  private propertySource = new BehaviorSubject<string>('initial value');
  currentProperty = this.propertySource.asObservable();

  updateProperty(propertyName: string, newValue: string): void {
    switch (propertyName) {
      case 'stockType':
        this.setStockType(newValue);
        break;
      default:
        console.error(`Invalid property name: ${propertyName}`);
        return;
    }

    this.propertySource.next('stockType');
  }

  setStockName(value: string): void {
    console.log(value, 'stockName');

    this.stockName = value;
  }

  getStockName(): string {
    return this.stockName;
  }

  setStockType(value: string): void {
    console.log(value, 'stockType');

    this.stockType = value;
  }

  getStockType(): string {
    return this.stockType;
  }

  setDays(value: string): void {
    console.log(value, 'days');

    this.days = value;
  }

  getDays(): string {
    return this.days;
  }

  setPrice(value: string): void {
    console.log(value, 'price');

    this.price = value;
  }

  getPrice(): string {
    return this.price;
  }

  setSector(value: string): void {
    console.log(value, 'sector');

    this.sector = value;
  }

  getSector(): string {
    return this.sector;
  }

  setMarket_Cap(value: string): void {
    console.log(value, 'market_cap');

    this.market_cap = value;
  }

  getMarket_Cap(): string {
    return this.market_cap;
  }

  setClass(value: string): void {
    console.log(value, 'class');

    this.class = value;
  }

  getClass(): string {
    return this.class;
  }

  setStockRet(value: string): void {
    console.log(value, 'stockRet');

    this.stockRet = value;
  }

  getStockRet(): string {
    return this.stockRet;
  }
}
