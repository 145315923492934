import { Component } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { AppProxy } from 'src/app/services/app-proxy';

@Component({
  selector: 'app-forgotPassword',
  templateUrl: './forgotPassword.component.html',
  styleUrls: ['./forgotPassword.component.css'],
})
export class ForgotPasswordComponent {
  resetPasswordForm: FormGroup;
  sendEmailForm: FormGroup;
  step: number = 1;
  message: string = '';
  resendCodeTimer: number = 30;
  timer: boolean = false;

  email: FormControl = new FormControl('', [
    Validators.required,
    Validators.email,
  ]);
  password: FormControl = new FormControl('', [
    Validators.required,
    Validators.minLength(5),
  ]);
  confirmPassword: FormControl = new FormControl('', [
    Validators.required,
    Validators.minLength(5),
  ]);
  code: FormControl = new FormControl('', [
    Validators.required,
    Validators.minLength(4),
    Validators.minLength(4),
  ]);

  constructor(
    private router: Router,
    private proxy: AppProxy,
    private formBuilder: FormBuilder
  ) {
    this.sendEmailForm = this.formBuilder.group({
      email: this.email,
    });
    this.resetPasswordForm = this.formBuilder.group({
      code: this.code,
      password: this.password,
      confirmPassword: this.confirmPassword,
    });
  }

  onSendEmail() {
    if (this.sendEmailForm.valid) {
      this.message = '';
      const url = this.proxy.baseQAUrl + 'auth/' + 'sendEmail';
      const values = this.sendEmailForm.value;
      this.proxy.executePost(url, values).subscribe((data: any) => {
        if (data?.success) {
          this.message = '';
          this.step = 2;
        } else {
          this.message = data?.message;
        }
      });
    } else {
      this.message = 'Please Send Your Email';
    }
  }

  onResetPassword() {
    if (!this.resetPasswordForm.valid) {
      this.message =
        'Please fill in the fields. Minimum password length 5 characters';
    } else if (
      this.resetPasswordForm.value.password !==
      this.resetPasswordForm.value.confirmPassword
    ) {
      this.message = ' Passwords do not match';
    } else {
      this.message = '';
      const url = this.proxy.baseQAUrl + 'auth/' + 'resetPassword';
      const values = {
        code: this.resetPasswordForm.value.code,
        password: this.resetPasswordForm.value.password,
        email: this.sendEmailForm.value.email,
      };
      this.proxy.executePost(url, values).subscribe((data: any) => {
        if (data?.success) {
          this.router.navigate(['login']);
        } else {
          this.message = data?.message;
        }
      });
    }
  }

  onResetCode() {
    this.timer = true;
    const timerInterval = setInterval(() => {
      this.resendCodeTimer = this.resendCodeTimer - 1;
      if (this.resendCodeTimer === 0) {
        this.timer = false;
        clearInterval(timerInterval);
        this.resendCodeTimer = 30;
      }
    }, 1000);

    const url = this.proxy.baseQAUrl + 'auth/' + 'sendEmail';
    const values = this.sendEmailForm.value;
    this.proxy.executePost(url, values).subscribe((data: any) => {
      if (!data?.success) {
        this.message = data?.message;
      }
    });
  }

  onLogin() {
    this.router.navigate(['login']);
  }
}
