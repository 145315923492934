<div class="form-signin">
  <div style="right: 0; left: 0; margin: auto; margin-bottom: 30px">
    <div class="width1">
      <div class="d-flex backNameDescription">
        <div class="backName">
          <button class="backBtnToTop" (click)="backToTop()">
            <span class="arrow"
              ><img src="assets/images/Vector.svg" alt=""
            /></span>
            <span class="back">Back</span>
          </button>
          <div class="d-flex">
            <div class="name">{{ name }}</div>
            <img
              class="question"
              (click)="question()"
              src="assets/images/User_Popup_Button.png"
              alt=""
            />
          </div>
          <div class="description name">{{ description }}</div>
        </div>
        <img class="txt-input" [src]="img" alt="" />
      </div>

      <div
        class="d-flex flex-wrap"
        [ngClass]="innerWidth > 480 ? 'container1' : 'null'"
      >
        <img
          *ngIf="innerWidth <= 480"
          class="line"
          src="assets/images/Line_1.png"
          alt=""
        />
        <div *ngIf="innerWidth <= 480" class="d-flex" style="margin-top: 65px">
          <div
            (click)="searchBy()"
            (keydown.enter)="searchBy()"
            class="col3"
            style="
              width: 270px;
              background-image: url(assets/images/Search_Box.png);
              background-size: 190px 30px;
            "
          >
            <img
              style="cursor: pointer; padding-left: 10px"
              src="assets/images/Search_Button.png"
              alt=""
            />
            <input
              class="search"
              [(ngModel)]="text"
              name="searchText"
              [style.color]="'white'"
              placeholder="Search"
            />
          </div>
          <!-- <div
            *ngIf="innerWidth > 480"
            style="position: absolute; left: 240px; font-size: 14px"
          >
            Choose character
          </div> -->
          <div
            *ngIf="innerWidth <= 480"
            class="chooseButton"
            (click)="person()"
          >
            Choose <br />
            character
          </div>
          <div class="person">
            <img (click)="person()" src="assets/images/Person.png" alt="" />
          </div>
        </div>
        <div
          class="d-flex"
          style="
            width: 100%;
            margin-top: 10px;
            direction: ltr;
            margin-left: 3%;
            margin-top: 3%;
          "
        >
          <div class="top">Top 10</div>
          <div
            *ngIf="innerWidth > 480"
            (keydown.enter)="searchBy()"
            class="col3"
            style="
              width: 270px;
              background-image: url(assets/images/Search_Box.png);
            "
          >
            <img
              (click)="searchBy()"
              style="cursor: pointer; padding-left: 10px"
              src="assets/images/Search_Button.png"
              alt=""
            />
            <input
              class="search"
              [(ngModel)]="text"
              name="searchText"
              [style.color]="'white'"
              placeholder="Search"
            />
          </div>
          <div *ngIf="innerWidth > 480">
            <choose-button
              style="position: absolute; right: 3%"
            ></choose-button>
          </div>
        </div>
        <div
          class="d-flex"
          [ngClass]="
            name === 'Value Investing' && innerWidth < 480
              ? 'date1Value'
              : 'date1'
          "
          [ngClass]="innerWidth > 480 ? 'btn-block' : 'null'"
        >
          <div *ngFor="let date of dateList; let i = index">
            <span
              (click)="showProgress ? null : byDate(i, date.date)"
              [style.cursor]="showProgress ? 'default' : 'pointer'"
              style="padding-left: 10px"
              class="nav-link"
              [ngClass]="date.selected == true ? 'dateSelect' : 'date'"
              >{{ date.date }}</span
            >
          </div>
        </div>

        <div
          class="middel"
          *ngIf="
            innerWidth <= 480 &&
            stockList?.length > 0 &&
            name != 'Value Investing'
          "
        >
          <div class="itemMiddel" style="margin-top: 5px">
            <span>SPY</span>
          </div>
          <div class="middleSection spyMiddle">
            <div class="header2 spyHeader">SPY</div>
            <div *ngIf="sandpList[0] == null" class="price1 middelSpy">0$</div>
            <div *ngIf="sandpList[0] != null" class="price1 middelSpy">
              {{
                name !== "Social Sentiment"
                  ? sandpList[0].price
                  : (sandpList[0].buythedip[0].price + "").slice(0, 5)
              }}$
            </div>
          </div>
          <div class="d-flex middel2">
            <span *ngIf="sandpList[0] == null && innerWidth > 480" class="sp"
              >0</span
            >
            <span *ngIf="sandpList[0] != null && innerWidth > 480" class="sp">{{
              sandpList[0].stocktype
            }}</span>
            <div class="std1" style="margin-left: 1%">|</div>
            <div *ngIf="sandpList[0] == null" class="std1">RET: 0.0</div>
            <div *ngIf="sandpList[0] != null" class="std1">
              RET:
              {{
                name !== "Social Sentiment"
                  ? sandpList[0].ret
                  : sandpList[0].buythedip[0].ret + "%"
              }}
            </div>

            <div class="std1" style="left: 30%">|</div>

            <div
              *ngIf="sandpList[0] == null && maxDdText == 'max_dd'"
              class="std1"
              style="left: 50%"
            >
              MAX DD: 0.0
            </div>
            <div
              *ngIf="sandpList[0] != null && maxDdText == 'max_dd'"
              class="std1"
              style="left: 50%"
            >
              MAX DD: {{ sandpList[0].max_dd }}%
            </div>

            <div
              *ngIf="sandpList[0] == null && maxDdText == 'ma20'"
              class="std1"
              style="left: 50%"
            >
              MaxDD: 0.0%
            </div>
            <div
              *ngIf="sandpList[0] != null && maxDdText == 'ma20'"
              class="std1"
              style="left: 50%"
            >
              MaxDD: {{ sandpList[0].max_dd | number : "1.2-2" }}%
            </div>

            <div
              *ngIf="sandpList[0] == null && maxDdText == 'cci'"
              class="std1"
              style="left: 50%"
            >
              CCI: 0.0
            </div>
            <div
              *ngIf="sandpList[0] != null && maxDdText == 'cci'"
              class="std1"
              style="left: 50%"
            >
              CCI: {{ sandpList[0].cci }}
            </div>

            <div
              *ngIf="sandpList[0] == null && maxDdText == 'Sentiment Rank'"
              class="std1"
              style="left: 50%"
            >
              Sentiment Rank: 0.0
            </div>
            <div
              *ngIf="sandpList[0] != null && maxDdText == 'Sentiment Rank'"
              class="std1"
              style="left: 50%"
            >
              Sentiment Rank:
              {{
                name !== "Social Sentiment"
                  ? sandpList[0].qut
                  : sandpList[0].sentiment_rank
              }}
            </div>
            <div class="std1" style="left: 20%">|</div>

            <div
              *ngIf="sandpList[0].std && stdText == 'std'"
              class="std1"
              style="left: 65%"
            >
              STD: {{ sandpList[0].std }}
            </div>
            <div
              *ngIf="sandpList[0] == null && stdText == 'std'"
              class="std1"
              style="left: 65%"
            >
              STD: 0.0%
            </div>

            <div
              *ngIf="sandpList[0] == null && stdText == 'ma50'"
              class="std1"
              style="left: 65%"
            >
              Recovery: 0.0%
            </div>
            <div
              *ngIf="sandpList[0] != null && stdText == 'ma50'"
              class="std1"
              style="left: 65%"
            >
              Recovery: {{ sandpList[0].recovery | number : "1.2-2" }}%
            </div>

            <div
              *ngIf="sandpList[0] == null && stdText == 'roc'"
              class="std1"
              style="left: 65%"
            >
              ROC: 0.0
            </div>
            <div
              *ngIf="sandpList[0] != null && stdText == 'roc'"
              class="std1"
              style="left: 65%"
            >
              ROC: {{ sandpList[0].roc }}
            </div>

            <div
              *ngIf="sandpList[0] == null && stdText == 'Volume change'"
              class="std1"
              style="left: 65%"
            >
              Volume Change: 0.0
            </div>
            <div
              *ngIf="sandpList[0] != null && stdText == 'Volume change'"
              class="std1"
            >
              Volume Change:
              {{ getSocialVolumeChange(sandpList[0].volume_change) }} %
            </div>
          </div>
        </div>

        <div
          *ngIf="
            innerWidth > 480 &&
            stockList?.length > 0 &&
            name !== 'Value Investing'
          "
          class="middel"
          style="align-items: center"
        >
          <div class="d-flex middel4 middleHeader">
            <div
              [ngClass]="{
                'spy-container60': name == 'Social Sentiment',
                'spy-container100': name != 'Social Sentiment'
              }"
            >
              <span class="header sp_">SPY</span>
              <div *ngIf="sandpList[0] != null" class="header2">
                {{ sandpList[0].symbol }}
              </div>
              <div *ngIf="sandpList[0] == null" class="price2">0$</div>
              <div *ngIf="sandpList[0] != null" class="price2">
                {{
                  name !== "Social Sentiment"
                    ? sandpList[0].price
                    : (sandpList[0].buythedip[0].price + "").slice(0, 6)
                }}$
              </div>

              <div class="stdLine">|</div>
              <div *ngIf="sandpList[0] == null" class="std">RET: 0.0</div>
              <div *ngIf="sandpList[0] != null" class="std">
                RET:
                {{
                  name !== "Social Sentiment"
                    ? sandpList[0].ret
                    : sandpList[0].buythedip[0].ret + "%"
                }}
              </div>

              <div class="stdLine" style="left: 30%">|</div>

              <div
                *ngIf="sandpList[0] == null && maxDdText == 'max_dd'"
                class="std"
                style="left: 50%"
              >
                MAX DD: 0.0
              </div>
              <div
                *ngIf="sandpList[0] != null && maxDdText == 'max_dd'"
                class="std"
                style="left: 50%"
              >
                MAX DD: {{ sandpList[0].max_dd }}%
              </div>

              <div
                *ngIf="sandpList[0] == null && maxDdText == 'ma20'"
                class="std"
                style="left: 50%"
              >
                MaxDD: 0.0%
              </div>
              <div
                *ngIf="sandpList[0] != null && maxDdText == 'ma20'"
                class="std"
                style="left: 50%"
              >
                MaxDD: {{ sandpList[0].max_dd | number : "1.2-2" }}%
              </div>

              <div
                *ngIf="sandpList[0] == null && maxDdText == 'cci'"
                class="std"
                style="left: 50%"
              >
                CCI: 0.0
              </div>
              <div
                *ngIf="sandpList[0] != null && maxDdText == 'cci'"
                class="std"
                style="left: 50%"
              >
                CCI: {{ sandpList[0].cci }}
              </div>

              <div
                *ngIf="sandpList[0] == null && maxDdText == 'Sentiment Rank'"
                class="std"
                style="left: 50%"
              >
                Sentiment Rank: 0.0
              </div>
              <div
                *ngIf="sandpList[0] != null && maxDdText == 'Sentiment Rank'"
                class="std"
                style="left: 50%"
              >
                Sentiment Rank:
                {{
                  name !== "Social Sentiment"
                    ? sandpList[0].qut
                    : sandpList[0].sentiment_rank
                }}
              </div>

              <div class="stdLine" style="left: 20%">|</div>

              <div *ngIf="sandpList[0] == null && stdText == 'std'" class="std">
                STD: 0.0
              </div>
              <div *ngIf="sandpList[0] != null && stdText == 'std'" class="std">
                STD: {{ sandpList[0].std }}
              </div>

              <div
                *ngIf="sandpList[0] == null && stdText == 'ma50'"
                class="std"
              >
                Recovery: 0.0%
              </div>
              <div
                *ngIf="sandpList[0] != null && stdText == 'ma50'"
                class="std"
              >
                Recovery: {{ sandpList[0].recovery | number : "1.2-2" }}%
              </div>

              <div *ngIf="sandpList[0] == null && stdText == 'roc'" class="std">
                ROC: 0.0
              </div>
              <div *ngIf="sandpList[0] != null && stdText == 'roc'" class="std">
                ROC: {{ sandpList[0].roc }}
              </div>

              <div
                *ngIf="sandpList[0] == null && stdText == 'Volume change'"
                class="std"
              >
                Volume change: 0.0
              </div>
              <div
                *ngIf="sandpList[0] != null && stdText == 'Volume change'"
                class="std"
              >
                Volume change:
                {{ getSocialVolumeChange(sandpList[0].volume_change) }} %
              </div>
              <div
                *ngIf="name === 'Social Sentiment'"
                class="stdLine"
                style="left: 20%"
              >
                |
              </div>
            </div>
            <div *ngIf="name === 'Social Sentiment'" class="all-links"></div>
          </div>
        </div>
        <mat-spinner
          *ngIf="showProgress == true"
          class="mat-spinner-color"
          style="justify-content: center; align-items: center; margin: 0 auto"
          [diameter]="70"
          mode="indeterminate"
        ></mat-spinner>
        <div style="width: 100%">
          <div
            *ngFor="let stock of stockList; let i = index"
            style="margin-top: 20px"
          >
            <div
              *ngIf="
                innerWidth > 480 && stock.symbol != null && stock.symbol != ''
              "
              class="middel3"
              (click)="inner(stock)"
            >
              <div class="d-flex middel4">
                <div
                  [ngClass]="{
                    'left-side60': name == 'Social Sentiment',
                    'left-side100': name != 'Social Sentiment'
                  }"
                  [class]="'stock' + type"
                >
                  <span
                    *ngIf="name != 'Value Investing'"
                    class="header valueHeader"
                  >
                    <span
                      *ngIf="
                        stock.tickerData == null &&
                        stock.symbolName != undefined &&
                        stock.symbolName[0] != undefined
                      "
                    >
                      {{
                        name === "Value Investing"
                          ? truncateStr(stock.symbolName[0].name, 40)
                          : stock.symbolName[0].name
                      }}
                    </span>
                  </span>
                  <span
                    *ngIf="
                      stock.symbolName == null && stock.tickerData != undefined
                    "
                    class="header valueHeader"
                    >{{
                      name === "Value Investing"
                        ? truncateStr(
                            stock.tickerData[0] ? stock.tickerData[0].name : "",
                            40
                          )
                        : stock.tickerData[0]
                        ? stock.tickerData[0].name
                        : ""
                    }}</span
                  >
                  <div
                    class="header2"
                    (click)="inner(stock)"
                    style="text-align: center"
                  >
                    {{ stock.symbol }}
                  </div>
                  <div class="price2">
                    {{
                      (stock.price
                        ? stock.price != "NaN" && stock.price != "nan"
                          ? stock.price
                          : "0.00"
                        : stock?.buythedip[0]?.price != null &&
                          name !== "Value Investing"
                        ? (stock?.buythedip[0]?.price + "").slice(0, 5)
                        : "0.00") + "$"
                    }}
                  </div>
                  <div class="stdLine">|</div>
                  <ng-container
                    *ngIf="
                      name !== 'Value Investing' && name !== 'Social Sentiment'
                    "
                  >
                    <div *ngIf="stock.ret == null" class="std">RET: 0.0%</div>
                    <div *ngIf="stock.ret != null" class="std">
                      RET: {{ stock.ret }}
                    </div>
                  </ng-container>
                  <ng-container
                    *ngIf="
                      name === 'Value Investing' || name === 'Social Sentiment'
                    "
                  >
                    <!-- <div *ngIf="stock?.buythedip[0]?.ret == null" class="std">
                    RET: 0.0%
                  </div> -->
                    <div *ngIf="stock?.buythedip" class="std">
                      RET:
                      {{
                        stock?.buythedip?.length !== 0
                          ? (stock?.buythedip[0]?.ret | number : "1.2-2")
                          : "0.0"
                      }}%
                    </div>
                  </ng-container>
                  <div class="stdLine">|</div>

                  <div
                    *ngIf="stock.max_dd == null && maxDdText == 'max_dd'"
                    class="std"
                  >
                    MAX DD: 0.0
                  </div>
                  <div
                    *ngIf="stock.max_dd != null && maxDdText == 'max_dd'"
                    class="std"
                  >
                    MAX DD: {{ stock.max_dd }}%
                  </div>

                  <div
                    *ngIf="stock.max_dd == null && maxDdText == 'ma20'"
                    class="std"
                  >
                    MaxDD: 0.0%
                  </div>
                  <div
                    *ngIf="stock.max_dd != null && maxDdText == 'ma20'"
                    class="std"
                  >
                    MaxDD: {{ stock.max_dd * 100 | number : "1.2-2" }}%
                  </div>

                  <div
                    *ngIf="stock.cci == null && maxDdText == 'cci'"
                    class="std"
                  >
                    CCI DD: 0.0
                  </div>
                  <div
                    *ngIf="stock.cci != null && maxDdText == 'cci'"
                    class="std"
                  >
                    CCI: {{ stock.cci }}
                  </div>

                  <div class="std" *ngIf="maxDdText == 'Sentiment Rank'">
                    Sentiment rank:
                    {{
                      stock.sentiment_rank != null
                        ? (stock.sentiment_rank + "").slice(0, 5)
                        : "0.0"
                    }}
                  </div>
                  <ng-container *ngIf="name === 'Value Investing'">
                    <div
                      *ngIf="stock.rev_growth_qtr == null && maxDdText == 'p/e'"
                      class="std stdValue"
                    >
                      Rev_growth_qtr : 0.0%
                    </div>
                    <div
                      *ngIf="stock.rev_growth_qtr != null && maxDdText == 'p/e'"
                      class="std stdValue"
                    >
                      Rev_growth_qtr:
                      {{ stock.rev_growth_qtr * 100 | number : "1.2-2" }}%
                    </div>
                    <div class="stdLine">|</div>
                    <div
                      *ngIf="
                        stock.debt_equity_ratio == null && maxDdText == 'p/e'
                      "
                      class="std stdValue"
                    >
                      Debt/Equity : 0.0%
                    </div>
                    <div
                      *ngIf="
                        stock.debt_equity_ratio != null && maxDdText == 'p/e'
                      "
                      class="std stdValue"
                    >
                      Debt/Equity:
                      {{ stock.debt_equity_ratio * 100 | number : "1.2-2" }}%
                    </div>
                    <div class="stdLine">|</div>
                    <div
                      *ngIf="stock.net_margin == null && maxDdText == 'p/e'"
                      class="std stdValue"
                    >
                      Net_Margin : 0.0%
                    </div>
                    <div
                      *ngIf="stock.net_margin != null && maxDdText == 'p/e'"
                      class="std stdValue"
                    >
                      Net_Margin:
                      {{ stock.net_margin * 100 | number : "1.2-2" }}%
                    </div>
                  </ng-container>

                  <div class="stdLine">|</div>

                  <div
                    *ngIf="stock.std == null && stdText == 'std'"
                    class="std"
                  >
                    STD: 0.0
                  </div>
                  <div
                    *ngIf="stock.std != null && stdText == 'std'"
                    class="std"
                  >
                    STD: {{ stock.std }}
                  </div>

                  <div
                    *ngIf="stock.recovery == null && stdText == 'ma50'"
                    class="std"
                  >
                    Recovery: 0.0%
                  </div>
                  <div
                    *ngIf="stock.recovery != null && stdText == 'ma50'"
                    class="std"
                  >
                    Recovery: {{ stock.recovery * 100 | number : "1.2-2" }}%
                  </div>

                  <div
                    *ngIf="stock.roc == null && stdText == 'roc'"
                    class="std"
                  >
                    ROC: 0.0
                  </div>
                  <div
                    *ngIf="stock.roc != null && stdText == 'roc'"
                    class="std"
                  >
                    ROC: {{ stock.roc }}
                  </div>

                  <div class="std" *ngIf="stdText == 'Volume change'">
                    Volume change:
                    {{
                      stock.volume_change != null
                        ? getSocialVolumeChange(stock.volume_change) + "%"
                        : "0.0"
                    }}
                  </div>

                  <ng-container *ngIf="name === 'Value Investing'">
                    <div
                      *ngIf="
                        (stock.price_earnings == null ||
                          stock.price_earnings == 'nan') &&
                        maxDdText == 'p/e'
                      "
                      class="std"
                    >
                      P/E: 0.0
                    </div>
                    <div
                      *ngIf="
                        stock.price_earnings != null &&
                        stock.price_earnings !== 'nan' &&
                        maxDdText == 'p/e'
                      "
                      class="std"
                    >
                      P/E: {{ stock.price_earnings | number : "1.2-2" }}
                    </div>
                    <div class="stdLine">|</div>
                    <div
                      *ngIf="stock.rating == null && maxDdText == 'p/e'"
                      class="std stdValue"
                    >
                      Analyst Rating: 0.0
                    </div>
                    <div
                      *ngIf="stock.rating != null && maxDdText == 'p/e'"
                      class="std stdValue"
                    >
                      Analyst Rating: {{ stock.rating }}
                    </div>
                  </ng-container>
                  <div *ngIf="!!stock.fmpnews" class="stdLine">|</div>
                </div>
                <div *ngIf="name == 'Social Sentiment'" class="all-links">
                  <div
                    class="link-container"
                    *ngFor="let article of stock.fmpnews; let i = index"
                    (click)="$event.stopPropagation()"
                  >
                    <a [href]="article.url" target="_blank" class="link">{{
                      article.title
                    }}</a>
                  </div>
                </div>
                <!-- <img  style="margin-top: -35px; max-height: 77px;" class="stdImg" src="assets/images/Stock_Graph_Icon_1.png"> -->
              </div>
            </div>

            <div
              *ngIf="
                innerWidth <= 480 && stock.symbol != null && stock.symbol != ''
              "
              (click)="inner(stock)"
              class="middel3"
            >
              <div class="itemMiddel rowMiddle">
                <!-- <span
                  *ngIf="
                    stock.symbolName != undefined &&
                    stock.symbolName[0] != undefined
                  "
                  class="header1"
                  style="font-size: 15px"
                  >{{ stock.symbolName[0].name }}</span
                >
                <span
                  *ngIf="
                    stock.symbolName == null && stock.tickerData != undefined
                  "
                  class="header1"
                  style="font-size: 15px"
                  >{{ stock.tickerData[0] ? stock.tickerData[0].name : '' }}</span
                > -->
                <span
                  *ngIf="name != 'Value Investing'"
                  class="header valueHeader"
                >
                  <span
                    *ngIf="
                      stock.tickerData == null &&
                      stock.symbolName != undefined &&
                      stock.symbolName[0] != undefined
                    "
                  >
                    {{
                      name === "Value Investing"
                        ? truncateStr(stock.symbolName[0].name, 40)
                        : stock.symbolName[0].name
                    }}
                  </span>
                </span>
                <span
                  *ngIf="
                    stock.symbolName == null && stock.tickerData != undefined
                  "
                  class="header valueHeader"
                  >{{
                    name === "Value Investing"
                      ? truncateStr(
                          stock.tickerData[0] ? stock.tickerData[0].name : "",
                          40
                        )
                      : stock.tickerData[0]
                      ? stock.tickerData[0].name
                      : ""
                  }}</span
                >
                <!-- <div class="price2">{{stock.price}}$</div> -->
              </div>
              <!-- <img  class="img3" src="assets/images/Stock_Graph_Icon_1.png"> -->
              <div class="middleSection">
                <div class="symbolHeader">{{ stock.symbol }}</div>
                <div class="price2 middelPrice">
                  {{
                    (stock.price
                      ? stock.price != "NaN" && stock.price != "nan"
                        ? stock.price
                        : "0.00"
                      : stock?.buythedip[0]?.price != null &&
                        name !== "Value Investing"
                      ? (stock?.buythedip[0]?.price + "").slice(0, 5)
                      : "0.00") + "$"
                  }}
                </div>
              </div>
              <div class="d-flex middel4-mobile">
                <div class="left-side100">
                  <!-- <div class="price2">{{stock.price}}$</div> -->
                  <div class="stdLine">|</div>
                  <ng-container
                    *ngIf="
                      name !== 'Value Investing' && name !== 'Social Sentiment'
                    "
                  >
                    <div *ngIf="stock.ret == null" class="std">RET: 0.0%</div>
                    <div *ngIf="!!stock.ret" class="std">
                      RET: {{ stock.ret }}
                    </div>
                  </ng-container>
                  <ng-container
                    *ngIf="
                      name === 'Value Investing' || name === 'Social Sentiment'
                    "
                  >
                    <!-- <div *ngIf="stock.buythedip[0].ret == null" class="std">
                    RET: 0.0%
                  </div> -->
                    <div *ngIf="stock?.buythedip" class="std">
                      RET:
                      {{
                        stock?.buythedip?.length !== 0
                          ? (stock?.buythedip[0]?.ret | number : "1.2-2")
                          : "0.0"
                      }}%
                    </div>
                  </ng-container>
                  <div class="stdLine">|</div>
                  <div
                    *ngIf="stock.max_dd == null && maxDdText == 'max_dd'"
                    class="std"
                  >
                    MAX_DD: 0.0
                  </div>
                  <div
                    *ngIf="stock.max_dd != null && maxDdText == 'max_dd'"
                    class="std"
                  >
                    MAX DD: {{ stock.max_dd }}%
                  </div>

                  <div
                    *ngIf="stock.max_dd == null && maxDdText == 'ma20'"
                    class="std"
                  >
                    MaxDD: 0.0%
                  </div>
                  <div
                    *ngIf="stock.max_dd != null && maxDdText == 'ma20'"
                    class="std"
                  >
                    MaxDD: {{ stock.max_dd * 100 | number : "1.2-2" }}%
                  </div>

                  <div
                    *ngIf="stock.cci == null && maxDdText == 'cci'"
                    class="std"
                  >
                    CCI: 0.0
                  </div>
                  <div
                    *ngIf="stock.cci != null && maxDdText == 'cci'"
                    class="std"
                  >
                    CCI: {{ stock.cci }}
                  </div>
                  <div
                    *ngIf="
                      sandpList[0] == null && maxDdText == 'Sentiment Rank'
                    "
                    class="std1"
                    style="left: 50%"
                  >
                    Sentiment Rank: 0.0
                  </div>
                  <div
                    *ngIf="
                      sandpList[0] != null && maxDdText == 'Sentiment Rank'
                    "
                    class="std1"
                    style="left: 50%"
                  >
                    Sentiment Rank:
                    {{
                      stock.sentiment_rank != null
                        ? (stock.sentiment_rank + "").slice(0, 5)
                        : "0.0"
                    }}
                  </div>

                  <div
                    *ngIf="stock.qut == null && maxDdText == 'qut'"
                    class="std"
                  >
                    QUT:
                    {{
                      !!stock?.volume_change
                        ? getSocialVolumeChange(stock?.volume_change)
                        : "0.0"
                    }}
                  </div>
                  <ng-container *ngIf="name === 'Value Investing'">
                    <div
                      *ngIf="stock.rev_growth_qtr == null && maxDdText == 'p/e'"
                      class="std"
                    >
                      Rev_growth_qtr : 0.0%
                    </div>
                    <div
                      *ngIf="stock.rev_growth_qtr != null && maxDdText == 'p/e'"
                      class="std"
                    >
                      Rev_growth_qtr:
                      {{ stock.rev_growth_qtr * 100 | number : "1.2-2" }}%
                    </div>
                    <div class="stdLine">|</div>
                    <div
                      *ngIf="
                        stock.debt_equity_ratio == null && maxDdText == 'p/e'
                      "
                      class="std"
                    >
                      Debt/Equity : 0.0%
                    </div>
                    <div
                      *ngIf="
                        stock.debt_equity_ratio != null && maxDdText == 'p/e'
                      "
                      class="std"
                    >
                      Debt/Equity:
                      {{ stock.debt_equity_ratio * 100 | number : "1.2-2" }}%
                    </div>
                    <div class="stdLine">|</div>
                    <div
                      *ngIf="stock.net_margin == null && maxDdText == 'p/e'"
                      class="std"
                    >
                      Net_Margin : 0.0%
                    </div>
                    <div
                      *ngIf="stock.net_margin != null && maxDdText == 'p/e'"
                      class="std"
                    >
                      Net_Margin:
                      {{ stock.net_margin * 100 | number : "1.2-2" }}%
                    </div>
                    <div class="stdLine">|</div>
                    <div
                      *ngIf="
                        (stock.price_earnings == null ||
                          stock.price_earnings == 'nan') &&
                        maxDdText == 'p/e'
                      "
                      class="std"
                    >
                      P/E: 0.0
                    </div>
                    <div
                      *ngIf="
                        stock.price_earnings != null &&
                        stock.price_earnings !== 'nan' &&
                        maxDdText == 'p/e'
                      "
                      class="std"
                    >
                      P/E: {{ stock.price_earnings | number : "1.2-2" }}
                    </div>
                    <div class="stdLine">|</div>
                    <div
                      *ngIf="stock.rating == null && maxDdText == 'p/e'"
                      class="std"
                    >
                      Analyst Rating: 0.0
                    </div>
                    <div
                      *ngIf="stock.rating != null && maxDdText == 'p/e'"
                      class="std"
                    >
                      Analyst Rating: {{ stock.rating }}
                    </div>
                  </ng-container>
                  <div class="stdLine">|</div>

                  <div
                    *ngIf="stock.std == null && stdText == 'std'"
                    class="std"
                  >
                    STD: 0.0
                  </div>
                  <div
                    *ngIf="stock.std != null && stdText == 'std'"
                    class="std"
                  >
                    STD: {{ stock.std }}
                  </div>

                  <div
                    *ngIf="stock.recovery == null && stdText == 'ma50'"
                    class="std"
                  >
                    Recovery: 0.0%
                  </div>
                  <div
                    *ngIf="stock.recovery != null && stdText == 'ma50'"
                    class="std"
                  >
                    Recovery: {{ stock.recovery * 100 | number : "1.2-2" }}%
                  </div>

                  <div
                    *ngIf="stock.roc == null && stdText == 'roc'"
                    class="std"
                  >
                    ROC: 0.0
                  </div>
                  <div
                    *ngIf="stock.roc != null && stdText == 'roc'"
                    class="std"
                  >
                    ROC: {{ stock.roc }}
                  </div>

                  <div
                    *ngIf="
                      stock.volume_change == null && stdText == 'Volume change'
                    "
                    class="std"
                  >
                    Volume change: 0.0
                  </div>
                  <div
                    *ngIf="
                      stock.volume_change != null && stdText == 'Volume change'
                    "
                    class="std"
                  >
                    Volume change:
                    {{
                      !!stock.volume_change
                        ? getSocialVolumeChange(stock.volume_change) + "%"
                        : "0.0"
                    }}
                  </div>
                </div>
                <div *ngIf="name == 'Social Sentiment'" class="all-links">
                  <div
                    class="link-container"
                    *ngFor="let article of stock.fmpnews; let i = index"
                    (click)="$event.stopPropagation()"
                  >
                    <a [href]="article.url" target="_blank" class="link">{{
                      article.title
                    }}</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        *ngIf="innerWidth <= 480"
        style="background-color: #040b21; padding-top: 30px; height: 40vh"
      >
        <div class="capitapPreservationFund">
          <div style="color: #00b7c8">WhoMi</div>
          <div style="color: #00b7c8">{{ name }} fund</div>
          <img
            class="more lineMore"
            style="margin-top: 15px !important; width: 100%"
            src="assets/images/Line_1.png"
            alt=""
          />
          <div style="color: white; text-align: left; margin-left: 4%">
            <span class="returnText">Return:</span>
          </div>
          <img
            class="moreDashed"
            src="assets/images/Blue_Line_2.png"
            alt=""
            style="width: 100%"
          />
          <div class="sharp">
            <span class="returnText">Sharp:</span>
          </div>
        </div>
        <img
          *ngIf="innerWidth > 480"
          class="more"
          style="display: flex; margin: auto"
          src="assets/images/Button_2.png"
          alt=""
        />
        <div *ngIf="innerWidth < 480" class="moreContainer">
          <button class="moreButton" (click)="more()">Want to now more?</button>
        </div>
      </div>
    </div>
    <div *ngIf="innerWidth > 480">
      <div style="width: 100%; display: flex">
        <img
          style="height: 10px; width: 88%; margin: auto"
          src="../../../assets/images/Line_1_1.png"
          alt=""
        />
      </div>

      <div style="width: 100vw; height: 355px; background-color: #040b21">
        <div
          style="
            position: absolute;
            width: 75%;
            right: 0;
            left: 0;
            margin: auto;
          "
        >
          <div style="color: #00b7c8; padding-top: 30px; text-align: center">
            WhoMi - {{ name }} fund
          </div>
          <img
            class="more lineMore"
            style="margin-top: 30px !important; width: 100%"
            src="../../../assets/images/Line_1_1.png"
            alt=""
          />
          <div
            style="
              color: white;
              margin-right: 2%;
              margin-top: 10px;
              text-align: left;
              margin-left: 2%;
            "
          >
            <span class="returnText">Return:</span>
            <span class="returnNumber">{{ fund.Return }}</span>
          </div>
          <img
            src="assets/images/Blue_Line_2_PC.png"
            style="width: 100%"
            alt=""
          />
          <div
            style="
              color: white;
              margin-right: 5%;
              text-align: left;
              margin-left: 2%;
              margin-top: 20px;
            "
          >
            <span class="returnText">Sharp:</span>
            <span class="returnNumber">{{ fund.Sharp }}</span>
          </div>
          <img
            src="assets/images/Blue_Line_2_PC.png"
            style="width: 100%"
            alt=""
          />
          <img
            (click)="more()"
            class="more"
            style="display: flex; margin: auto; cursor: pointer"
            src="assets/images/Want_To_Know_More_Button.png"
            alt=""
          />
        </div>
      </div>
      <img
        class="lineMore"
        style="width: 100%; position: relative !important; height: 10px"
        src="../../../assets/images/Line_1_1.png"
        alt=""
      />
    </div>
  </div>
</div>
