import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AlgoNameRouting } from 'src/app/models/stockType';
import { topDataService } from 'src/app/services/topData.service';

@Component({
  selector: 'app-popup-type',
  templateUrl: './popup-type.component.html',
  styleUrls: ['./popup-type.component.css'],
})
export class PopupTypeComponent implements OnInit {
  showProgress = true;
  name: string;
  description: string;
  img: string;
  text: string;
  type: string;
  interval;

  constructor(
    private matDialog: MatDialog,
    private router: Router,
    private topDataSerivce: topDataService
  ) {}

  ngOnInit(): void {
    this.showProgress = true;
    this.description = this.topDataSerivce.getDescription();
    this.name = this.topDataSerivce.getName();
    this.type = this.topDataSerivce.getType();
    if (this.type == '0') this.img = 'assets/images/Home_Icon_11.png';
    else if (this.type == '1') this.img = 'assets/images/Home_Icon_22.png';
    else if (this.type == '2') this.img = 'assets/images/Home_Icon_33.png';
    else if (this.type == '3') this.img = 'assets/images/Home_Icon_44.png';
    else if (this.type == '4') this.img = 'assets/images/Home_Icon_54.png';
    this.timer();
  }

  timer() {
    this.interval = setInterval(() => {
      this.showProgress = false;
    }, 550);
  }

  close() {
    this.matDialog.closeAll();
    sessionStorage.isQuestion = false;
  }

  next() {
    const algoRoutes = {
      'Capital Preservation': AlgoNameRouting.CAPITAL,
      Momentum: AlgoNameRouting.MOMENTUM,
      'Buy the Dip': AlgoNameRouting.BDD,
      'Social Sentiment': AlgoNameRouting.SOCIAL,
      'Value Investing': AlgoNameRouting.VALUE,
    };
    this.matDialog.closeAll();
    this.router.navigate(['../top', algoRoutes[this.name]]);
  }
}
