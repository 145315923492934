import { GridPagingMode } from 'igniteui-angular';

export class StockType {
  id: number = 0;
  name: string = '';
  description: string = '';
  select: boolean;
  createdAt: string = '';
  updatedAt: string = '';
  image: string = '';
}

export class Height {
  [key: string]: number;
}

export enum StockTypeEnum {
  CAPITAL_PRESERVATION,
  MOMENTUM,
  BUY_THE_DIP,
  SOCIAL_SENTIMENT,
  VALUE_INVESTING,
}

export const timeFramesArrays = {
  value: ['1D', '6M', '9M', '12M'],
  otherAlgos: ['1D', '1W', '1M', '3M', '6M', '12M']
}

export const AlgoNameRouting = {
  CAPITAL: 'capital',
  MOMENTUM: 'momentum',
  BDD: 'bdd',
  SOCIAL: 'social',
  VALUE: 'value',
};

export const AlgorithmsDescriptions = {
  CAPITAL: 'Sleep better at night',
  MOMENTUM: 'Winning horses keep on winning',
  BDD: 'Number 1 rule in investing is buy cheap',
  SOCIAL: 'Invest in the big next thing everyone is talking about',
  VALUE: "Don't gamble. Make knowledgeable decisions",
};

export class Date {
  id: number;
  date: string;
  selected: boolean;
}

export class Top {
  dailyStock: TopList;
  weeklyStock: TopList;
  oneMonthStock: TopList;
  threeMonthStock: TopList;
  sixMonthStock: TopList;
  twelveMonthStock: TopList;
  quarterlyStock: TopList;
  yearlyStock: TopList;
  halfyearlyStock: TopList;
}

export class Top2 {
  dailyStock: TopList2;
  weeklyStock: TopList2;
  oneMonthStock: TopList2;
  threeMonthStock: TopList2;
  sixMonthStock: TopList2;
  twelveMonthStock: TopList2;
}

export class TopList {
  data: TopItem[];
  sandp: TopItem[];
}

export class TopList2 {
  data: TopItem2[];
  sandp: TopItem2[];
}

export class TopItem2 {
  id: number;
  symbol: string;
  stocktype: string;
  date: string;
  days: string;
  price: string;
  ret: string;
  ma50: string;
  ma20: string;
}

export class TopItem {
  id: number;
  symbol: string;
  stocktype: string;
  date: string;
  days: string;
  price: string;
  symbolName: symbolName[];
  tickerData: symbolName[];
  ret: string;
  max_dd: string;
  std: string;
  ma50: string;
  ma20: string;
  cci: string;
  roc: string;
  pos: string;
  recovery: string;
  buythedip: TopItem2[];
  lookback?: string;
  rev_growth_qtr: any;
  debt_equity_ratio: number;
  net_margin: number;
  price_earnings: any;
  rating: any;
  volume_change?: number | string;
  qut?: string;
  sentiment_rank?: string;
}

export class symbolName {
  id: number;
  class: string;
  market_cap: string;
  name: string;
  ticker: string;
  sector: string;
}

export class sAndp {
  // ret:number;
  // ma50: number;
  // ma20: number;

  sAndPs: string;
  // RET: string;
  MAX_DD: string;
  STD: string;
  // sAndP:parameters[];
}

export class Fund {
  Return: string = '';
  Sharp: string = '';
}

export class SAndP {
  STD: string;
  NSI: string;
}

export class graphItem {
  value: number = 0;
  STD: string = '';
  MAX_DD: string = '';
  SAndP: sAndp;
}

export class graphItem2 {
  value: number = 0;
  Mom: string = '';
  NSI: string = '';
  SAndP: sAndp;
}

export class graphHeader {
  buying_the_dip: graph;
  conservative: graph;
  momentums: graph;
}

export class graph2 {
  header: string;
  date: string;
  price: string;
  decile: number;
  symbol: string;
  stocktype: string;
  max_dd: string;
  std: string;
  ma20: string;
  ma50: string;
  cci: string;
  roc: string;
  spy_decile: number;
  spy_max_dd: string;
  spy_std: string;
  spy_cci: string;
  spy_roc: string;
  spy_ma20: string;
  spy_ma50: string;
}

export class graph {
  header: string = '';
  date: string = '';
  price: string = '';
  decile: string = '0';
  symbol: string = '';
  stocktype: string = '';
  max_dd: string = '';
  std: string = '';
  ma20: string = '';
  ma50: string = '';
  cci: string = '';
  roc: string = '';
  spy_decile: string = '0';
  spy_max_dd: string = '';
  spy_std: string = '';
  spy_cci: string = '';
  spy_roc: string = '';
  spy_ma20: string = '';
  spy_ma50: string = '';
  recovery: string = '';
  spy_recovery: string = '';
  rev_growth_qtr?: string;
  debt_equity_ratio?: string;
  net_margin?: string;
  price_earnings?: string;
  rating?: string;
  sentiment_rank?: string;
  qut?: string;
  spy_sentiment_rank?: string;
  spy_volume_change?: string | number;
  volume_change?: string | number;
}

export class Graph {
  header: string;
  date: string;
  price: string;
  decile: number;
  symbol: string;
  stocktype: string;
  max_dd: string;
  std: string;
  spy_decile: number;
}

export class parameters {
  time: number;
  value: number;
}

export class parameter {
  days: string;
  symbol: string;
  stockType: string;
}

export class search {
  search: string;
}

export class parametersTime {
  parameters: parameters[];
  price: string;
  ret: string;
  symbol: string;
}

export class CountryRenewableElectricityItem {
  public constructor(init: Partial<CountryRenewableElectricityItem>) {
    Object.assign(this, init);
  }

  public year: string;
  public europe: number;
  public china: number;
  public america: number;
}
export class CountryRenewableElectricity extends Array<CountryRenewableElectricityItem> {
  public constructor() {
    super();
    this.push(
      new CountryRenewableElectricityItem({
        year: `2009`,
        europe: 34,
        china: 21,
        america: 19,
      })
    );
    this.push(
      new CountryRenewableElectricityItem({
        year: `2010`,
        europe: 43,
        china: 26,
        america: 24,
      })
    );
    this.push(
      new CountryRenewableElectricityItem({
        year: `2011`,
        europe: 66,
        china: 29,
        america: 28,
      })
    );
    this.push(
      new CountryRenewableElectricityItem({
        year: `2012`,
        europe: 69,
        china: 32,
        america: 26,
      })
    );
    this.push(
      new CountryRenewableElectricityItem({
        year: `2013`,
        europe: 58,
        china: 47,
        america: 38,
      })
    );
    this.push(
      new CountryRenewableElectricityItem({
        year: `2014`,
        europe: 40,
        china: 46,
        america: 31,
      })
    );
    this.push(
      new CountryRenewableElectricityItem({
        year: `2015`,
        europe: 78,
        china: 50,
        america: 19,
      })
    );
    this.push(
      new CountryRenewableElectricityItem({
        year: `2016`,
        europe: 13,
        china: 90,
        america: 52,
      })
    );
    this.push(
      new CountryRenewableElectricityItem({
        year: `2017`,
        europe: 78,
        china: 132,
        america: 50,
      })
    );
    this.push(
      new CountryRenewableElectricityItem({
        year: `2018`,
        europe: 40,
        china: 134,
        america: 34,
      })
    );
    this.push(
      new CountryRenewableElectricityItem({
        year: `2018`,
        europe: 40,
        china: 134,
        america: 34,
      })
    );
    this.push(
      new CountryRenewableElectricityItem({
        year: `2019`,
        europe: 80,
        china: 96,
        america: 38,
      })
    );
  }
}
