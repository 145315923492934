import {
  Component,
  EventEmitter,
  HostListener,
  OnInit,
  Output,
} from '@angular/core';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.css'],
})
export class InputComponent implements OnInit {
  innerWidth: number;
  value: string = '';
  @Output() inputChange = new EventEmitter<string>();

  constructor() {}

  ngOnInit(): void {
    this.innerWidth = window.innerWidth;
  }

  onInput(value: string) {
    this.value = value;
    this.inputChange.emit(value);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.innerWidth = window.innerWidth;
  }

  isMobile() {
    return this.innerWidth <= 390;
  }
}
