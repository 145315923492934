<div class="topBar" (click)="handleMenu(false)">
  <div
    class="imageWebContainer"
    *ngIf="innerWidth > 480"
    (click)="handleMenu(false)"
  >
    <img (click)="main()" src="../../../assets/images/Small_Logo_2_1.png" />
  </div>
  <div
    class="imageContainer"
    (click)="handleMenu(false)"
    *ngIf="innerWidth < 480"
  >
    <img
      (click)="main()"
      src="../../../assets/images/Small_Logo_2_1.png"
      class="logoImg"
      alt=""
    />
  </div>
  <div class="searchAndLogo" [ngClass]="innerWidth > 480 ? 'rightLogo' : ''">
    <div
      *ngIf="isLoggedIn && isApproved()"
      class="searchGroup"
      [ngStyle]="
        searchBorderColor
          ? { 'border-color': '#00caa7' }
          : { 'border-color': '#818590' }
      "
    >
      <div class="searchIconContainer">
        <img
          class="searchImg"
          src="../../../assets/images/search-small.png"
          alt=""
        />
      </div>

      <app-input (inputChange)="onInputChange()"></app-input>
      <div
        *ngIf="inputValue.length"
        id="searchContainer"
        class="searchResultContainer"
        [ngStyle]="{
          display:
            inputValue.length && searchResult.length === 0 ? 'none' : 'block'
        }"
      >
        <div
          *ngFor="let stock of searchResult; let i = index"
          class="searchLinkContainer"
        >
          <a class="searchLink" href="inner/{{ stock.ticker }}">
            <a class="mainLink" href="inner/{{ stock.ticker }}">{{
              stock.ticker
            }}</a>
            <div class="stocknameContainer">
              <p class="stockName">
                {{
                  stock.name.length < 15
                    ? stock.name
                    : stock.name.slice(0, 21) + "..."
                }}
              </p>
            </div>
          </a>
        </div>
      </div>
      <div
        *ngIf="searchBorderColor"
        class="closeButton"
        (click)="closeSearch()"
      >
        <img
          class="leftSideOfClose"
          src="../../../assets/images/closeSearchBtn.png"
        />
        <img src="../../../assets/images/closeSearchBtn2.png" />
      </div>
    </div>
    <div class="profile">
      <div
        class="accountLineContainer"
        (click)="handleMenu(!settings); $event.stopPropagation()"
      >
        <div class="accountLineSmallContainer">
          <div class="logoImageContainer">
            <img class="userImage" src="../../../assets/images/userLogo.png" />
          </div>
          <span class="accountSpan">Account</span>
          <div class="accountArrow">
            <img src="../../../assets/images/accountArrow.png" />
          </div>
        </div>
      </div>
      <div class="options" *ngIf="settings">
        <div class="option" (click)="onAdmin()" *ngIf="role === 'Admin' && isApproved()">
          <div class="iconAndName">
            <div class="settingsContainer">
              <img src="../../../assets/images/Admin-Panel-Icon.png" />
            </div>
            <span> Admin Panel</span>
          </div>
        </div>
        <div class="option" (click)="onSettings()" *ngIf="token && isApproved()">
          <div class="iconAndName">
            <div class="settingsContainer">
              <img src="../../../assets/images/Settings.png" />
            </div>
            <span>Settings</span>
          </div>
        </div>
        <div class="option" (click)="onDeleteAccount()" *ngIf="token && isApproved()">
          <div class="iconAndName">
            <div class="bucketContainer">
              <img src="../../../assets/images/Bucket.png" />
            </div>
            <span>Delete Account</span>
          </div>
        </div>
        <div class="option" (click)="logout()">
          <div class="iconAndName">
            <div class="logoutBtnContainer">
              <img src="../../../assets/images/LogoutBtn.png" />
            </div>
            <span class="logoutSpan">{{ token && isApproved() ? "Log Out" : "Log in" }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
