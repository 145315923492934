import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AlgoNameRouting } from 'src/app/models/stockType';
import { DataService } from 'src/app/services/data.service';
import { topDataService } from 'src/app/services/topData.service';


@Component({
  selector: 'app-group-type',
  templateUrl: './group-type.component.html',
  styleUrls: ['./group-type.component.css']
})
export class GroupTypeComponent implements OnInit {
  @Output() showCharacter: EventEmitter<number> = new EventEmitter<number>();
  name:string="Capital Preservation";
  img="__Popup_Conservative_PC.png"

  constructor(private dataService : DataService ,private matDialog: MatDialog, private router: Router, private topDateService: topDataService) { }

  ngOnInit(): void {
    this.dataService.character.next(true);
    this.showCharacter.emit();
    this.name= this.topDateService.getName();
 
if(this.name=='Momentum')
{
this.img= "__Popup_Momentum_PC.png"
}
else if(this.name=='Buy the Dip')
{
this.img="__Popup_Buy_The_Dip_PC.png"
}
else if(this.name=='Social Sentiment')
{
this.img="__Popup_Social_Sentiment_PC.png"
}
else if(this.name=='Value Investing')
{
this.img="__Popup_Value_Investment_PC.png"
}
  }

  next()
  {
    const algoRoutes = {
      'Capital Preservation': AlgoNameRouting.CAPITAL,
      Momentum: AlgoNameRouting.MOMENTUM,
      'Buy the Dip': AlgoNameRouting.BDD,
      'Social Sentiment': AlgoNameRouting.SOCIAL,
      'Value Investing': AlgoNameRouting.VALUE,
    };

    const algoName = this.topDateService.getName();
    algoName
      ? this.router.navigate(['../top', algoRoutes[algoName]],{
        queryParams: { period: '1D' },
      })
      : this.router.navigate(['../']);
  };

  close()
  {
    window.history.back();
  }
}

