export class ContectUser {
  name: string;
  email: string;
  phoneNumber: string;
  address: string;
}

export class user {
  id?: number;
  name?: string;
  email?: string;
  address?: string;
  phoneNumber?: string;
  createdAt?: string;
  updatedAt?: string;
  role?: string;
  active?: boolean;
  status?: string;
}

export class search {
  search: string = '1';
}

export enum UserStatus {
  PENDING = 'Pending',
  APPROVED = 'Approved',
  REJECTED = 'Rejected',
}
