import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ChangeDetectionStrategy,
  Input,
  ViewChild,
  Renderer2,
  ElementRef,
  ChangeDetectorRef,
  AfterViewInit,
  OnDestroy,
} from '@angular/core';
import {
  AlgoNameRouting,
  graph,
  graphItem,
  graphItem2,
  parameter,
  parametersTime,
  StockType,
  Top,
  TopItem,
  Date,
  Height,
} from 'src/app/models/stockType';
import { AppProxy } from 'src/app/services/app-proxy';
import { StocksService } from 'src/app/services/stocks-service';
import { DataService } from 'src/app/services/data.service';
import Chart from 'chart.js/auto';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { getDataFromAlpha } from '../../services/stock-api.service';
import { innerDataService } from 'src/app/services/innerData.service';
import { topDataService } from 'src/app/services/topData.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { makePercent } from 'src/app/helpers';

declare global {
  interface Window {
    TradingView?: any;
  }
}
@Component({
  selector: 'app-inner',
  templateUrl: './inner.component.html',
  styleUrls: ['./inner.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InnerComponent implements OnInit, AfterViewInit, OnDestroy {
  math = Math;
  chart = [];
  @Output() showCharacter: EventEmitter<number> = new EventEmitter<number>();
  name;
  stockType;
  sAndP;
  RET;
  days;
  price: string;
  ret = '10%';
  num = 2;
  innerHeight: any;
  innerWidth: any;
  isFilterChoosingShown: boolean;
  typeList: StockType[] = [];
  dateList: Date[] = [];
  stockList: StockType[] = [];
  numberList: number[] = [3, 6, 4, 7, 1];
  defaultHeaders: string[] = [
    'Capital Preservation',
    'Momentum',
    'Buy The Dip',
    'Soclial Sentiment',
    'Value Investing',
  ];

  heights: Height = {
    capitalStockGraph: 30,
    momentumStockGraph: 30,
    bddStockGraph: 30,
    socialStockGraph: 30,
    valueStockGraph: 30,
    capitalSPYGraph: 30,
    momentumSPYGraph: 30,
    bddSPYGraph: 30,
    socialSPYGraph: 30,
    valueSPYGraph: 30,
  };

  height = 42;
  margintop1 = 0;
  margintop2 = 0;
  margintop3 = 0;
  margintop4 = 0;
  margintop5 = 0;
  marginTop = 478;
  stock: TopItem;
  data: any;
  options: any;
  spy_cci: string;
  spy_roc: string;
  cci: string;
  roc: string;
  ma20: string;
  ma50: string;
  spy_ma50: string;
  spy_ma20: string;
  graphList: graph = null;
  graphList1: graph = null;
  graphList2: graph = null;
  graphList3: graph = null;
  graphList4: graph = null;
  graphList5: graph = null;
  graphListAll: graph[];
  graph: graph = new graph();
  std: string;
  std1: string;
  std2: string;
  std3: string;
  std4: string;
  std5: string;
  max_dd: string;
  max_dd1: string;
  max_dd2: string;
  max_dd3: string;
  max_dd4: string;

  spy_max_dd: string;
  spy_max_dd1: string;
  spy_max_dd2: string;
  spy_max_dd3: string;
  spy_max_dd4: string;

  spy_std: string;
  spy_std1: string;
  spy_std2: string;
  spy_std3: string;
  spy_std4: string;

  value: string = '';
  value1: string = '';
  value2: string;
  value3: string;
  value4: string;

  spyValue: string;
  spyValue1: string;
  spyValue2: string;
  spyValue3: string;
  spyValue4: string;

  disabled: boolean = false;
  period: string = '';
  symbolError: string = "This symbol data doesn't exists";
  Rev_growth_qtr: string;
  Debt_equality: string;
  Net_Margin: string;
  PE: string;
  Analyst_Rating: string;
  descriptionResult: any;
  Conservative_MAX_DD: string;
  Conservative_STD: string;
  Conservative_value: number;
  Conservative: graphItem;
  Momentum: graphItem;
  Buy_the_Dip: graphItem2;
  Social_Sentiment: graphItem;
  Value_Investings: graphItem;
  sector: string;
  market_cap: string;
  security_type: string;
  width75 = 75;
  parametersTime: parametersTime;
  top: Top;
  chartTimeout: any;
  ctx;
  verticalBarOptions = {
    showXAxis: true,
    showYAxis: true,
    gradient: false,
    showLegend: true,
    showGridLines: true,
    barPadding: 50,
    showXAxisLabel: false,
    xAxisLabel: 'Country',
    showYAxisLabel: true,
    yAxisLabel: 'Sales',
  };
  showProgress = false;
  parameter: parameter;
  myChart: any;
  @Input() symbol;
  @ViewChild('tradingview') tradingview?: ElementRef;
  ws;
  wsMessage =
    'you may need to send specific message to subscribe data, eg: BTC';
  granularityMap = {
    '1': 60,
    '3': 180,
    '5': 300,
    '30': 30 * 60,
    '60': 60 * 60,
    '120': 60 * 60 * 2,
    '240': 60 * 60 * 4,
    '360': 60 * 60 * 6,
    D: 86400,
  };
  url: string = 'https://www.tradingview.com/symbols/NASDAQ-AAPL/';
  // declare const TradingView: any;

  html: SafeHtml;
  KisshtHtml;
  AlgoName: string;

  constructor(
    private http: HttpClient,
    private sanitizer: DomSanitizer,
    private _renderer2: Renderer2,
    private dataService: DataService,
    private topDataService: topDataService,
    private innerDataService: innerDataService,
    private proxy: AppProxy,
    private stocksService: StocksService,
    private cd: ChangeDetectorRef,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    const params = this.activatedRoute.snapshot.queryParams;
    this.period = params.period;
    this.AlgoName = params.algo;
    this.loadScript(() => {
      this.symbol = this.innerDataService.getStockType().split('?')[0];

      if (window.TradingView) {
        /** Chart - 2 */
        this.chartTimeout = setTimeout(() => {
          new window.TradingView.widget({
            autosize: true,
            symbol: [`${this.symbol}`],
            interval: '1',
            timezone: 'Etc/UTC',
            theme: 'dark',
            chart: 'line',
            style: '3',
            locale: 'en',
            toolbar_bg: '#f1f3f6',
            enable_publishing: false,
            allow_symbol_change: true,
            container_id: 'tradingview_8aaad',
            hideDateRanges: false,
            withdateranges: true,
            hide_side_toolbar: true,
            save_image: false,
            hideideas: true,
            studies: ['MASimple@tv-basicstudies'],
            show_popup_button: true,
            popup_width: '1000',
            popup_height: '650',
            full_screen: false,
          });
        }, 500);
      }
    });
  }

  async setAlphaData(db_market_cap, db_sector): Promise<void> {
    const descriptionContent = document.getElementById('description-content');

    if (!this.symbol) {
      this.symbol = this.innerDataService.getStockType();
    }
    const dataFromAlpha = await getDataFromAlpha(this.symbol);
    await this.stocksService
      .getDataFromYahoo(this.symbol)
      .subscribe((yahhoData) => {
        if (descriptionContent.innerHTML === '') {
          if (
            yahhoData.description &&
            Object.keys(yahhoData.description).length !== 0
          ) {
            descriptionContent.innerHTML = yahhoData.description;
          } else if (dataFromAlpha.description) {
            descriptionContent.innerHTML = dataFromAlpha.description;
          } else {
            descriptionContent.innerHTML = yahhoData.description;
          }
        }
        if (!this.sector) {
          if (yahhoData.sector && Object.keys(yahhoData.sector).length !== 0) {
            this.sector = yahhoData.sector;
          } else if (dataFromAlpha.sector) {
            this.sector = dataFromAlpha.sector;
          } else {
            this.sector =
              db_sector && db_sector !== 'NaN' && db_sector !== 'nan'
                ? db_sector
                : '-';
          }
        }
        if (!this.market_cap) {
          if (
            yahhoData.market_cap &&
            Object.keys(yahhoData.market_cap).length !== 0
          ) {
            if (yahhoData?.market_cap['raw']) {
              this.market_cap = this.formatNumberInMillions(
                yahhoData.market_cap['raw']
              );
            }
          } else if (+dataFromAlpha.market_cap) {
            this.market_cap = this.formatNumberInMillions(
              dataFromAlpha.market_cap
            );
          } else {
            this.market_cap = this.formatNumberInMillions(db_market_cap);
          }
        }
      });
  }

  setDefaults() {
    this.heights = {
      capitalStockGraph: 30,
      capitalSPYGraph: 30,
      momentumStockGraph: 30,
      momentumSPYGraph: 30,
      bddStockGraph: 30,
      bddSPYGraph: 30,
      socialSPYGraph: 30,
      socialStockGraph: 30,
      valueStockGraph: 30,
      valueSPYGraph: 30,
    };
  }

  ngOnDestroy(): void {
    clearTimeout(this.chartTimeout);
  }

  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.innerDataService.currentProperty.subscribe((propertyName) => {
      if (propertyName === 'stockType') {
        this.cd.detectChanges();
      }
    });

    this.activatedRoute.queryParams.subscribe((params: Params) => {
      if (this.period !== params['period'] && this.period !== '') {
        this.period = params['period'];
        this.AlgoName = params['algo'] ? params['algo'] : '';
        if (!this.period) {
          this.period = '1D';
        }

        this.parameter.days = this.period;
        this.graphListAll = [];
        this.drawDecile();
      }
      this.cd.markForCheck();
    });

    this.graphListAll = [];
    this.security_type = this.innerDataService.getClass();
    this.showProgress = true;

    if (this.security_type && this.security_type !== 'undefined') {
      this.AlgoName = !this.AlgoName
        ? this.topDataService.getName()
        : this.AlgoName;
      this.name = this.innerDataService.getStockName();
      this.stockType = this.innerDataService.getStockType().split('?')[0];
      this.RET = this.innerDataService.getStockRet();
      this.price = this.innerDataService.getPrice();
      this.days = this.innerDataService.getDays();
      this.setAlphaData(
        this.innerDataService.getMarket_Cap(),
        this.innerDataService.getSector()
      );
    } else {
      this.stockType = this.innerDataService.getStockType().split('?')[0];
      this.days = '1';
      this.AlgoName = !this.AlgoName
        ? this.topDataService.getName()
        : this.AlgoName;
      this.stocksService
        .getParameters(this.stockType)
        .subscribe(async (res: any) => {
          this.RET = res[0].ret;
          if (this.symbol === 'META') {
            this.RET = parseFloat(res[0].ret).toFixed(2) + '%';
          }
          this.price = (+res[0].price).toFixed(2).toString();
          this.security_type = res[0].tickerData[0].class;
          this.name = res[0].tickerData[0].name;
          this.setAlphaData(
            res[0].tickerData[0].market_cap,
            res[0].tickerData[0].sector
          );
        });
    }

    if (this.AlgoName === 'value') {
      this.dateList = [
        {
          id: 1,
          date: '1D',
          selected: true,
        },
        {
          id: 2,
          date: '6M',
          selected: false,
        },
        {
          id: 3,
          date: '9M',
          selected: false,
        },
        {
          id: 4,
          date: '12M',
          selected: false,
        },
      ];
    } else {
      this.dateList = [
        {
          id: 1,
          date: '1D',
          selected: true,
        },
        {
          id: 2,
          date: '1W',
          selected: false,
        },
        {
          id: 3,
          date: '1M',
          selected: false,
        },
        {
          id: 4,
          date: '3M',
          selected: false,
        },
        {
          id: 5,
          date: '6M',
          selected: false,
        },
        {
          id: 6,
          date: '12M',
          selected: false,
        },
      ];
    }

    this.showProgress = false;
    this.innerHeight = window.innerHeight;
    this.innerWidth = window.innerWidth;

    if (this.innerWidth <= 480) {
      this.height = 18;
      this.marginTop = 205;
    } else {
      this.height = 42;
      this.marginTop = 478;
    }

    this.parameter = new parameter();
    this.parameter.stockType = this.stockType;
    this.parameter.symbol = this.stockType;
    if (!this.period) {
      this.parameter.days = '1D'; // this.days;
    } else {
      this.parameter.days = this.period;
    }

    this.drawDecile();
    this.cd.detectChanges();
  }

  drawDecile() {
    this.setDefaults();
    if (!this.period) this.period = '1D';
    this.capitalPreservationGraph();
    this.momentumGraph();
    this.buyTheDipGraph();
    this.socialSentimentGraph();
    this.valueInvestingGraph();
  }

  capitalPreservationGraph() {
    this.parameter.stockType = '1';
    this.stocksService.getGraph(this.parameter).subscribe((res: any) => {
      if (res != null && res != this.symbolError) {
        this.graphList = res;
        this.graphListAll[0] = new graph();
        this.graphListAll[0] = this.graphList;
        this.graphListAll[0].header = 'Capital Preservation';
        this.graph = this.graphList;
        this.max_dd = makePercent(Number(this.graphList.max_dd).toFixed(2));
        this.spy_std = this.graphList.spy_std;
        this.spy_max_dd = makePercent(
          Number(this.graphList.spy_max_dd).toFixed(2)
        );
        this.std = this.graphList.std;
        this.value = this.graphList.decile + '';
        this.spyValue = this.graphList.spy_decile + '';
        this.heights.capitalStockGraph += Number(
          this.height * Number(this.value)
        );
        this.heights.capitalSPYGraph +=
          Number(this.height * Number(this.graphList.spy_decile)) || 0;
        if (Number(this.graphList.decile) > Number(this.graphList.spy_decile)) {
          this.margintop1 = Number(
            this.marginTop - Number(this.heights.capitalStockGraph)
          );
        } else {
          this.margintop1 = Number(
            this.marginTop - Number(this.heights.capitalSPYGraph) - 30
          );
        }
        this.heights.capitalStockGraph = this.heights.capitalStockGraph;
        this.heights.capitalSPYGraph = this.heights.capitalSPYGraph;
      } else {
        this.graphListAll[0] = new graph();
        this.graphListAll[0].header = 'Capital Preservation';
        this.heights.capitalSPYGraph = this.heights.capitalStockGraph = 30;
        this.margintop1 = Number(
          this.marginTop - Number(this.heights.capitalStockGraph)
        );
        this.spyValue = this.value = '0';
      }
      this.cd.markForCheck();
      this.dataService.character.next(false);
      this.showCharacter.emit();
    });
  }

  momentumGraph() {
    this.parameter.stockType = '2';
    this.stocksService.getGraph(this.parameter).subscribe((res: any) => {
      if (res != null && res != this.symbolError) {
        this.graphList1 = res;
        this.graphListAll[1] = this.graphList1;
        this.graphListAll[1].header = 'Momentum';
        this.cci = this.graphList1.cci;
        this.roc = this.graphList1.roc;
        this.spy_cci = this.graphList1.spy_cci;
        this.spy_roc = this.graphList1.spy_roc;
        this.value1 = this.graphList1.decile + '';
        this.spyValue1 = this.graphList1.spy_decile + '';
        this.heights.momentumStockGraph += Number(
          this.height * Number(this.graphList1.decile)
        );
        this.heights.momentumSPYGraph += Number(
          this.height * Number(this.graphList1.spy_decile)
        );
        if (Number(this.graphList1.decile) > Number(this.graphList1.spy_decile))
          this.margintop2 = Number(
            this.marginTop - Number(this.heights.momentumStockGraph)
          );
        else
          this.margintop2 = Number(
            this.marginTop - Number(this.heights.momentumSPYGraph)
          );
        this.heights.momentumStockGraph = this.heights.momentumStockGraph;
        this.heights.momentumSPYGraph = this.heights.momentumSPYGraph;
      } else if (!res) {
        this.graphListAll[1] = new graph();
        this.graphListAll[1].header = 'Momentum';
      } else {
        this.graphListAll[1] = new graph();
        this.graphListAll[1].header = 'Momentum';
        this.heights.momentumSPYGraph = this.heights.momentumStockGraph = 30;
        this.margintop2 = Number(
          this.marginTop - Number(this.heights.momentumStockGraph)
        );
        this.spyValue1 = this.value1 = '0';
      }
      this.cd.markForCheck();
      this.dataService.character.next(false);
      this.showCharacter.emit();
    });
  }

  buyTheDipGraph() {
    this.parameter.stockType = '3';
    this.stocksService.getGraph(this.parameter).subscribe((res: any) => {
      this.showProgress = false;
      if (res != null && res != this.symbolError) {
        this.graphList2 = res;
        this.graphListAll[2] = this.graphList2;
        this.graphListAll[2].header = 'Buy The Dip';
        this.graphList2.max_dd = makePercent(
          String(((+this.graphList2?.max_dd || 0) * 100)?.toFixed(2))
        );
        this.graphList2.recovery = makePercent(
          String(((+this.graphList2?.recovery || 0) * 100)?.toFixed(2))
        );
        this.graphList2.spy_max_dd = makePercent(
          String(((+this.graphList2?.max_dd || 0) * 100)?.toFixed(2))
        );
        this.graphList2.spy_recovery = makePercent(
          String(((+this.graphList2?.spy_recovery || 0) * 100)?.toFixed(2))
        );
        this.ma20 = this.graphList2.max_dd;
        this.ma50 = this.graphList2.recovery;
        this.spy_ma20 = this.graphList2.spy_ma20;
        this.spy_ma50 = this.graphList2.spy_ma50;
        this.std2 = this.graphList2.std;
        this.value2 = this.graphList2.decile;
        this.spyValue2 = this.graphList2.spy_decile + '';
        this.heights.bddStockGraph += Number(
          this.height * Number(this.graphList2.decile)
        );
        this.heights.bddSPYGraph +=
          Number(this.height * Number(this.graphList2.spy_decile)) || 0;
        if (Number(this.graphList2.decile) > Number(this.graphList2.spy_decile))
          this.margintop3 = Number(
            this.marginTop - Number(this.heights.bddStockGraph)
          );
        else
          this.margintop3 = Number(
            this.marginTop - Number(this.heights.bddSPYGraph)
          );
      } else if (!res) {
        this.graphListAll[2] = new graph();
        this.graphListAll[2].header = 'Buy The Dip';
        this.margintop3 = Number(this.marginTop);
      } else {
        this.graphListAll[2] = new graph();
        this.graphListAll[2].header = 'Buy The Dip';
        this.heights.bddSPYGraph = this.heights.bddStockGraph = 30;
        this.margintop3 = Number(
          this.marginTop - Number(this.heights.bddStockGraph)
        );
        this.spyValue2 = this.value2 = '0';
      }
      this.cd.markForCheck();
      this.dataService.character.next(false);
      this.showCharacter.emit();
    });
  }

  socialSentimentGraph() {
    this.parameter.stockType = '4';
    this.stocksService.getGraph(this.parameter).subscribe((res: any) => {
      this.showProgress = false;
      if (res != null && res != this.symbolError) {
        this.graphList3 = res;

        this.graphListAll[3] = this.graphList3;
        this.graphListAll[3].header = 'Social Sentiment';
        this.max_dd2 = this.graphList3.ma20;
        this.std3 = this.graphList3.ma50;
        this.value3 = this.graphList3.decile + '';
        this.spyValue3 = this.graphList3.spy_decile + '';
        this.heights.socialStockGraph += Number(
          this.height * Number(this.graphList3.decile)
        );
        this.heights.socialSPYGraph += Number(
          this.height * Number(this.graphList3.spy_decile)
        );
        if (Number(this.graphList3.decile) > Number(this.graphList3.spy_decile))
          this.margintop4 = Number(
            this.marginTop - Number(this.heights.socialStockGraph)
          );
        else
          this.margintop4 = Number(
            this.marginTop - Number(this.heights.socialSPYGraph)
          );
        this.heights.socialStockGraph = this.heights.socialStockGraph;
        this.heights.socialSPYGraph = this.heights.socialSPYGraph;
        if (isNaN(this.heights.socialStockGraph)) {
          this.heights.socialStockGraph = 30;
          this.value3 = '0';
        }
        if (isNaN(this.heights.socialSPYGraph)) {
          this.heights.socialSPYGraph = 30;
          this.spyValue3 = '0';
        }

        if (this.value3 == '0' && this.innerWidth <= 480)
          this.margintop4 = Number(this.margintop4) + 56;
      } else if (!res) {
        this.graphListAll[3] = new graph();
        this.graphListAll[3].header = 'Social Sentiment';
      } else {
        this.graphListAll[3] = new graph();
        this.graphListAll[3].header = 'Buy The Dip';
        this.heights.socialSPYGraph = this.heights.socialStockGraph = 30;
        this.margintop4 = Number(
          this.marginTop - Number(this.heights.socialStockGraph)
        );
        this.spyValue3 = this.value3 = '0';
      }
      this.cd.markForCheck();
      this.dataService.character.next(false);
      this.showCharacter.emit();
    });
  }

  valueInvestingGraph() {
    this.parameter.stockType = '5';
    this.stocksService.getGraph(this.parameter).subscribe((res: any) => {
      this.showProgress = false;
      if (res != null && res != this.symbolError) {
        this.graphList5 = res;
        this.max_dd2 = makePercent(this.graphList5.ma20);
        this.std5 = this.graphList5.ma50;
        const toStrValue4 = +this.graphList5.decile;
        this.value4 = toStrValue4.toString();
        this.spyValue4 = this.graphList5.spy_decile + '';
        const checkedRev_growth_qtr = isNaN(+this.graphList5.rev_growth_qtr)
          ? 0
          : +this.graphList5.rev_growth_qtr * 100;
        this.Rev_growth_qtr = makePercent(checkedRev_growth_qtr.toFixed(2));
        const checkedDebt_equality = isNaN(+this.graphList5.debt_equity_ratio)
          ? 0
          : +this.graphList5.debt_equity_ratio * 100;
        this.Debt_equality = makePercent(checkedDebt_equality.toFixed(2));
        const checkedNet_Margin = isNaN(+this.graphList5.net_margin)
          ? 0
          : +this.graphList5.net_margin * 100;
        this.Net_Margin = makePercent(checkedNet_Margin.toFixed(2));
        const checkedPE = isNaN(+this.graphList5.price_earnings)
          ? 0
          : +this.graphList5.price_earnings;
        this.PE = checkedPE.toFixed(2);
        const checkedAnalyst_Rating = isNaN(+this.graphList5.rating)
          ? 0
          : +this.graphList5.rating;
        this.Analyst_Rating = checkedAnalyst_Rating + '.0';

        this.heights.valueStockGraph += Number(
          this.height * Number(this.graphList5.decile)
        );
        this.heights.valueSPYGraph += Number(
          this.height * Number(this.graphList5.spy_decile)
        );

        if (Number(this.graphList5.decile) > Number(this.graphList5.spy_decile))
          this.margintop5 = Number(
            this.marginTop - Number(this.heights.valueStockGraph)
          );
        else
          this.margintop5 = Number(
            this.marginTop - Number(this.heights.valueSPYGraph)
          );

        if (isNaN(this.heights.valueStockGraph)) {
          this.heights.valueStockGraph = 30;
          this.value4 = '0';
        }
        if (isNaN(this.heights.valueSPYGraph)) {
          this.heights.valueSPYGraph = 30;
          this.spyValue4 = '0';
        }

        if (this.graphListAll) {
          this.graphListAll[4] = new graph();
        }
        this.graphListAll[4] = this.graphList5;
        this.graphListAll[4].header = 'Value Investing';
        this.heights.valueStockGraph = this.heights.valueStockGraph;
        this.heights.valueSPYGraph = this.heights.valueSPYGraph;
        if (this.graphList5.decile == '0' && this.innerWidth <= 480)
          this.margintop5 = Number(this.margintop5) + 56;
      } else if (!res) {
        this.graphListAll[4] = new graph();
        this.graphListAll[4].header = 'Value Investing';
      } else {
        this.graphListAll[4] = new graph();
        this.graphListAll[4].header = 'Value Investing';
        this.heights.valueSPYGraph = this.heights.valueStockGraph = 30;
        this.margintop5 = Number(
          this.marginTop - Number(this.heights.valueStockGraph)
        );
        this.Rev_growth_qtr = makePercent(0);
        this.Debt_equality = makePercent(0);
        this.Net_Margin = makePercent(0);
        this.PE = '0';
        this.Analyst_Rating = '0.0';
        this.value4 = '0';
      }
      this.isFilterChoosingShown = true;
      this.cd.markForCheck();
      this.dataService.character.next(false);
      this.showCharacter.emit();
    });
  }

  handleStockPeriod(period: string) {
    this.router.navigate([], {
      queryParams: { period },
      queryParamsHandling: 'merge',
      relativeTo: this.activatedRoute,
    });
    if (!this.disabled) {
      this.disabled = true;
      setTimeout(() => {
        this.disabled = false;
      }, 1000);
    }
  }

  getSocialSentimentGraphSpyVolumeChange(spy = false) {
    const number = spy
      ? this.graphList3?.spy_volume_change
      : this.graphList3?.volume_change;
    if (!this.graphList3 || !number) {
      return makePercent('0.0');
    }

    const volumeChangePercent = (number as number) * 100;

    if (volumeChangePercent < 1) {
      return makePercent(volumeChangePercent.toFixed(2));
    }

    return makePercent(Math.round(volumeChangePercent));
  }

  ngAfterViewInit(): void {}

  formatNumber(value: number | string): string {
    const num = +value;
    const suffixes = ['', 'K', 'M', 'B', 'T'];
    const suffixNum = Math.floor(('' + num).length / 3);
    let shortNum = parseFloat(
      (suffixNum != 0 ? num / Math.pow(1000, suffixNum) : num).toPrecision(3)
    );

    if (shortNum % 1 !== 0) {
      shortNum = +shortNum.toFixed(2);
    }

    return shortNum + suffixes[suffixNum];
  }

  formatNumberInMillions(num: number | string): string {
    const value = typeof num === 'number' ? num : Number(num);
    const billion = 1000000000;

    if (isNaN(value)) return 'Invalid input';

    if (value < billion) {
      const million = 1000000;
      if (value < million) return value.toString();
      const formattedNum = (value / million).toFixed(2);
      return formattedNum + 'M';
    }

    const formattedNum = (value / billion).toFixed(2);
    return formattedNum + 'B';
  }

  loadScript = (callback: any): void => {
    const script = document.createElement('script');
    const existingScript = document.getElementById('tradingview');
    if (!existingScript) {
      script.src = 'https://s3.tradingview.com/tv.js';
      script.type = 'text/javascript';
      script.id = 'tradingview';
      document.body.appendChild(script);
      script.onload = () => {
        if (callback) callback();
      };
    }
    if (existingScript && callback) callback();
  };

  chooseCharacter = (): void => {
    this.router.navigate(['../']);
  };
}
