import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-value-investing',
  templateUrl: './value-investing.component.html',
  styleUrls: ['./value-investing.component.css']
})
export class ValueInvestingComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
