<div class="container">
  <form
    [formGroup]="step === 1 ? signupForm : verifyCodeForm"
    (ngSubmit)="step === 1 ? onSubmit() : onConfirm()"
    class="form"
    *ngIf="pageType === PageType.SIGNUP"
  >
    <h1>{{ step === 1 ? "Sign Up" : "Confirm Account" }}</h1>
    <p class="message" *ngIf="checkValidate">Please fill in all fields</p>
    <p class="message">{{ message }}</p>
    <div class="inputContainer" *ngIf="step === 2">
      <label for="code">Code from registered mail</label>
      <input class="input" type="text" id="code" formControlName="code" />
    </div>
    <span *ngIf="step === 1">
      <div class="inputsBlock">
        <div class="inputContainer">
          <label for="firstName">First Name</label>
          <input
            class="input"
            value="{{ signupForm.value.firstName }}"
            type="text"
            id="firstName"
            formControlName="firstName"
          />
        </div>
        <div class="inputContainer">
          <label for="lastName">Last Name</label>
          <input
            class="input"
            type="text"
            value="{{ signupForm.value.lastName }}"
            id="lastName"
            formControlName="lastName"
          />
        </div>
        <div class="inputContainer">
          <label for="email">Email</label>
          <input
            class="input"
            type="email"
            value="{{ signupForm.value.email }}"
            id="email"
            formControlName="email"
          />
        </div>
        <div class="inputContainer">
          <label for="password">Password</label>
          <input
            class="input"
            type="password"
            id="password"
            formControlName="password"
          />
        </div>
      </div>
      <div class="inputsBlock">
        <div class="inputContainer">
          <label for="phoneNumber">Phone Number</label>
          <input
            class="input"
            id="phoneInput"
            type="tel"
            (keypress)="keyPress($event)"
            formControlName="phoneNumber"
            ng2TelInput
            value="{{ signupForm.value.phoneNumber }}"
            (countryChange)="onCountryChange($event)"
            placeholder=" "
          />
          <span class="dialCode" matPrefix style="width: max-content">
            {{ countryCode }}
          </span>
        </div>
        <div class="inputContainer">
          <label for="country">Country</label>
          <input
            class="input"
            type="text"
            value="{{ signupForm.value.country }}"
            id="country"
            formControlName="country"
          />
        </div>
      </div>
    </span>
    <div class="inputContainerCheckbox" *ngIf="step === 1">
      <input
        class="checkbox"
        type="checkbox"
        id="terms"
        formControlName="terms"
      />
      <p class="text">I agree to the</p>
      <p class="textLink" (click)="onTerms()">Terms and Conditions</p>
    </div>
    <button
      class="button"
      type="submit"
      [disabled]="
        step === 1
          ? buttonDisable || !signupForm.value.terms
          : !verifyCodeForm.valid
      "
    >
      {{ step === 1 ? "Sign Up" : "Confirm Account" }}
    </button>
    <p (click)="onLogin()" class="login">Already have an account? Log in</p>
  </form>
</div>
